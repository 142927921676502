import { Dimension } from '.';
import { Logger } from '../logging';
import { Namespace, TFunction } from 'i18next';

export const angleUnits = ['deg'] as const;
export type AngleUnit = (typeof angleUnits)[number];

export function isAngleUnit(val: unknown): val is AngleUnit {
  return angleUnits.includes(val as AngleUnit);
}

export class Angle extends Dimension<AngleUnit> {
  protected static readonly logger = new Logger('Angle');

  static parse(serializedAngle: string | null | undefined): Angle | null {
    return this.baseParse(serializedAngle, isAngleUnit, ({ scalar, unit }) => new Angle(scalar, unit));
  }

  constructor(scalar: number, unit: AngleUnit) {
    super({ scalar, unit }, [scalar, unit]);
  }

  format(t: TFunction<Namespace>): string {
    return this.baseFormat(t, 'angle').formattedString;
  }
}
