import { DateTime, Duration } from 'luxon';
import { useFlag } from '@unleash/proxy-client-react';
import { PropsWithChildren, useEffect, useMemo, useSyncExternalStore } from 'react';
import { EmptyLayout } from '../layout/Layouts';
import { AppUpdatePage } from './AppUpdatePage';
import { _never } from '../common/utils/_never';
import { Logger } from '../common/utils/logging';
import { dispatchCustomEvent } from '../common/utils/customEvents';
import { onServiceWorkerMessage, sendMessageToServiceWorker } from '../serviceWorkerMessages/app';
import { ClientMessageTypes, ServiceWorkerMessageTypes } from '../serviceWorkerMessages/types';
import { config } from '../config';

const logger = new Logger('AppUpdate');

/*
 * Hard mode vs. soft mode:
 *
 * - Soft mode is the default mode. Available updates are detected by calling ServiceWorkerRegistration.update(). If an
 *   update is available and installs successfully, the app is notified of the pending update. To apply the update, the
 *   waiting service worker is instructed to activate itself by calling self.skipWaiting(). This is the recommended way
 *   to handle updates according to service worker best practices. However, since the service worker actively
 *   participates in the update process, a bug in the service worker could prevent updates from being installed.
 * - In case this happens, hard mode can be enabled by enabling the 'app_hard_update_mode' feature flag. In hard mode,
 *   updates are detected by fetching the config.js file from the server, and comparing the current app version with the
 *   one from the config.js file. A mismatch means an update is available. Then, to apply the update, the old service
 *   worker calls self.registration.unregister() to uninstall itself. On the next page load, the browser will download
 *   the latest service worker and install it again from scratch. This method might be more reliable than soft mode, but
 *   it's less efficient, in part because it leads to the whole asset cache being invalidated and re-downloaded,
 *   including assets that haven't changed between the two versions.
 */

/** How long to wait between update checks. */
const updateCheckInterval: Duration<true> = Duration.fromObject({ minutes: 3 });

/** How long to wait for a hard-mode update check to complete before considering it failed. */
const hardUpdateCheckTimeout: Duration<true> = Duration.fromObject({ seconds: 5 });

/** How long to stall page load while checking for available updates. */
const stallingStateTimeout: Duration<true> = Duration.fromObject({ seconds: 3 });

/** How long to allow the user to use the application while an update is pending before forcing installation of the update. */
const pendingUpdateGracePeriod: Duration<true> = config.APP_UPDATE_GRACE_PERIOD;

export type AppUpdateState =
  // Page loading is stalled while checking for an available update. If an update is found before `stallingStateTimeout`
  // and the app isn't open in other windows, transitions to the 'updating' state to install the update. If an update is
  // found before `stallingStateTimeout` but the app is open in other windows, transitions to the 'pending' state to
  // wait for user confirmation or for `pendingUpdateGracePeriod` expiration before installing the update. If no updates
  // are available, or the `stallingStateTimeout` expires, transitions to the 'idle' state.
  | 'stalling'
  // No available updates. Application is working normally.
  | 'idle'
  // An update is pending. Wait for the user to confirm update installation, or for the installation deadline to be
  // reached, whichever comes first, before transitioning to the 'updating' state.
  | 'pending'
  // An update is being installed. Transitions to the 'reloading' state once the installation completes.
  | 'updating'
  // An update was installed, reload the app.
  | 'reloading';

interface AppUpdateStore {
  getState(): AppUpdateStoreState;
  onStateChange(listener: () => void): () => void;
  enableHardUpdateMode(enabled: boolean): void;
  applyUpdate(): void;
}

type AppUpdateStoreState =
  | {
      readonly updateState: Exclude<AppUpdateState, 'pending'>;
    }
  | {
      readonly updateState: Extract<AppUpdateState, 'pending'>;
      /** When the pending update will be installed even if the user doesn't confirm the update's installation.
       * Use DateTime instead of Date because unlike Date, DateTime is immutable. */
      readonly pendingUpdateDeadline: DateTime<true>;
    };

let appUpdateStore: AppUpdateStore | null = null;

const getAppUpdateStore = (enableHardUpdateMode: boolean = false): AppUpdateStore => {
  if (appUpdateStore) return appUpdateStore;

  logger.info('Creating AppUpdateStore.');

  if (!('serviceWorker' in navigator)) {
    logger.info('Browser does not support service workers. Update checks will not be performed.');

    // When the browser doesn't support service workers, the store stays in a permanent 'idle' state and all its methods
    // are no-ops (i.e. the application is working normally but with no update management at all).
    const state: AppUpdateStoreState = { updateState: 'idle' };

    return {
      getState() {
        return state;
      },
      onStateChange() {
        return () => {};
      },
      enableHardUpdateMode() {},
      applyUpdate() {
        logger.error("Service worker update requested but the browser doesn't support service workers. This is a bug.");
      },
    };
  }

  // The current state of the store.
  let state: AppUpdateStoreState = { updateState: 'stalling' };

  // State listeners, called when the state changes
  const stateChangeListeners = new Set<() => void>();

  // Whether hard update mode is enabled
  let isHardUpdateModeEnabled = enableHardUpdateMode;

  // Timeout ID for the pending update installation deadline
  let pendingUpdateDeadlineTimeoutId: ReturnType<typeof setTimeout> | undefined = undefined;

  // Set a new store state, notify state listeners, and execute state-specific logic.
  const setState = (newUpdateState: AppUpdateState) => {
    if (newUpdateState === state.updateState) return;

    logger.info(`State transition: ${state.updateState} -> ${newUpdateState}`);

    // Update store state
    if (newUpdateState === 'pending') {
      setPendingStateWithDeadline(DateTime.now().plus(pendingUpdateGracePeriod));
    } else {
      state = { updateState: newUpdateState };
      clearTimeout(pendingUpdateDeadlineTimeoutId);
    }

    // Call state change listeners
    stateChangeListeners.forEach((listener) => listener());

    // Execute state-specific logic
    switch (newUpdateState) {
      case 'reloading':
        // Update was installed, reload the page
        dispatchCustomEvent('disableUnloadBlockerForAppUpdate');
        window.location.reload();
        break;
    }
  };

  // Set a timeout to transition to the 'idle' state if no update is found before the stalling timeout expires
  const stallingTimeoutId = setTimeout(() => {
    if (state.updateState !== 'stalling') return;
    logger.info('Stalling deadline reached without finding an available update, transitioning to idle state.');
    setState('idle');
  }, stallingStateTimeout.toMillis());

  // Update installation deadline cross-window synchronization
  //
  // Whenever a window (application instance) detects an update, it computes an update installation deadline, saves it
  // in its state, then broadcasts it to all windows. When a window receives a broadcast deadline, if it wasn't already
  // in pending state, then it goes into pending state with the broadcast deadline. If it was already in pending state,
  // but the broadcast deadline is earlier than its own deadline, then it updates its deadline to match the broadcast
  // one. If it was already in pending state, but its own deadline is earlier than the broadcast one, then it broadcasts
  // its own deadline to the other windows. This process repeats until all windows have converged to the same deadline
  // (the earliest amongst all windows).

  const broadcastChannel = new BroadcastChannel('appUpdate');
  const pendingUpdateMessageType = 'appUpdate.pendingUpdate';

  const broadcastDeadline = (deadline: DateTime<true>) => {
    broadcastChannel.postMessage({ type: pendingUpdateMessageType, deadline: deadline.toMillis() });
  };

  const setPendingStateWithDeadline = (pendingUpdateDeadline: DateTime<true>) => {
    state = { updateState: 'pending', pendingUpdateDeadline };
    clearTimeout(pendingUpdateDeadlineTimeoutId);
    pendingUpdateDeadlineTimeoutId = setTimeout(() => {
      logger.info(`Update installation deadline reached, requesting update installation.`);
      requestUpdate();
    }, state.pendingUpdateDeadline.diffNow().toMillis());
    broadcastDeadline(state.pendingUpdateDeadline);
    logger.info(`Update installation deadline: ${state.pendingUpdateDeadline.toISO()}`);
    stateChangeListeners.forEach((listener) => listener());
  };

  // Query the service worker to know whether the app is currently open in other controlled windows.
  // If the application isn't controlled by a service worker, the returned promise will resolve to `false`.
  const isAppOpenInOtherWindows = () =>
    new Promise<boolean>((resolve) => {
      const queryId = crypto.randomUUID();
      const removeListener = onServiceWorkerMessage(({ data: { type, payload } }) => {
        if (type !== ServiceWorkerMessageTypes.HasOtherWindowsResponse || payload.queryId !== queryId) return;
        removeListener();
        resolve(payload.hasOtherWindows);
      });
      if (!sendMessageToServiceWorker(ClientMessageTypes.HasOtherWindowsQuery, { queryId })) {
        logger.warn('Failed to query service worker for open windows: no service worker controller.');
        removeListener();
        resolve(false);
      }
    });

  // Return a Promise that resolves to whether the given service worker registration has a waiting update. If an update
  // is installing, wait until installation either succeeds or fails before notifying the app of the update to make
  // sure we have the latest update.
  const registrationHasPendingUpdate = async (registration: ServiceWorkerRegistration): Promise<boolean> => {
    const { installing } = registration;
    if (installing) {
      await new Promise<void>((resolve) => {
        installing.addEventListener('statechange', () => resolve(), { once: true });
      });
    }
    return registration.waiting != null;
  };

  // Handle update availability and update the state accordingly.
  const handleUpdateAvailability = async (isUpdateAvailable: boolean) => {
    switch (state.updateState) {
      case 'stalling':
        clearTimeout(stallingTimeoutId);
        if (isUpdateAvailable) {
          if (await isAppOpenInOtherWindows()) {
            logger.info(
              `Update found while in ${state.updateState} state with other open windows, waiting for user confirmation before installing the update.`,
            );
            setState('pending');
          } else {
            logger.info(`Update found while in ${state.updateState} state with no other open windows, requesting update installation.`);
            requestUpdate();
          }
        } else {
          // No update found, start the app
          logger.info('No update found while stalling page load. Starting the app.');
          setState('idle');
        }
        break;
      case 'idle':
        if (isUpdateAvailable) {
          logger.info(`Update found while in ${state.updateState} state, waiting for user confirmation before installing the update.`);
          setState('pending');
        }
        break;
      case 'pending':
        if (!isUpdateAvailable) {
          logger.error(
            "Notified that no updates are available while in the 'pending' state. Updates aren't supposed to disappear, this is most likely a bug.",
          );
        }
        // If `isUpdateAvailable` is true, this either means that we received a duplicate update notification, or that a
        // newer update was found. In both cases we don't need to do anything.
        break;
      default:
        if (isUpdateAvailable) {
          logger.info(`Update found while in ${state.updateState} state. Ignored.`);
        }
        break;
    }
  };

  // Check for available updates and update the state accordingly.
  const updateCheck = () => {
    logger.info('Checking for available updates.');

    // Start up to 2 update checks in parallel, and create a promise that resolves to true as soon as one of them finds
    // an update or resolves to false if neither of them does.
    new Promise<boolean>((resolve) => {
      const softUpdateCheckPromise = softUpdateCheck();
      const hardUpdateCheckPromise = isHardUpdateModeEnabled ? hardUpdateCheck() : Promise.resolve(false);

      softUpdateCheckPromise.then((softUpdateAvailable) => resolve(softUpdateAvailable || hardUpdateCheckPromise));
      hardUpdateCheckPromise.then((hardUpdateAvailable) => resolve(hardUpdateAvailable || softUpdateCheckPromise));
    }).then(handleUpdateAvailability);
  };

  // Check for updates in soft mode (using the service worker API).
  const softUpdateCheck = async (): Promise<boolean> => {
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) {
      // Service worker isn't registered so we can't check for available updates.
      return false;
    }
    if (navigator.onLine) {
      await registration
        .update()
        .catch((error) => logger.warn('Soft update check failed: unable to update service worker registration.', error));
    }
    const isUpdateAvailable = await registrationHasPendingUpdate(registration);
    if (isUpdateAvailable) {
      logger.info('Update detected through waiting service worker.');
    }
    return isUpdateAvailable;
  };

  // Check for updates in hard mode (by fetching the app version from the config file and detecting a mismatch with the
  // current app version).
  const hardUpdateCheck = async (): Promise<boolean> => {
    if (!navigator.onLine) {
      return false;
    }

    // Read app version straight from the environment variables instead of importing the config module.
    // This enables an easy bypass by manually setting env.APP_VERSION in the devtools.
    const currentVersion = window.env.APP_VERSION;
    if (currentVersion == null) {
      logger.error(`Hard update check: current APP_VERSION is ${currentVersion}.`);
    }

    return fetch(`${process.env.PUBLIC_URL}/config.js`, { signal: AbortSignal.timeout(hardUpdateCheckTimeout.toMillis()) })
      .then((response) => response.text())
      .then((textResponse) => {
        const detectedVersion = textResponse.match(/["']?APP_VERSION["']?\s*:\s*["'](.+?)["']/)?.[1];
        if (detectedVersion == null) {
          logger.error('Hard update check failed: unable to read APP_VERSION from config file.');
          return false;
        }
        return currentVersion !== detectedVersion;
      })
      .catch((err) => {
        if (err instanceof DOMException && err.message === 'AbortError') {
          logger.info(`Hard update check failed: timed out after ${hardUpdateCheckTimeout.toMillis()} milliseconds.`);
        } else {
          logger.warn('Hard update check failed: unexpected error.', err);
        }
        return false;
      })
      .then((updateNeeded) => {
        if (updateNeeded) {
          logger.info('Update detected through config file version mismatch.');
        }
        return updateNeeded;
      });
  };

  // Request the service worker to install the pending update.
  const requestUpdate = () => {
    logger.info(`Requesting update in ${isHardUpdateModeEnabled ? 'hard' : 'soft'} mode...`);
    if (!sendMessageToServiceWorker(ClientMessageTypes.Update, { hardMode: isHardUpdateModeEnabled })) {
      logger.error("Failed to apply update: couldn't send update message to service worker. Transitioning to idle state.");
      setState('idle');
    }
  };

  // If there is no service worker registration at store creation, then get out of the 'stalling' state and into the
  // 'idle' state.
  navigator.serviceWorker.getRegistration().then((registration) => {
    if (state.updateState === 'stalling' && !registration) {
      logger.info('No service worker registration found, transitioning to idle state.');
      setState('idle');
    }
  });

  // Then, whenever a service worker registration appears...
  navigator.serviceWorker.ready.then((registration) => {
    const checkServiceWorkerState = (serviceWorker: ServiceWorker) => {
      if (serviceWorker.state !== 'activated') return;
      logger.info('Soft update successful, transitioning to reloading state.');
      setState('reloading');
    };

    const subscribedServiceWorkers = new WeakSet<ServiceWorker>();
    const subscribeToServiceWorkerState = (serviceWorker: ServiceWorker) => {
      if (subscribedServiceWorkers.has(serviceWorker)) return;
      subscribedServiceWorkers.add(serviceWorker);

      let previousState = serviceWorker.state;
      serviceWorker.addEventListener('statechange', () => {
        logger.info(`Service worker state changed from ${JSON.stringify(previousState)} to ${JSON.stringify(serviceWorker.state)}.`);
        previousState = serviceWorker.state;
        checkServiceWorkerState(serviceWorker);
      });

      logger.info(`Service worker state is ${JSON.stringify(serviceWorker.state)}.`);
      checkServiceWorkerState(serviceWorker);
    };

    if (registration.installing) subscribeToServiceWorkerState(registration.installing);
    if (registration.waiting) subscribeToServiceWorkerState(registration.waiting);

    // Listen for the 'updatefound' registration event to detect updates at any time and not only during update checks.
    registration.addEventListener('updatefound', () => {
      logger.info('Service worker update found.');

      const { installing, waiting } = registration;
      if (installing) {
        logger.info('Found installing service worker.');
        subscribeToServiceWorkerState(installing);
      }
      if (waiting) {
        logger.info('Found waiting service worker.');
        subscribeToServiceWorkerState(waiting);
      }
      if (!installing && !waiting) {
        logger.warn('Service worker registration has no installing or waiting service worker.');
      }

      registrationHasPendingUpdate(registration).then(handleUpdateAvailability);
    });

    // Listen for broadcast channel messages.
    broadcastChannel.addEventListener('message', (event: MessageEvent) => {
      if (event.data?.type !== pendingUpdateMessageType || typeof event.data.deadline !== 'number') return;
      if (state.updateState === 'updating' || state.updateState === 'reloading') {
        logger.info(`Update found by other window while in ${state.updateState} state. Ignored.`);
        return;
      }
      const deadline = DateTime.fromMillis(event.data.deadline);
      if (!deadline.isValid) {
        logger.error(`Received invalid update installation deadline from other window: ${JSON.stringify(event.data.deadline)}.`);
      } else if (state.updateState !== 'pending') {
        logger.info('Update found by other window, transitioning to pending state.');
        setPendingStateWithDeadline(deadline);
      } else if (deadline < state.pendingUpdateDeadline) {
        logger.info('Received earlier update installation deadline from other window. Updating deadline.');
        setPendingStateWithDeadline(deadline);
      } else if (state.pendingUpdateDeadline < deadline) {
        broadcastDeadline(state.pendingUpdateDeadline);
      }
    });

    // Listen for service worker messages.
    onServiceWorkerMessage((event) => {
      const { type, payload } = event.data;
      switch (type) {
        case ServiceWorkerMessageTypes.UpdateStarted:
          if (state.updateState === 'updating') break;
          logger.info(`Update started in ${payload.hardMode ? 'hard' : 'soft'} mode.`);
          setState('updating');
          break;
        case ServiceWorkerMessageTypes.UpdateFailure:
          if (state.updateState === 'idle') break;
          logger.error('Failed to install update, transitioning to idle state.', payload.error);
          setState('idle');
          break;
        case ServiceWorkerMessageTypes.HardUpdateSuccess:
          if (state.updateState === 'reloading') break;
          logger.info('Hard update successful, transitioning to reloading state.');
          setState('reloading');
          break;
        case ServiceWorkerMessageTypes.HasOtherWindowsResponse:
          // This is handled directly by isAppOpenInOtherWindows()
          break;
        default:
          _never(type);
          break;
      }
    });

    // NOTE temporarily disabled while trying a new approach to detect update installation success based on monitoring
    // of service worker state.
    // Listen for 'controllerchange' events:
    // - if there was no previous service worker controller, this is an initial service worker registration (we ignore it)
    // - if there was a previous service worker controller, this signals the successful end of a soft update (transition
    //   to the 'reloading' state)
    // let previousController = navigator.serviceWorker.controller;
    // navigator.serviceWorker.addEventListener('controllerchange', () => {
    //   const isInitialRegistration = !previousController;
    //   previousController = navigator.serviceWorker.controller;
    //   if (!isInitialRegistration) {
    //     logger.info('Soft update successful, transitioning to reloading state.');
    //     setState('reloading');
    //   }
    // });

    // Check for updates every `updateCheckInterval`.
    setInterval(updateCheck, updateCheckInterval.toMillis());

    // If we're still in the 'stalling' state, do an update check right now to determine whether an update is available
    // and get out of the 'stalling' state as soon as possible.
    if (state.updateState === 'stalling') updateCheck();
  });

  return (appUpdateStore = {
    getState() {
      return state;
    },

    onStateChange(listener: () => void) {
      const uniqueListener = () => listener();
      stateChangeListeners.add(uniqueListener);
      return () => {
        stateChangeListeners.delete(uniqueListener);
      };
    },

    applyUpdate() {
      if (state.updateState !== 'pending') {
        logger.error(`Asked to apply update while in ${state.updateState} state. This is a bug.`);
      } else {
        logger.info('User confirmed update installation, installing update.');
        requestUpdate();
      }
    },

    enableHardUpdateMode(enabled: boolean) {
      if (enabled === isHardUpdateModeEnabled) return;
      isHardUpdateModeEnabled = enabled;
      logger.info(`Hard update mode ${enabled ? 'enabled' : 'disabled'}.`);
    },
  });
};

export type UseAppUpdateReturn =
  | { updateState: Exclude<AppUpdateState, 'pending'>; pendingUpdateDeadline: null; applyUpdate: () => void }
  | { updateState: Extract<AppUpdateState, 'pending'>; pendingUpdateDeadline: DateTime<true>; applyUpdate: () => void };

export function useAppUpdate(): UseAppUpdateReturn {
  const isHardUpdateMode = useFlag('app_hard_update_mode');
  const store = getAppUpdateStore(isHardUpdateMode);
  useEffect(() => store.enableHardUpdateMode(isHardUpdateMode), [store, isHardUpdateMode]);
  const storeState = useSyncExternalStore(store.onStateChange, store.getState);
  return useMemo(
    () =>
      storeState.updateState === 'pending'
        ? {
            updateState: storeState.updateState,
            pendingUpdateDeadline: storeState.pendingUpdateDeadline,
            applyUpdate: store.applyUpdate,
          }
        : {
            updateState: storeState.updateState,
            pendingUpdateDeadline: null,
            applyUpdate: store.applyUpdate,
          },
    [storeState, store.applyUpdate],
  );
}

export function AppUpdate({ children }: PropsWithChildren) {
  const { updateState } = useAppUpdate();

  switch (updateState) {
    case 'stalling':
      return <EmptyLayout />;
    case 'idle':
    case 'pending':
      return children;
    case 'updating':
    case 'reloading':
      return <AppUpdatePage />;
    default:
      _never(updateState);
  }
}
