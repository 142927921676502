/**
 * @generated SignedSource<<6314815b2612c63d8b2c8ba7e18cd4c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientBaseFields_ClientOverridesStateFragment$data = {
  readonly value: {
    readonly state: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ClientBaseFields_ClientOverridesStateFragment";
};
export type ClientBaseFields_ClientOverridesStateFragment$key = {
  readonly " $data"?: ClientBaseFields_ClientOverridesStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_ClientOverridesStateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientBaseFields_ClientOverridesStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StructuredAddressData",
      "kind": "LinkedField",
      "name": "value",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StructuredAddressResult",
  "abstractKey": null
};

(node as any).hash = "1e86030a54dfccf65bf4e88c1e45d4e2";

export default node;
