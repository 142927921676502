/**
 * @generated SignedSource<<ec5b17804c4b89de16b8c692115d82e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CraneSelectorFields_Manual_RadiusFragment$data = {
  readonly radius: string | null | undefined;
  readonly " $fragmentType": "CraneSelectorFields_Manual_RadiusFragment";
};
export type CraneSelectorFields_Manual_RadiusFragment$key = {
  readonly " $data"?: CraneSelectorFields_Manual_RadiusFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_Manual_RadiusFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CraneSelectorFields_Manual_RadiusFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "radius",
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "fce128fb623d025e443e0cb516f185d2";

export default node;
