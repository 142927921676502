import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { DateTime, Duration } from 'luxon';
import { AlertTitle, Button, Stack, Typography } from '@mui/material';
import { useAppUpdateDeadlineCountdown } from './useAppUpdateDeadlineCountdown';
import { useAppUpdate } from './AppUpdate';
import { Trans, useTranslation } from 'react-i18next';
import { useCustomEventListener } from '../common/utils/customEvents';
import { config } from '../config';
import { Warning } from '@mui/icons-material';

declare module '../common/utils/customEvents' {
  interface CustomEvents {
    togglePendingAppUpdateSnackbar: void;
  }
}

/** How long before the installation deadline to display a snackbar again if the first one was closed. */
const lastWarningTimeBeforeDeadline: Duration<true> = config.APP_UPDATE_LAST_WARNING_TIME;

export function AppUpdateSnackbarHandler() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { pendingUpdateDeadline, applyUpdate } = useAppUpdate();

  const hasDeadline = pendingUpdateDeadline != null;
  const [showSnackbar, setShowSnackbar] = useState(hasDeadline);

  // If we previously had no deadline, but now we do, show the snackbar.
  // If we previously had a deadline, but now we don't, hide the snackbar.
  useEffect(() => {
    setShowSnackbar(hasDeadline);
  }, [hasDeadline]);

  // Allow toggling the snackbar from another component, but only if we have a deadline.
  useCustomEventListener('togglePendingAppUpdateSnackbar', () => {
    if (hasDeadline) setShowSnackbar((show) => !show);
  });

  // React to changes of `showSnackbar` to show / hide the snackbar.
  useEffect(() => {
    if (!showSnackbar) return;

    let closed = false;
    const close = () => {
      if (closed) return;
      closed = true;
      closeSnackbar(snackbarKey);
      setShowSnackbar(false);
    };

    const snackbarKey = enqueueSnackbar({
      message: <AppUpdateSnackbarMessage />,
      action: <AppUpdateSnackbarAction onClose={() => close()} onUpdate={() => applyUpdate()} />,
      persist: true,
      onClose: close,
    });

    return close;
  }, [applyUpdate, closeSnackbar, enqueueSnackbar, showSnackbar]);

  // Whenever the deadline changes, if it's not null, schedule a new snackbar to be shown
  // `lastWarningTimeBeforeDeadline` before the deadline, unless we're already past that point in time.
  useEffect(() => {
    if (!pendingUpdateDeadline || DateTime.now() > pendingUpdateDeadline.minus(lastWarningTimeBeforeDeadline)) return;
    const lastWarningTimeoutId = setTimeout(
      () => setShowSnackbar(true),
      pendingUpdateDeadline.minus(lastWarningTimeBeforeDeadline).diffNow().toMillis(),
    );
    return () => clearTimeout(lastWarningTimeoutId);
  }, [pendingUpdateDeadline]);

  return null;
}

function AppUpdateSnackbarMessage() {
  const { t } = useTranslation('appUpdate');
  const countdown = useAppUpdateDeadlineCountdown();
  return (
    <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: '1rem' }}>
      <Warning color='warning' />
      <Stack>
        <AlertTitle>{t('snackbar.title')}</AlertTitle>
        <Typography>
          <Trans t={t} i18nKey='snackbar.description' values={{ countdown }} components={{ strong: <strong /> }} />
        </Typography>
      </Stack>
    </Stack>
  );
}

function AppUpdateSnackbarAction({ onClose, onUpdate }: { onClose: () => void; onUpdate: () => void }) {
  const { t } = useTranslation('appUpdate');
  return (
    <Stack sx={{ flexDirection: 'row', gap: '0.5rem', padding: '0.5rem' }}>
      <Button color='warning' onClick={() => onUpdate()}>
        {t('snackbar.buttons.update')}
      </Button>
      <Button variant='outlined' color='inverted' onClick={() => onClose()}>
        {t('snackbar.buttons.close')}
      </Button>
    </Stack>
  );
}
