/**
 * @generated SignedSource<<3f079f39d7ddb6cb02cb0ac323ff8941>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useMissingCostsDependenciesFragment$data = {
  readonly clientBase: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_useFieldAssignedClientFragment">;
  };
  readonly equipmentBase: {
    readonly craneSelector: {
      readonly manualConfiguration: {
        readonly userInput: {
          readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_VehicleIdsFragment">;
        };
      };
      readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_CraneSelectorModeFragment" | "useFavoriteEquipmentSelectedFragment">;
    };
  };
  readonly project: {
    readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_DispatchBranchFragment" | "SaleProjectFields_NatureOfWorkFragment" | "SaleProjectFields_NatureOfWorkSubCategoryFragment">;
  };
  readonly projectBase: {
    readonly assignedWorksiteInfo: {
      readonly location: {
        readonly structuredAddress: {
          readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesCityFragment" | "ProjectBaseFields_WorksiteOverridesPostalCodeFragment" | "ProjectBaseFields_WorksiteOverridesStateFragment">;
        } | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesAddressReadFragment">;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesName_ReadFragment">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_ArrivalDateReadFragment">;
  };
  readonly " $fragmentType": "useMissingCostsDependenciesFragment";
};
export type useMissingCostsDependenciesFragment$key = {
  readonly " $data"?: useMissingCostsDependenciesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useMissingCostsDependenciesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useMissingCostsDependenciesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientInternalBase",
      "kind": "LinkedField",
      "name": "clientBase",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientBaseFields_useFieldAssignedClientFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectInternalBase",
      "kind": "LinkedField",
      "name": "projectBase",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Literal",
              "name": "isCopy",
              "value": false
            }
          ],
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_ArrivalDateReadFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WorksiteInfoInternal",
          "kind": "LinkedField",
          "name": "assignedWorksiteInfo",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectBaseFields_WorksiteOverridesName_ReadFragment"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Location",
              "kind": "LinkedField",
              "name": "location",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectBaseFields_WorksiteOverridesAddressReadFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StructuredAddressResult",
                  "kind": "LinkedField",
                  "name": "structuredAddress",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProjectBaseFields_WorksiteOverridesCityFragment"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProjectBaseFields_WorksiteOverridesStateFragment"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProjectBaseFields_WorksiteOverridesPostalCodeFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_NatureOfWorkFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_NatureOfWorkSubCategoryFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_DispatchBranchFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquipmentInternalBase",
      "kind": "LinkedField",
      "name": "equipmentBase",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CraneSelectorInternal",
          "kind": "LinkedField",
          "name": "craneSelector",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CraneSelectorFields_CraneSelectorModeFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useFavoriteEquipmentSelectedFragment"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ManualConfigurationInternal",
              "kind": "LinkedField",
              "name": "manualConfiguration",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ManualConfigurationInfo",
                  "kind": "LinkedField",
                  "name": "userInput",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "CraneSelectorFields_VehicleIdsFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "a3ff0a520f244786d5e81b5703a99d40";

export default node;
