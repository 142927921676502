import { ClientLanguage } from '../../__enums__/ClientLanguage';

const button = {
  selectClient: 'Sélectionnez un client',
};

const dialog = {
  selectClient: 'Sélectionner un client',
  noResult: 'Aucun résultat trouvé',
  representative: 'REP',
  automaticSuggestions: 'Suggestions automatiques',
  allClients: 'Tous les clients',
  requirementsTitle: 'Exigences client',
};

const field = {
  address: 'Adresse du client',
  apartment: 'Appartement',
  city: 'Ville',
  province: 'Province',
  postalCode: 'Code postal',
  name: 'Nom du client',
  phoneNumber: 'Téléphone',
  preferredLanguage: "Langue d'usage",
};

const error = {
  clientIsRequired: 'Veuillez sélectionner un client',
  errorWhileLoading: 'Une erreur est survenue en chargeant le client',
};

const placeholder = {
  changeLanguageWarning:
    "Le changement de la langue d'usage du client va régénérer les addendas ajoutés automatiquement. Si vous les avez modifiés manuellement, ces ajustements seront perdus. Souhaitez-vous continuer?",
  clientInfo: 'Entrez les informations du client',
  noClientSelected: 'Aucun',
  selectClient: 'Sélectionnez un client',
};

export const client = {
  button,
  client: 'Client',
  clientDetails: 'Détails du client',
  details: 'Détails',
  directSale: 'Vente directe',
  sectionHeader: 'Info',
  dialog,
  error,
  language: {
    en: 'Anglais',
    fr: 'Français',
  } satisfies Record<ClientLanguage, string>,
  languageShort: {
    en: 'EN',
    fr: 'FR',
  } satisfies Record<ClientLanguage, string>,
  placeholder,
  field,
};
