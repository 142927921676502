import * as Sentry from '@sentry/react';
import { useTheme } from '@mui/material';
import ErrorFallback from './ErrorFallback';
import { ListLayout, SidebarContentProps } from './Layouts';
import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationMenu } from './SidebarDrawer';
import { errorBoundaryBeforeCaptureFn } from '../AppErrorBoundary';
import { Logger } from '../common/utils/logging';
import { useRetryCallback } from './useRetryCallback';

const logger = new Logger('ListPageErrorBoundary');

export function ListPageErrorBoundary({ heading, flagName, children }: { heading: string; flagName: string; children: ReactNode }) {
  const theme = useTheme();
  const { t } = useTranslation('common');
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={null} />, []);
  const handleRetry = useRetryCallback();

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => (
        <ListLayout heading={heading} flagName={flagName} sidebarProvider={sidebar} $key={null}>
          <ErrorFallback
            error={error}
            subtitleMessage={t('requestSupport')}
            onButtonClick={handleRetry(resetError)}
            buttonMessage={t('button.retry')}
          />
        </ListLayout>
      )}
      beforeCapture={(scope) => errorBoundaryBeforeCaptureFn(scope, theme)}
      onError={(...args) => {
        logger.error(...args);
      }}>
      {children}
    </Sentry.ErrorBoundary>
  );
}
