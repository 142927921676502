/**
 * @generated SignedSource<<e52f75826a4028d9886c42a171f78245>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useFavoriteEquipment_SynchronizeAutomatic_FromFieldValuesFragment$data = {
  readonly automaticConfiguration: {
    readonly " $fragmentSpreads": FragmentRefs<"AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_FavoriteConfigurationFragment">;
  readonly " $fragmentType": "useFavoriteEquipment_SynchronizeAutomatic_FromFieldValuesFragment";
};
export type useFavoriteEquipment_SynchronizeAutomatic_FromFieldValuesFragment$key = {
  readonly " $data"?: useFavoriteEquipment_SynchronizeAutomatic_FromFieldValuesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_SynchronizeAutomatic_FromFieldValuesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useFavoriteEquipment_SynchronizeAutomatic_FromFieldValuesFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CraneSelectorFields_FavoriteConfigurationFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomaticConfigurationInternal",
      "kind": "LinkedField",
      "name": "automaticConfiguration",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};

(node as any).hash = "10e2c7a6b0a6c93d50d2b737dd44762b";

export default node;
