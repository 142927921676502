/**
 * @generated SignedSource<<a0b00ab956aaac8688ec87832c1ef709>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_CopyClientAddressButtonFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesApartmentFragment" | "ProjectBaseFields_WorksiteOverridesCityFragment" | "ProjectBaseFields_WorksiteOverridesCoordinatesFragment" | "ProjectBaseFields_WorksiteOverridesPostalCodeFragment" | "ProjectBaseFields_WorksiteOverridesStateFragment">;
  readonly " $fragmentType": "ProjectBaseFields_CopyClientAddressButtonFragment";
};
export type ProjectBaseFields_CopyClientAddressButtonFragment$key = {
  readonly " $data"?: ProjectBaseFields_CopyClientAddressButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_CopyClientAddressButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_CopyClientAddressButtonFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverridesCoordinatesFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverridesPostalCodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverridesApartmentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverridesCityFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverridesStateFragment"
    }
  ],
  "type": "StructuredAddressResult",
  "abstractKey": null
};

(node as any).hash = "733e974b736b239d1b446bc9567837f5";

export default node;
