import { Component, ReactNode } from 'react';
import ErrorFallback from '../layout/ErrorFallback';
import { useTranslation } from 'react-i18next';
import { EmptyLayout } from '../layout/Layouts';
import * as Sentry from '@sentry/react';

export class EmployeeNotFoundError extends Error {
  constructor() {
    super('Employee not found');
  }
}

export class EmployeeNotFoundErrorBoundary extends Component<{ children?: ReactNode }, { error?: EmployeeNotFoundError }> {
  constructor(props: { children?: JSX.Element }) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error) {
    if (error instanceof EmployeeNotFoundError) {
      return { error };
    }
    return {};
  }

  override componentDidCatch(error: Error) {
    if (error instanceof EmployeeNotFoundError) {
      Sentry.captureException(error);
    } else {
      throw new AggregateError([error], 'Error type not handled by EmployeeNotFoundErrorBoundary');
    }
  }

  render() {
    const { error } = this.state;

    if (error) {
      return (
        <EmptyLayout>
          <EmployeeNotFoundErrorFallback error={error} />
        </EmptyLayout>
      );
    }
    const { children } = this.props;
    return children;
  }
}

function EmployeeNotFoundErrorFallback({ error }: { error: EmployeeNotFoundError }) {
  const { t } = useTranslation('auth');

  return <ErrorFallback error={error} titleMessage={t('unauthorized')} subtitleMessage={t('contactYourAdmin')} />;
}
