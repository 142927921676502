/**
 * @generated SignedSource<<d0c654c555fc5155b4226bcd0f8393a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as boomConfigurationSnapshotLabelResolverType } from "../../../__resolvers__/BoomConfigurationSnapshotLabelResolver";
// Type assertion validating that `boomConfigurationSnapshotLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(boomConfigurationSnapshotLabelResolverType satisfies (
  rootKey: BoomConfigurationSnapshotLabelResolver$key,
) => string | null | undefined);
export type CraneSelectorFields_Manual_BoomConfigurationFragment$data = {
  readonly boomConfiguration: {
    readonly id: string;
    readonly label: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "CraneSelectorFields_Manual_BoomConfigurationFragment";
};
export type CraneSelectorFields_Manual_BoomConfigurationFragment$key = {
  readonly " $data"?: CraneSelectorFields_Manual_BoomConfigurationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_Manual_BoomConfigurationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CraneSelectorFields_Manual_BoomConfigurationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BoomConfigurationSnapshot",
      "kind": "LinkedField",
      "name": "boomConfiguration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BoomConfigurationSnapshotLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../../__resolvers__/BoomConfigurationSnapshotLabelResolver').label,
          "path": "boomConfiguration.label"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "4e870a879ee94a83040c2fcb8d0ccad3";

export default node;
