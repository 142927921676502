import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { parseDateTime, roundDateTimeToMinuteStep } from '../utils/dateTimeUtils';
import { ForwardedRef, forwardRef, RefAttributes, useEffect, useRef, useState } from 'react';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay/PickersDay';
import { Box } from '@mui/material';
import {
  DateField,
  DateFieldProps,
  DatePicker,
  DatePickerProps,
  PickersDay,
  TimeField,
  TimeFieldProps,
  TimePickerProps,
} from '@mui/x-date-pickers-pro';
import { useCancellableSubscription } from '../hooks/useCancellableSubscription';
import graphql from 'babel-plugin-relay/macro';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { FormDateTimePicker_RoundedDateTimePickerQuery } from './__generated__/FormDateTimePicker_RoundedDateTimePickerQuery.graphql';
import { _throw } from '../utils/_throw';
import { useEffectEvent } from '../utils/effectUtils';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

type DateInputPickerProps = {
  fieldOnly?: false;
} & Omit<DatePickerProps<DateTime>, 'value' | 'onChange'>;
type DateInputFieldProps = {
  fieldOnly: true;
} & Omit<DateFieldProps<DateTime>, 'value' | 'onChange'>;
type DateInputProps = DateInputPickerProps | DateInputFieldProps;

type TimeInputPickerProps = {
  fieldOnly?: false;
} & Omit<TimePickerProps<DateTime>, 'value' | 'onChange'>;
type TimeInputFieldProps = {
  fieldOnly: true;
} & Omit<TimeFieldProps<DateTime>, 'value' | 'onChange'>;
type TimeInputProps = TimeInputPickerProps | TimeInputFieldProps;

type RoundedDateTimePickerJoinedProps = {
  split?: false;
} & DateTimePickerProps<DateTime>;

type RoundedDateTimePickerSplitProps = {
  split: true;
  value: DateTimePickerProps<DateTime>['value'];
  onChange: DateTimePickerProps<DateTime>['onChange'];
  dateInput: DateInputProps;
  timeInput: TimeInputProps;
};

type RoundedDateTimePickerProps = RoundedDateTimePickerJoinedProps | RoundedDateTimePickerSplitProps;

type DateTimeState = 'empty' | 'invalid' | 'valid';

function getDateTimeState(val: DateTime | null): DateTimeState {
  if (!val) {
    return 'empty';
  }
  if (!val.isValid) {
    return 'invalid';
  }
  return 'valid';
}

/** Whether to fetch statutory holidays for the 3 closest months or years. */
const statutoryHolidaysFetchPeriod: 'year' | 'month' = 'year';

export const RoundedDateTimePicker = forwardRef(function RoundedDateTimePicker(
  { value, ...props }: RoundedDateTimePickerProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const env = useRelayEnvironment();
  const [, setSubscription] = useCancellableSubscription();

  // A date within the month currently displayed in the calendar
  const [displayedDate, setDisplayedDate] = useState<DateTime | null>(null);
  // A date within the last fetched statutory holiday period
  const [fetchedDate, setFetchedDate] = useState<DateTime | null>(null);
  // The list of statutory holidays for the current value of fetchedDate
  const [statutoryHolidays, setStatutoryHolidays] = useState<DateTime[]>([]);

  /** Fetch statutory holidays for the 3 {@link statutoryHolidaysFetchPeriod} closest to the provided date. */
  const fetchStatutoryHolidays = useEffectEvent((dateTime: DateTime) => {
    // Load 3 `statutoryHolidaysFetchPeriod` worth of statutory holidays to ensure we have enough data in case
    // DateTimePicker's `showDaysOutsideCurrentMonth` attribute is set to true, which can lead to some days outside the
    // current `statutoryHolidaysFetchPeriod` to be displayed. Also helps hiding data fetching latency.
    const startDate = dateTime.minus({ [statutoryHolidaysFetchPeriod]: 1 }).startOf(statutoryHolidaysFetchPeriod);
    const endDate = dateTime.plus({ [statutoryHolidaysFetchPeriod]: 1 }).endOf(statutoryHolidaysFetchPeriod);

    setSubscription(
      fetchQuery<FormDateTimePicker_RoundedDateTimePickerQuery>(
        env,
        graphql`
          query FormDateTimePicker_RoundedDateTimePickerQuery($where: StatutoryHolidayFilterInput) {
            statutoryHolidays(where: $where) {
              date
            }
          }
        `,
        {
          where: {
            and: [
              { date: { gte: startDate.toISODate() ?? _throw('Invalid date') } },
              { date: { lte: endDate.toISODate() ?? _throw('Invalid date') } },
            ],
          },
        },
      ).subscribe({
        next: (data) => {
          setStatutoryHolidays(
            data.statutoryHolidays.map(
              ({ date }) => parseDateTime(date) ?? _throw(`RoundedDateTimePicker: Invalid date string received from API: ${date}`),
            ),
          );
          setFetchedDate(dateTime);
        },
      }),
    );
  });

  useEffect(() => {
    if (displayedDate && (!fetchedDate || !displayedDate.hasSame(fetchedDate, statutoryHolidaysFetchPeriod))) {
      fetchStatutoryHolidays(displayedDate);
    }
  }, [displayedDate, fetchStatutoryHolidays, fetchedDate]);

  const minutesStep = props.split ? props.timeInput.minutesStep : props.minutesStep;

  const lastDate = useRef<DateTimeState>(getDateTimeState(value ?? null));
  const lastTime = useRef<DateTimeState>(getDateTimeState(value ?? null));

  const handleChange: NonNullable<DateTimePickerProps<DateTime>['onChange']> = (val, context) => {
    // Replicates the joined field behavior from Mui's DateTimeField which doesn't produce changes on invalid values.
    if (
      lastDate.current === 'invalid' ||
      lastTime.current === 'invalid' ||
      (lastDate.current === 'empty' && lastTime.current !== 'empty') ||
      (lastDate.current !== 'empty' && lastTime.current === 'empty')
    ) {
      return;
    }

    props.onChange?.(val, context);
  };

  const handleDateChange: NonNullable<DateTimePickerProps<DateTime>['onChange']> = (val, context) => {
    lastDate.current = getDateTimeState(val);
    handleChange(val, context);
  };

  const handleTimeChange: NonNullable<DateTimePickerProps<DateTime>['onChange']> = (val, context) => {
    lastTime.current = getDateTimeState(val);
    handleChange(val, context);
  };

  const handleBlur = () => {
    if (!value || !minutesStep) {
      return;
    }

    const rounded = roundDateTimeToMinuteStep(value, minutesStep);
    if (!rounded.equals(value)) {
      props.onChange?.(rounded, { validationError: null });
    }
  };

  if (props.split) {
    return (
      <Box sx={{ display: 'flex', gap: '0.25rem' }}>
        {props.dateInput.fieldOnly ? (
          <DateField
            {...props.dateInput}
            onChange={handleDateChange}
            clearable
            value={value}
            slotProps={{
              ...props.dateInput.slotProps,
              textField: {
                ...props.dateInput.slotProps?.textField,
                onBlur: handleBlur,
              },
            }}
          />
        ) : (
          <DatePicker
            {...props.dateInput}
            onChange={handleDateChange}
            value={value}
            slotProps={{
              ...props.dateInput.slotProps,
              textField: {
                ...props.dateInput.slotProps?.textField,
                onBlur: handleBlur,
              },
            }}
          />
        )}
        {props.timeInput.fieldOnly ? (
          <TimeField
            {...props.timeInput}
            ref={ref}
            value={value}
            onChange={handleTimeChange}
            slotProps={{
              ...props.timeInput.slotProps,
              textField: {
                // Fix alignment issue caused by this field not having a label. See the 0.25rem margin top set for MuiTextField in theme.ts
                sx: { pt: '0.25rem' },
                ...props.timeInput.slotProps?.textField,
                onBlur: handleBlur,
              },
            }}
          />
        ) : (
          <TimePicker
            {...props.timeInput}
            ref={ref}
            value={value}
            onChange={handleTimeChange}
            slotProps={{
              ...props.timeInput.slotProps,
              textField: {
                // Fix alignment issue caused by this field not having a label. See the 0.25rem margin top set for MuiTextField in theme.ts
                sx: { pt: '0.25rem' },
                ...props.timeInput.slotProps?.textField,
                onBlur: handleBlur,
              },
            }}
          />
        )}
      </Box>
    );
  }

  return (
    <DateTimePicker<DateTime>
      {...props}
      ref={ref}
      value={value}
      onChange={handleChange}
      onOpen={() => {
        setDisplayedDate(value ?? DateTime.now());
      }}
      onMonthChange={(month) => {
        setDisplayedDate(month);
      }}
      onYearChange={(year) => {
        setDisplayedDate(year);
      }}
      slots={{ day: RoundedDateTimePickerDay }}
      slotProps={{
        ...props.slotProps,
        actionBar: {
          actions: ['clear', 'cancel', 'accept'],
          sx: {
            'button:first-of-type': {
              mr: 'auto', // left align the first action in the actionBar, in this case the clear button
            },
          },
        },
        field: {
          clearable: true,
        },
        textField: {
          ...props.slotProps?.textField,
          onBlur: handleBlur,
        },
        toolbar: {
          sx: {
            '.MuiDateTimePickerToolbar-timeDigitsContainer': {
              alignItems: 'center', // fix time digits & colon alignment issue in the toolbar
            },
          },
        },
        day: {
          ...props.slotProps?.day,
          ...{ statutoryHolidays }, // spreading is used to avoid a type error as statutoryHolidays is a custom prop
        },
      }}
    />
  );
});

function RoundedDateTimePickerDay({
  statutoryHolidays = [],
  ...props
}: PickersDayProps<DateTime> & RefAttributes<HTMLButtonElement> & { statutoryHolidays?: DateTime[] }) {
  const { isWeekend } = props.day;
  const isStatutoryHoliday = () => statutoryHolidays.some((date) => date.hasSame(props.day, 'day'));
  const displayBackground = (props.showDaysOutsideCurrentMonth || !props.outsideCurrentMonth) && (isWeekend || isStatutoryHoliday());
  const isPastDate = props.day < DateTime.now().startOf('day');

  return (
    <Box sx={(theme) => ({ backgroundColor: displayBackground ? theme.palette.background.default : undefined })}>
      <PickersDay {...props} sx={(theme) => ({ color: isPastDate ? theme.palette.text.disabled : undefined })} />
    </Box>
  );
}
