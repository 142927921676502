export const appUpdate = {
  updateInProgress: "Mise à jour de l'application en cours, veuillez patienter...",
  snackbar: {
    title: 'Mise à jour disponible',
    description:
      'Une mise à jour de la dernière version disponible sera effectuée dans <strong>{{countdown}}</strong>. Enregistrez vos modifications avant de mettre à jour, sans quoi elles seront perdues.',
    buttons: {
      close: 'Plus tard',
      update: 'Mettre à jour',
    },
  },
};
