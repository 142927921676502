/**
 * @generated SignedSource<<ab04f9578d1715e599583dd5a317bb77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_WorksiteOverridesAddressReadFragment$data = {
  readonly address: string;
  readonly structuredAddress: {
    readonly value: {
      readonly streetAddress: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ProjectBaseFields_WorksiteOverridesAddressReadFragment";
};
export type ProjectBaseFields_WorksiteOverridesAddressReadFragment$key = {
  readonly " $data"?: ProjectBaseFields_WorksiteOverridesAddressReadFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesAddressReadFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_WorksiteOverridesAddressReadFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StructuredAddressResult",
      "kind": "LinkedField",
      "name": "structuredAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StructuredAddressData",
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "streetAddress",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Location",
  "abstractKey": null
};

(node as any).hash = "fe8c14ad4c030a65fa0d343a0a31ca4b";

export default node;
