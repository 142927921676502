import * as Sentry from '@sentry/react';
import { useTheme } from '@mui/material';
import ErrorFallback from './ErrorFallback';
import { ListLayout, SidebarContentProps } from './Layouts';
import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationMenu } from './SidebarDrawer';
import { errorBoundaryBeforeCaptureFn } from '../AppErrorBoundary';
import { Logger } from '../common/utils/logging';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { ListPageRootErrorBoundaryFragment$key } from './__generated__/ListPageRootErrorBoundaryFragment.graphql';
import { useRetryCallback } from './useRetryCallback';

const logger = new Logger('ListPageRootErrorBoundary');

export function ListPageRootErrorBoundary({
  heading,
  flagName,
  $key,
  children,
}: {
  heading: string;
  flagName: string;
  $key: ListPageRootErrorBoundaryFragment$key;
  children: ReactNode;
}) {
  const theme = useTheme();
  const { t } = useTranslation('common');
  const handleRetry = useRetryCallback();

  const $data = useFragment(
    graphql`
      fragment ListPageRootErrorBoundaryFragment on Query {
        ...SidebarDrawerFragment
        ...LayoutsListLayoutFragment
      }
    `,
    $key,
  );

  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => (
        <ListLayout heading={heading} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
          <ErrorFallback
            error={error}
            subtitleMessage={t('requestSupport')}
            onButtonClick={handleRetry(resetError)}
            buttonMessage={t('button.retry')}
          />
        </ListLayout>
      )}
      beforeCapture={(scope) => errorBoundaryBeforeCaptureFn(scope, theme)}
      onError={(...args) => {
        logger.error(...args);
      }}>
      {children}
    </Sentry.ErrorBoundary>
  );
}
