/**
 * @generated SignedSource<<df9da4ff82daf9db283e63d5d1bc57e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type JibKind = "fixedLattice" | "foldable" | "variableSpanLattice" | "variableSpanLatticeWithOffset" | "%future added value";
import { FragmentRefs } from "relay-runtime";
import { label as vehicleIdLabelResolverType } from "../../__resolvers__/VehicleIdLabelResolver";
// Type assertion validating that `vehicleIdLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(vehicleIdLabelResolverType satisfies (
  rootKey: VehicleIdLabelResolver$key,
) => string | null | undefined);
export type useFavoriteEquipment_useSynchronizeFavoriteEquipments_FromQueryPassthroughFragment$data = {
  readonly boomConfigurationName: string | null | undefined;
  readonly boomExtensionLength: string | null | undefined;
  readonly boomLength: string;
  readonly counterweight: string;
  readonly displayUsagePercentage: number;
  readonly jib: {
    readonly mainBoomAngleOffset?: string;
    readonly offsetAngle: string;
  } | null | undefined;
  readonly jibExtensionLength: string;
  readonly jibKind: JibKind | null | undefined;
  readonly jibLength: string;
  readonly maxWeight: string;
  readonly radius: string;
  readonly vehicleId: {
    readonly key: string;
    readonly label: string | null | undefined;
  };
  readonly " $fragmentType": "useFavoriteEquipment_useSynchronizeFavoriteEquipments_FromQueryPassthroughFragment";
};
export type useFavoriteEquipment_useSynchronizeFavoriteEquipments_FromQueryPassthroughFragment$key = {
  readonly " $data"?: useFavoriteEquipment_useSynchronizeFavoriteEquipments_FromQueryPassthroughFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_useSynchronizeFavoriteEquipments_FromQueryPassthroughFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mainBoomAngleOffset",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useFavoriteEquipment_useSynchronizeFavoriteEquipments_FromQueryPassthroughFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boomConfigurationName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boomLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boomExtensionLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jibLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jibExtensionLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "counterweight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VehicleId",
      "kind": "LinkedField",
      "name": "vehicleId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VehicleIdLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../__resolvers__/VehicleIdLabelResolver').label,
          "path": "vehicleId.label"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUsagePercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "radius",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jibKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "jib",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "offsetAngle",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "JibVariable",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "JibVariableWithExtension",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AutomaticConfigurationInfo",
  "abstractKey": null
};
})();

(node as any).hash = "a927c7457dda6a83e94eed518feba65f";

export default node;
