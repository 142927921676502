import {
  createFieldKey,
  createFormContext,
  SetValueFn,
  useField,
  useFieldErrors,
  useFieldIsDirty,
  useFieldMapper,
  useFieldValidation,
} from '../../common/utils/forms';
import { Addendum } from './AddendaFields';
import {
  AddendumTemplateAutocomplete,
  ForwardAddendumTemplateAutocompleteProps,
} from '../../common/components/AddendumTemplateAutocomplete';
import { flagDirty } from '../../common/utils/patchable';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { useCallback } from 'react';
import { AddendumKind, addendumKinds } from '../../__enums__/AddendumKind';
import { SelectPicker, SelectPickerProps } from '../../common/components/SelectPicker';
import { InputAdornment, TextField, Theme, Typography, useMediaQuery } from '@mui/material';
import { SxProps } from '@mui/system';
import { ClientLanguage } from '../../__enums__/ClientLanguage';
import { resolvedLanguage } from '../../i18n';
import { RequiredForInputLabel } from '../RequiredForJobStatus';

export const addendumSubFormContext = createFormContext<{ sync: Addendum }>();

const fieldAddendumIdKey = createFieldKey<string>();
export function useFieldAddendumId(initialValue: string) {
  const [value] = useField(addendumSubFormContext, fieldAddendumIdKey, () => initialValue);

  const useMapper = useFieldMapper(addendumSubFormContext, fieldAddendumIdKey);
  useMapper((v) => ({ id: v }), [], 'sync');

  return value;
}

export type FieldAddendumTemplate = NonNullable<ForwardAddendumTemplateAutocompleteProps['value']> | null;
const fieldAddendumTemplateKey = createFieldKey<FieldAddendumTemplate>();
export function useFieldAddendumTemplate(initialValue: FieldAddendumTemplate | undefined, disabled: boolean) {
  const [addendumTemplate, setAddendumTemplate] = useField(addendumSubFormContext, fieldAddendumTemplateKey, () => initialValue ?? null);

  const useMapper = useFieldMapper(addendumSubFormContext, fieldAddendumTemplateKey);
  useMapper((type, { isDirty }) => flagDirty({ template: type }, { template: isDirty }), [], 'sync');

  const addendumTemplateDirty = useFieldIsDirty(addendumSubFormContext, fieldAddendumTemplateKey);

  const renderAddendumTemplate = useCallback(
    (lang: ClientLanguage, sx?: SxProps<Theme>) => (
      <AddendumTemplateInput value={addendumTemplate} setValue={setAddendumTemplate} lang={lang} disabled={disabled} sx={sx} />
    ),
    [addendumTemplate, disabled, setAddendumTemplate],
  );

  return { addendumTemplate, setAddendumTemplate, renderAddendumTemplate, addendumTemplateDirty };
}
function AddendumTemplateInput({
  value,
  setValue,
  lang,
  disabled,
  sx,
}: {
  value: FieldAddendumTemplate;
  setValue: SetValueFn<FieldAddendumTemplate>;
  lang: string;
  disabled: boolean;
  sx?: SxProps<Theme>;
}) {
  const { t, i18n } = useAmbientTranslation();
  const appLang = resolvedLanguage(i18n);

  return (
    <AddendumTemplateAutocomplete
      value={value}
      onChange={(v) => {
        // HACK workaround to prevent a race condition from crashing the autocomplete when suspending
        setTimeout(() => setValue(v));
      }}
      sx={sx}
      disabled={disabled}
      textFieldProps={(params) => ({
        ...params,
        label: t('placeholder.addAddenda'),
        placeholder: t('field.addenda.addEmpty', { ns: 'jobs' }),
        InputProps: {
          ...params.InputProps,
          startAdornment: appLang !== lang && (
            <InputAdornment position='start' sx={{ pl: '0.5rem' }}>
              <Typography variant='subtitle2'>({t(`languageShort.${lang}`, { ns: 'client' })})</Typography>
            </InputAdornment>
          ),
        },
      })}
    />
  );
}

export type FieldAddendumTitle = string;
const fieldAddendumTitleKey = createFieldKey<FieldAddendumTitle>();
export function useFieldAddendumTitle(initialValue: FieldAddendumTitle, disabled: boolean) {
  const [addendumTemplate, setAddendumTitle] = useField(addendumSubFormContext, fieldAddendumTitleKey, () => initialValue);

  const useMapper = useFieldMapper(addendumSubFormContext, fieldAddendumTitleKey);
  useMapper((type, { isDirty }) => flagDirty({ title: type }, { title: isDirty }), [], 'sync');

  const useValidation = useFieldValidation(addendumSubFormContext, fieldAddendumTitleKey);
  useValidation((v, { isDirty }) => (isDirty ? !!v : true), [], 'change:required');

  const renderAddendumTitle = useCallback(
    (sx?: SxProps<Theme>) => <AddendumTitleInput value={addendumTemplate} setValue={setAddendumTitle} disabled={disabled} sx={sx} />,
    [addendumTemplate, disabled, setAddendumTitle],
  );

  return { addendumTemplate, setAddendumTitle, renderAddendumTitle };
}
function AddendumTitleInput({
  value,
  setValue,
  disabled,
  sx,
}: {
  value: FieldAddendumTitle;
  setValue: SetValueFn<FieldAddendumTitle>;
  disabled: boolean;
  sx?: SxProps<Theme>;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(addendumSubFormContext, fieldAddendumTitleKey);

  return (
    <TextField
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => setValue(e.target.value.trim())}
      label={
        <RequiredForInputLabel
          label={t('field.addenda.title')}
          requiredFor={{
            quote: ['submit'],
            serviceCall: ['reserve', 'transfer'],
          }}
          disabled={disabled}
        />
      }
      disabled={disabled}
      sx={sx}
      error={Object.keys(errors).length > 0}
    />
  );
}

export const addendumKindPriorities: Record<AddendumKind, number> = {
  clientOrWorksite: 2,
  quote: 1,
  invoicing: 5,
  dispatching: 3,
  craneOperator: 4,
  internalNote: 6,
  archived: 7,
};
export const SELECTABLE_ADDENDUMKINDS_BY_PRIORITY = addendumKinds
  .filter((a) => a !== 'archived')
  .slice()
  .sort((a, b) => addendumKindPriorities[a] - addendumKindPriorities[b]);

export type FieldAddendumKind = SelectPickerProps<AddendumKind>['value'];
const fieldAddendumKindKey = createFieldKey<FieldAddendumKind>();
export function useFieldAddendumKind(initialValue: FieldAddendumKind, disabled: boolean) {
  const [addendumKind, setAddendumKind] = useField(addendumSubFormContext, fieldAddendumKindKey, () => initialValue ?? 'internalNote');

  const useMapper = useFieldMapper(addendumSubFormContext, fieldAddendumKindKey);
  useMapper((type, { isDirty }) => flagDirty({ kind: type }, { kind: isDirty }), [], 'sync');

  const useValidation = useFieldValidation(addendumSubFormContext, fieldAddendumKindKey);
  useValidation((v) => !!v, [], 'change:required');

  const renderAddendumKind = useCallback(
    (sx?: SxProps<Theme>) => <AddendumKindInput value={addendumKind} setValue={setAddendumKind} disabled={disabled} sx={sx} />,
    [addendumKind, disabled, setAddendumKind],
  );

  return { addendumKind, setAddendumKind, renderAddendumKind };
}
function AddendumKindInput({
  value,
  setValue,
  disabled,
  sx,
}: {
  value: FieldAddendumKind;
  setValue: SetValueFn<FieldAddendumKind>;
  disabled: boolean;

  sx?: SxProps<Theme>;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(addendumSubFormContext, fieldAddendumKindKey);

  return (
    <SelectPicker
      value={value}
      disableClearable={!!value}
      onChange={(_, v) => setValue(v)}
      options={SELECTABLE_ADDENDUMKINDS_BY_PRIORITY}
      disabled={disabled}
      sx={sx}
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`addendumKind.${o}`, { ns: 'jobs' })}
      textFieldProps={() => ({
        error: Object.keys(errors).length > 0,
        label: t('field.addenda.kind'),
      })}
    />
  );
}

export type FieldAddendumDescription = string;
const fieldAddendumDescriptionKey = createFieldKey<FieldAddendumDescription>();
export function useFieldAddendumDescription(initialValue: FieldAddendumDescription, disabled: boolean) {
  const [addendumTemplate, setAddendumDescription] = useField(addendumSubFormContext, fieldAddendumDescriptionKey, () => initialValue);

  const useMapper = useFieldMapper(addendumSubFormContext, fieldAddendumDescriptionKey);
  useMapper((type, { isDirty }) => flagDirty({ description: type }, { description: isDirty }), [], 'sync');

  const useValidation = useFieldValidation(addendumSubFormContext, fieldAddendumDescriptionKey);
  useValidation((v, { isDirty }) => (isDirty ? !!v : true), [], 'change:required');

  const renderAddendumDescription = useCallback(
    (sx?: SxProps<Theme>) => (
      <AddendumDescriptionInput value={addendumTemplate} setValue={setAddendumDescription} disabled={disabled} sx={sx} />
    ),
    [addendumTemplate, disabled, setAddendumDescription],
  );

  return { addendumTemplate, setAddendumDescription, renderAddendumDescription };
}
function AddendumDescriptionInput({
  value,
  setValue,
  disabled,
  sx,
}: {
  value: FieldAddendumDescription;
  setValue: SetValueFn<FieldAddendumDescription>;
  disabled: boolean;
  sx?: SxProps<Theme>;
}) {
  const { t } = useAmbientTranslation();
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const errors = useFieldErrors(addendumSubFormContext, fieldAddendumDescriptionKey);

  return (
    <TextField
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => setValue(e.target.value.trim())}
      multiline={true}
      minRows={1}
      maxRows={compact ? 20 : 10}
      label={
        <RequiredForInputLabel
          label={t('field.addenda.description')}
          requiredFor={{
            quote: ['submit'],
            serviceCall: ['reserve', 'transfer'],
          }}
          disabled={disabled}
        />
      }
      disabled={disabled}
      sx={sx}
      error={Object.keys(errors).length > 0}
    />
  );
}
