/**
 * @generated SignedSource<<8b59f8b316f0f9e679c1eab0657eec8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CraneSelectorFields_CraneConfigurationSnapshotFragment$data = {
  readonly snapshotId: string | null | undefined;
  readonly " $fragmentType": "CraneSelectorFields_CraneConfigurationSnapshotFragment";
};
export type CraneSelectorFields_CraneConfigurationSnapshotFragment$key = {
  readonly " $data"?: CraneSelectorFields_CraneConfigurationSnapshotFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_CraneConfigurationSnapshotFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CraneSelectorFields_CraneConfigurationSnapshotFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "snapshotId",
      "storageKey": null
    }
  ],
  "type": "AutomaticConfigurationInternal",
  "abstractKey": null
};

(node as any).hash = "586cbe0c29c4bef05997bae4e62930d7";

export default node;
