/**
 * @generated SignedSource<<1e984fe824108aa3a9e23d0556baf908>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientBaseFields_ClientOverridesName_ReadFragment$data = {
  readonly name: string;
  readonly " $fragmentType": "ClientBaseFields_ClientOverridesName_ReadFragment";
};
export type ClientBaseFields_ClientOverridesName_ReadFragment$key = {
  readonly " $data"?: ClientBaseFields_ClientOverridesName_ReadFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_ClientOverridesName_ReadFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientBaseFields_ClientOverridesName_ReadFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "IOverridableClient",
  "abstractKey": "__isIOverridableClient"
};

(node as any).hash = "0c44b6576f97cbd0d41084d353dbf6e6";

export default node;
