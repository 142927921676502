import {
  createFieldKey,
  SetValueFn,
  useField,
  useFieldErrorsFirstMessage,
  useFieldHasErrors,
  useFieldIsDirty,
  useFieldMapper,
  useFieldSetter,
  useFieldValidation,
  useFieldValue,
} from '../../common/utils/forms';
import { DataID, useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { DateTime } from 'luxon';
import { jobStageBaseFormContext } from '../JobStageBaseFields';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { Box, Chip, CircularProgress, IconButton, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import { exclude } from '../../common/utils/arrayUtils';
import InfoIcon from '@mui/icons-material/Info';
import { RequirementsDialog } from '../../common/dialogs/RequirementsDialog';
import {
  ForwardWorksiteRequirementAutocompleteProps,
  WorksiteRequirementAutocomplete,
} from '../../common/components/WorksiteRequirementAutocomplete';
import { MaskInput } from '../../common/components/MaskInput';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { RoundedDateTimePicker } from '../../common/components/FormDateTimePicker';
import { dateFormat, dateTimeFormat, parseDateTime, timeFormat } from '../../common/utils/dateTimeUtils';
import { ProjectBaseFields_AssignedWorksiteFragment$key } from './__generated__/ProjectBaseFields_AssignedWorksiteFragment.graphql';
import { ProjectBaseFields_DurationHoursPerDayFragment$key } from './__generated__/ProjectBaseFields_DurationHoursPerDayFragment.graphql';
import { ProjectBaseFields_DurationLengthInDaysFragment$key } from './__generated__/ProjectBaseFields_DurationLengthInDaysFragment.graphql';
import { ProjectBaseFields_ArrivalDateFragment$key } from './__generated__/ProjectBaseFields_ArrivalDateFragment.graphql';
import { ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment$key } from './__generated__/ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment.graphql';
import { ProjectBaseFields_WorksiteOverrides_ContactFragment$key } from './__generated__/ProjectBaseFields_WorksiteOverrides_ContactFragment.graphql';
import { ProjectBaseFields_RequirementsFragment$key } from './__generated__/ProjectBaseFields_RequirementsFragment.graphql';
import { NumberInput } from '../../common/components/NumberInput';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import {
  useFieldAssignedClientRead,
  useFieldClientOverridesAddressRead,
  useFieldClientOverridesApartmentRead,
  useFieldClientOverridesCityRead,
  useFieldClientOverridesCoordinatesRead,
  useFieldClientOverridesIsDirectSalesRead,
  useFieldClientOverridesPostalCodeRead,
  useFieldClientOverridesStateRead,
} from './ClientBaseFields';
import { ProjectBaseFields_CopyClientAddressButtonFragment$key } from './__generated__/ProjectBaseFields_CopyClientAddressButtonFragment.graphql';
import { ProjectBaseFields_QueriedCopyClientAddressButtonQuery } from './__generated__/ProjectBaseFields_QueriedCopyClientAddressButtonQuery.graphql';
import { FallbackErrorBoundary } from '../../common/components/FallbackErrorBoundary';
import {
  AutomaticSuggestionList,
  emptySuggestionPromptInput,
  scoreThreshold,
  suggestionCount,
  Suggestions,
} from '../../common/components/Suggestions';
import { SuggestionPromptInput } from '../../common/components/__generated__/SuggestionsFakeQuery.graphql';
import { ProjectBaseFields_DurationLengthInDays_SuggestionsQuery } from './__generated__/ProjectBaseFields_DurationLengthInDays_SuggestionsQuery.graphql';
import { useSuggestions } from '../useSuggestions';
import { ProjectBaseFields_DurationLengthInDaysInput_SuggestionsFragment$key } from './__generated__/ProjectBaseFields_DurationLengthInDaysInput_SuggestionsFragment.graphql';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery } from './__generated__/ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery.graphql';
import { ProjectBaseFields_DurationHoursPerDayInput_SuggestionsFragment$key } from './__generated__/ProjectBaseFields_DurationHoursPerDayInput_SuggestionsFragment.graphql';
import { ProjectBaseFields_WorksiteRequirementsSuggestionsFragment$key } from './__generated__/ProjectBaseFields_WorksiteRequirementsSuggestionsFragment.graphql';
import { _throw } from '../../common/utils/_throw';
import { useHasWorksiteInfo } from '../AssignedWorksite';
import { RequiredForInputLabel, RequiredForStatus } from '../RequiredForJobStatus';
import { JobStage } from '../jobStage';
import { ProjectBaseFields_ArrivalDateReadFragment$key } from './__generated__/ProjectBaseFields_ArrivalDateReadFragment.graphql';
import { useFlag } from '@unleash/proxy-client-react';
import { SearchBoxSuggestion } from '@mapbox/search-js-core';
import { QuoteKind } from '../../__enums__/QuoteKind';
import { Coordinates } from '../../common/types/coordinates';
import { ProjectBaseFields_WorksiteAddressInput_ClientFragment$key } from './__generated__/ProjectBaseFields_WorksiteAddressInput_ClientFragment.graphql';
import { ProjectBaseFields_useHasSatisfiedRequiredWorksiteAddressFieldsFragment$key } from './__generated__/ProjectBaseFields_useHasSatisfiedRequiredWorksiteAddressFieldsFragment.graphql';
import { ProjectBaseFields_CopyClientAddressButton_ClientFragment$key } from './__generated__/ProjectBaseFields_CopyClientAddressButton_ClientFragment.graphql';
import { ProjectBaseFields_QueriedCopyClientAddressButtonFragment$key } from './__generated__/ProjectBaseFields_QueriedCopyClientAddressButtonFragment.graphql';
import { ProjectBaseFields_WorksiteAddressInputFragment$key } from './__generated__/ProjectBaseFields_WorksiteAddressInputFragment.graphql';
import { ProjectBaseFields_WorksiteOverridesCoordinatesFragment$key } from './__generated__/ProjectBaseFields_WorksiteOverridesCoordinatesFragment.graphql';
import { ProjectBaseFields_WorksiteOverridesAddressFragment$key } from './__generated__/ProjectBaseFields_WorksiteOverridesAddressFragment.graphql';
import { ProjectBaseFields_WorksiteOverridesAddress_ClientFragment$key } from './__generated__/ProjectBaseFields_WorksiteOverridesAddress_ClientFragment.graphql';
import { ProjectBaseFields_WorksiteOverridesPostalCodeFragment$key } from './__generated__/ProjectBaseFields_WorksiteOverridesPostalCodeFragment.graphql';
import { ProjectBaseFields_WorksiteOverridesApartmentFragment$key } from './__generated__/ProjectBaseFields_WorksiteOverridesApartmentFragment.graphql';
import { ProjectBaseFields_WorksiteOverridesCityFragment$key } from './__generated__/ProjectBaseFields_WorksiteOverridesCityFragment.graphql';
import { ProjectBaseFields_WorksiteOverridesStateFragment$key } from './__generated__/ProjectBaseFields_WorksiteOverridesStateFragment.graphql';
import { MapboxMap, MapboxSearchInput, MapboxSearchInputResponse } from '../../common/components/Mapbox';
import { ProjectBaseFields_WorksiteOverridesName_ReadFragment$key } from './__generated__/ProjectBaseFields_WorksiteOverridesName_ReadFragment.graphql';
import { ProjectBaseFields_WorksiteOverridesName_ClientFragment$key } from './__generated__/ProjectBaseFields_WorksiteOverridesName_ClientFragment.graphql';
import { ProjectBaseFields_WorksiteOverridesNameFragment$key } from './__generated__/ProjectBaseFields_WorksiteOverridesNameFragment.graphql';
import { ProjectBaseFields_WorksiteOverridesAddressReadFragment$key } from './__generated__/ProjectBaseFields_WorksiteOverridesAddressReadFragment.graphql';

export type FieldAssignedWorksite = { id: DataID; etag: string } | null;
const fieldAssignedWorksiteKey = createFieldKey<FieldAssignedWorksite>();
export function useFieldAssignedWorksiteRead($key: ProjectBaseFields_AssignedWorksiteFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_AssignedWorksiteFragment on ProjectInternalBase {
        assignedWorksiteId
        assignedWorksiteInfo {
          ...AssignedWorksite_UseHasWorksiteInfoFragment
        }
      }
    `,
    $key,
  );
  const hasWorksiteInfo = useHasWorksiteInfo($data?.assignedWorksiteInfo);
  const assignedWorksite = useFieldValue(jobStageBaseFormContext, fieldAssignedWorksiteKey, () =>
    !$data?.assignedWorksiteId
      ? hasWorksiteInfo
        ? { id: 'new', etag: nanoid() }
        : null
      : { id: $data.assignedWorksiteId, etag: nanoid() },
  );
  const assignedWorksiteIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldAssignedWorksiteKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldAssignedWorksiteKey);
  useMapper((v) => ({ projectBase: { assignedWorksiteId: v?.id && v.id !== 'new' ? v.id : null } }), [], 'save');
  useMapper((v) => ({ salesRatesInput: { worksiteId: v?.id && v.id !== 'new' ? v.id : null } }), [], 'recalculate');

  return { assignedWorksite, assignedWorksiteIsDirty };
}

export function useFieldAssignedWorksite($key: ProjectBaseFields_AssignedWorksiteFragment$key | null | undefined) {
  const rest = useFieldAssignedWorksiteRead($key);
  const setAssignedWorksite = useFieldSetter(jobStageBaseFormContext, fieldAssignedWorksiteKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldAssignedWorksiteKey);
  useValidation((val) => val != null || 'error.worksiteIsRequired', [], 'transferable,submittable,upgradable:required');

  return { setAssignedWorksite, ...rest };
}

export function useFieldAssignedWorksiteErrors() {
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldAssignedWorksiteKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(jobStageBaseFormContext, fieldAssignedWorksiteKey);

  return { assignedWorksiteErrorMessage: message, assignedWorksiteErrorParams: errorParams, assignedWorksiteHasErrors: hasErrors };
}

export type FieldWorksiteName = string;
const fieldWorksiteOverridesNameKey = createFieldKey<FieldWorksiteName>();
export function useFieldWorksiteOverridesNameRead($key: ProjectBaseFields_WorksiteOverridesName_ReadFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteOverridesName_ReadFragment on IOverridableWorksite {
        name
      }
    `,
    $key,
  );
  const worksiteOverridesName = useFieldValue(jobStageBaseFormContext, fieldWorksiteOverridesNameKey, () => $data?.name ?? '');

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldWorksiteOverridesNameKey);
  useMapper((v) => ({ projectBase: { assignedWorksiteInfo: { name: v } } }), [], 'save');

  return { worksiteOverridesName };
}

const WORKSITE_NAME_MAX_LENGTH = 30;
export function useFieldWorksiteOverridesName(
  $key: ProjectBaseFields_WorksiteOverridesNameFragment$key | null | undefined,
  client$key: ProjectBaseFields_WorksiteOverridesName_ClientFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteOverridesNameFragment on IOverridableWorksite {
        ...ProjectBaseFields_WorksiteOverridesName_ReadFragment
        location {
          ...ProjectBaseFields_WorksiteOverridesAddressFragment
          structuredAddress {
            ...ProjectBaseFields_WorksiteOverridesCoordinatesFragment
            ...ProjectBaseFields_WorksiteOverridesCityFragment
            ...ProjectBaseFields_WorksiteOverridesStateFragment
            ...ProjectBaseFields_WorksiteOverridesPostalCodeFragment
          }
        }
      }
    `,
    $key,
  );

  const client$data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteOverridesName_ClientFragment on ClientInternalBase {
        ...ProjectBaseFields_WorksiteOverridesAddress_ClientFragment
      }
    `,
    client$key,
  );

  const { worksiteOverridesName, ...rest } = useFieldWorksiteOverridesNameRead($data);
  const setWorksiteOverridesName = useFieldSetter(jobStageBaseFormContext, fieldWorksiteOverridesNameKey);
  const { setWorksiteOverridesAddress } = useFieldWorksiteOverridesAddress($data?.location, client$data, disabled, required);
  const { setWorksiteOverridesCoordinates } = useFieldWorksiteOverridesCoordinates($data?.location?.structuredAddress, disabled);
  const { setWorksiteOverridesCity } = useFieldWorksiteOverridesCity($data?.location?.structuredAddress, disabled, required);
  const { setWorksiteOverridesState } = useFieldWorksiteOverridesState($data?.location?.structuredAddress, disabled, required);
  const { setWorksiteOverridesPostalCode } = useFieldWorksiteOverridesPostalCode($data?.location?.structuredAddress, disabled, required);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldWorksiteOverridesNameKey);
  useValidation((v) => !required || !!v?.trim(), [required], 'transferable,submittable:required');

  const renderWorksiteOverridesName = useCallback(
    () => (
      <WorksiteNameInput
        value={worksiteOverridesName}
        onChange={(v, response) => {
          if (typeof v === 'string') {
            setWorksiteOverridesName(v.trim().substring(0, WORKSITE_NAME_MAX_LENGTH).trim());
          } else {
            setWorksiteOverridesName((response?.name ?? '').substring(0, WORKSITE_NAME_MAX_LENGTH).trim());
            setWorksiteOverridesAddress(response?.address ?? '');
            setWorksiteOverridesCity(response?.place ?? '');
            setWorksiteOverridesState(response?.region ?? '');
            setWorksiteOverridesPostalCode(response?.postcode ?? '');
            setWorksiteOverridesCoordinates(response?.coordinates ?? null);
          }
        }}
        disabled={disabled}
      />
    ),
    [
      disabled,
      setWorksiteOverridesAddress,
      setWorksiteOverridesCity,
      setWorksiteOverridesCoordinates,
      setWorksiteOverridesName,
      setWorksiteOverridesPostalCode,
      setWorksiteOverridesState,
      worksiteOverridesName,
    ],
  );

  return { worksiteOverridesName, setWorksiteOverridesName, renderWorksiteOverridesName, ...rest };
}

function WorksiteNameInput({
  value,
  onChange: handleChange,
  disabled,
}: {
  value: FieldWorksiteName;
  onChange: (value: string | SearchBoxSuggestion, retrieveResponse: MapboxSearchInputResponse | null) => void;
  disabled: boolean;
}) {
  const { t } = useTranslation('worksite');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldWorksiteOverridesNameKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['reserve', 'transfer'],
  };

  return (
    <MapboxSearchInput
      value={value}
      onChange={handleChange}
      textFieldProps={(params) => ({
        ...params,
        onBlur: (e) => handleChange(e.target.value.trim(), null),
        error: hasErrors,
        label: <RequiredForInputLabel label={t('field.name')} requiredFor={requiredFor} disabled={disabled} />,
        inputProps: {
          ...params.inputProps,
          maxLength: WORKSITE_NAME_MAX_LENGTH,
        },
      })}
      disabled={disabled}
      getOptionLabel={(o) => (typeof o === 'string' ? o : o.name)}
    />
  );
}

type FieldWorksiteOverridesAddress = string;
const fieldWorksiteOverridesAddressKey = createFieldKey<FieldWorksiteOverridesAddress>();
export function useFieldWorksiteOverridesAddressRead($key: ProjectBaseFields_WorksiteOverridesAddressReadFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteOverridesAddressReadFragment on Location {
        address
        structuredAddress {
          value {
            streetAddress
          }
        }
      }
    `,
    $key,
  );

  const worksiteOverridesAddress = useFieldValue(
    jobStageBaseFormContext,
    fieldWorksiteOverridesAddressKey,
    () => $data?.structuredAddress?.value?.streetAddress ?? $data?.address.replaceAll('\n', ' ') ?? '',
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldWorksiteOverridesAddressKey);
  useMapper((v) => ({ projectBase: { assignedWorksiteInfo: { location: { structuredAddress: { streetAddress: v } } } } }), [], 'save');

  return { worksiteOverridesAddress };
}

export function useFieldWorksiteOverridesAddress(
  $key: ProjectBaseFields_WorksiteOverridesAddressFragment$key | null | undefined,
  client$key: ProjectBaseFields_WorksiteOverridesAddress_ClientFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteOverridesAddressFragment on Location {
        ...ProjectBaseFields_WorksiteOverridesAddressReadFragment
        structuredAddress {
          ...ProjectBaseFields_WorksiteAddressInputFragment
          ...ProjectBaseFields_WorksiteOverridesCoordinatesFragment
          ...ProjectBaseFields_WorksiteOverridesCityFragment
          ...ProjectBaseFields_WorksiteOverridesStateFragment
          ...ProjectBaseFields_WorksiteOverridesPostalCodeFragment
        }
      }
    `,
    $key,
  );

  const client$data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteOverridesAddress_ClientFragment on ClientInternalBase {
        ...ProjectBaseFields_WorksiteAddressInput_ClientFragment
      }
    `,
    client$key,
  );

  const { worksiteOverridesAddress, ...rest } = useFieldWorksiteOverridesAddressRead($data);
  const setWorksiteOverridesAddress = useFieldSetter(jobStageBaseFormContext, fieldWorksiteOverridesAddressKey);
  const { setWorksiteOverridesCoordinates } = useFieldWorksiteOverridesCoordinates($data?.structuredAddress, disabled);
  const { setWorksiteOverridesCity } = useFieldWorksiteOverridesCity($data?.structuredAddress, disabled, required);
  const { setWorksiteOverridesState } = useFieldWorksiteOverridesState($data?.structuredAddress, disabled, required);
  const { setWorksiteOverridesPostalCode } = useFieldWorksiteOverridesPostalCode($data?.structuredAddress, disabled, required);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldWorksiteOverridesAddressKey);
  useValidation((v) => !required || !!v?.trim(), [required], 'transferable,submittable:required');

  const renderWorksiteOverridesAddress = () => (
    <WorksiteAddressInput
      $key={$data?.structuredAddress}
      client$key={client$data}
      value={worksiteOverridesAddress}
      onChange={(v, response) => {
        if (typeof v === 'string') {
          setWorksiteOverridesAddress(v);
        } else {
          setWorksiteOverridesAddress(response?.address ?? '');
          setWorksiteOverridesCity(response?.place ?? '');
          setWorksiteOverridesState(response?.region ?? '');
          setWorksiteOverridesPostalCode(response?.postcode ?? '');
          setWorksiteOverridesCoordinates(response?.coordinates ?? null);
        }
      }}
      disabled={disabled}
      required={required}
    />
  );

  return { worksiteOverridesAddress, setWorksiteOverridesAddress, renderWorksiteOverridesAddress, ...rest };
}

function WorksiteAddressInput({
  $key,
  client$key,
  value,
  onChange: handleChange,
  disabled,
  required,
}: {
  $key: ProjectBaseFields_WorksiteAddressInputFragment$key | null | undefined;
  client$key: ProjectBaseFields_WorksiteAddressInput_ClientFragment$key | null | undefined;
  value: FieldWorksiteOverridesAddress;
  onChange: (value: string | SearchBoxSuggestion, retrieveResponse: MapboxSearchInputResponse | null) => void;
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useTranslation('worksite');

  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteAddressInputFragment on StructuredAddressResult {
        ...ProjectBaseFields_QueriedCopyClientAddressButtonFragment
        ...ProjectBaseFields_CopyClientAddressButtonFragment
      }
    `,
    $key,
  );

  const client$data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteAddressInput_ClientFragment on ClientInternalBase {
        ...ClientBaseFields_useFieldAssignedClientFragment
        assignedClientInfo {
          ...ProjectBaseFields_CopyClientAddressButton_ClientFragment
          ...ClientBaseFields_ClientOverrides_IsDirectSalesFragment
        }
        assignedClient {
          ...ProjectBaseFields_CopyClientAddressButton_ClientFragment
        }
      }
    `,
    client$key,
  );

  const { assignedClient, assignedClientIsDirty } = useFieldAssignedClientRead(client$data);
  const { clientOverridesIsDirectSales } = useFieldClientOverridesIsDirectSalesRead(client$data?.assignedClientInfo);

  const copyClientAddressButton = assignedClient ? (
    clientOverridesIsDirectSales ? (
      <CopyClientAddressButton
        $key={$data}
        client$key={client$data?.assignedClientInfo}
        onCopyClick={(v) => handleChange(v, null)}
        disabled={disabled}
        required={required}
      />
    ) : assignedClientIsDirty ? (
      <FallbackErrorBoundary>
        <Suspense fallback={<CircularProgress size={20} />}>
          <ProjectBaseFields_QueriedCopyClientAddressButton
            $key={$data}
            clientId={assignedClient.id}
            onCopyClick={(v) => handleChange(v, null)}
            disabled={disabled}
            required={required}
          />
        </Suspense>
      </FallbackErrorBoundary>
    ) : (
      <CopyClientAddressButton
        $key={$data}
        client$key={client$data?.assignedClient}
        onCopyClick={(v) => handleChange(v, null)}
        disabled={disabled}
        required={required}
      />
    )
  ) : null;

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['reserve', 'transfer'],
  };

  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldWorksiteOverridesAddressKey);

  return (
    <MapboxSearchInput
      value={value}
      onChange={handleChange}
      textFieldProps={(params) => ({
        ...params,
        InputProps: {
          ...params.InputProps,
          endAdornment: copyClientAddressButton,
        },
        onBlur: (e) => handleChange(e.target.value.trim(), null),
        error: hasErrors,
        label: <RequiredForInputLabel label={t('field.address')} requiredFor={requiredFor} disabled={disabled} />,
      })}
      disabled={disabled}
    />
  );
}

function ProjectBaseFields_QueriedCopyClientAddressButton({
  $key,
  clientId,
  onCopyClick: handleCopyClick,
  disabled,
  required,
}: {
  $key: ProjectBaseFields_QueriedCopyClientAddressButtonFragment$key | null | undefined;
  clientId: DataID;
  onCopyClick: (value: string) => void;
  disabled: boolean;
  required: boolean;
}) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_QueriedCopyClientAddressButtonFragment on StructuredAddressResult {
        ...ProjectBaseFields_CopyClientAddressButtonFragment
      }
    `,
    $key,
  );

  const client$data = useLazyLoadQuery<ProjectBaseFields_QueriedCopyClientAddressButtonQuery>(
    graphql`
      query ProjectBaseFields_QueriedCopyClientAddressButtonQuery($id: ID!) {
        assignedClient: node(id: $id) @required(action: THROW) {
          ... on Client {
            ...ProjectBaseFields_CopyClientAddressButton_ClientFragment
          }
        }
      }
    `,
    { id: clientId },
  );

  return (
    <CopyClientAddressButton
      $key={$data}
      client$key={client$data.assignedClient}
      onCopyClick={handleCopyClick}
      disabled={disabled}
      required={required}
    />
  );
}

function CopyClientAddressButton({
  $key,
  client$key,
  onCopyClick,
  disabled,
  required,
}: {
  $key: ProjectBaseFields_CopyClientAddressButtonFragment$key | null | undefined;
  client$key: ProjectBaseFields_CopyClientAddressButton_ClientFragment$key | null | undefined;
  onCopyClick: (value: string) => void;
  disabled: boolean;
  required: boolean;
}) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_CopyClientAddressButtonFragment on StructuredAddressResult {
        ...ProjectBaseFields_WorksiteOverridesCoordinatesFragment
        ...ProjectBaseFields_WorksiteOverridesPostalCodeFragment
        ...ProjectBaseFields_WorksiteOverridesApartmentFragment
        ...ProjectBaseFields_WorksiteOverridesCityFragment
        ...ProjectBaseFields_WorksiteOverridesStateFragment
      }
    `,
    $key,
  );

  const client$data = useFragment(
    graphql`
      fragment ProjectBaseFields_CopyClientAddressButton_ClientFragment on IOverridableClient {
        ...ClientBaseFields_ClientOverrides_IsDirectSalesFragment
        location {
          ...ClientBaseFields_ClientOverridesAddressReadFragment
          structuredAddress {
            value {
              streetAddress
              coordinates {
                latitude
                longitude
              }
              apartment
              city
              state
              postalCode
            }
            ...ClientBaseFields_ClientOverridesCoordinatesFragment
            ...ClientBaseFields_ClientOverridesPostalCodeFragment
            ...ClientBaseFields_ClientOverridesApartmentFragment
            ...ClientBaseFields_ClientOverridesCityFragment
            ...ClientBaseFields_ClientOverridesStateFragment
          }
        }
      }
    `,
    client$key,
  );

  const { clientOverridesIsDirectSales } = useFieldClientOverridesIsDirectSalesRead(client$data);
  const { clientOverridesAddress } = useFieldClientOverridesAddressRead(client$data?.location);
  const { clientOverridesCoordinates } = useFieldClientOverridesCoordinatesRead(client$data?.location?.structuredAddress);
  const { clientOverridesPostalCode } = useFieldClientOverridesPostalCodeRead(client$data?.location?.structuredAddress);
  const { clientOverridesApartment } = useFieldClientOverridesApartmentRead(client$data?.location?.structuredAddress);
  const { clientOverridesCity } = useFieldClientOverridesCityRead(client$data?.location?.structuredAddress);
  const { clientOverridesState } = useFieldClientOverridesStateRead(client$data?.location?.structuredAddress);

  const { setWorksiteOverridesCoordinates } = useFieldWorksiteOverridesCoordinates($data, disabled);
  const { setWorksiteOverridesPostalCode } = useFieldWorksiteOverridesPostalCode($data, disabled, required);
  const { setWorksiteOverridesApartment } = useFieldWorksiteOverridesApartment($data, disabled);
  const { setWorksiteOverridesCity } = useFieldWorksiteOverridesCity($data, disabled, required);
  const { setWorksiteOverridesState } = useFieldWorksiteOverridesState($data, disabled, required);

  return (clientOverridesIsDirectSales && !clientOverridesAddress) || disabled ? null : (
    <IconButton
      onClick={() => {
        onCopyClick(
          clientOverridesIsDirectSales ? clientOverridesAddress : (client$data?.location?.structuredAddress?.value?.streetAddress ?? ''),
        );
        setWorksiteOverridesCoordinates(
          clientOverridesIsDirectSales
            ? clientOverridesCoordinates
            : client$data?.location?.structuredAddress?.value?.coordinates
              ? {
                  latitude: client$data.location.structuredAddress.value.coordinates.latitude,
                  longitude: client$data.location.structuredAddress.value.coordinates.longitude,
                }
              : null,
        );
        setWorksiteOverridesApartment(
          clientOverridesIsDirectSales ? clientOverridesApartment : (client$data?.location?.structuredAddress?.value?.apartment ?? ''),
        );
        setWorksiteOverridesPostalCode(
          clientOverridesIsDirectSales ? clientOverridesPostalCode : (client$data?.location?.structuredAddress?.value?.postalCode ?? ''),
        );
        setWorksiteOverridesCity(
          clientOverridesIsDirectSales ? clientOverridesCity : (client$data?.location?.structuredAddress?.value?.city ?? ''),
        );
        setWorksiteOverridesState(
          clientOverridesIsDirectSales ? clientOverridesState : (client$data?.location?.structuredAddress?.value?.state ?? ''),
        );
      }}>
      <ContentPasteIcon />
    </IconButton>
  );
}

type FieldWorksiteOverridesCoordinates = Coordinates | null;
const fieldWorksiteOverridesCoordinatesKey = createFieldKey<FieldWorksiteOverridesCoordinates>();
export function useFieldWorksiteOverridesCoordinatesRead(
  $key: ProjectBaseFields_WorksiteOverridesCoordinatesFragment$key | null | undefined,
) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteOverridesCoordinatesFragment on StructuredAddressResult {
        value {
          coordinates {
            latitude
            longitude
          }
        }
      }
    `,
    $key,
  );

  const worksiteOverridesCoordinates = useFieldValue(jobStageBaseFormContext, fieldWorksiteOverridesCoordinatesKey, () =>
    $data?.value?.coordinates
      ? {
          latitude: $data.value.coordinates.latitude,
          longitude: $data.value.coordinates.longitude,
        }
      : null,
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldWorksiteOverridesCoordinatesKey);
  useMapper(
    (v) => ({
      projectBase: {
        assignedWorksiteInfo: {
          location: {
            structuredAddress: {
              coordinates: v
                ? {
                    latitude: v.latitude,
                    longitude: v.longitude,
                  }
                : null,
            },
          },
        },
      },
    }),
    [],
    'save',
  );

  return { worksiteOverridesCoordinates };
}

export function useFieldWorksiteOverridesCoordinates(
  $key: ProjectBaseFields_WorksiteOverridesCoordinatesFragment$key | null | undefined,
  disabled: boolean,
) {
  const { worksiteOverridesCoordinates, ...rest } = useFieldWorksiteOverridesCoordinatesRead($key);
  const setWorksiteOverridesCoordinates = useFieldSetter(jobStageBaseFormContext, fieldWorksiteOverridesCoordinatesKey);

  const renderWorksiteMinimap = () => (
    <WorksiteMap coordinates={worksiteOverridesCoordinates} setCoordinates={setWorksiteOverridesCoordinates} disabled={disabled} />
  );

  return { worksiteOverridesCoordinates, setWorksiteOverridesCoordinates, renderWorksiteMinimap, ...rest };
}

function WorksiteMap({
  coordinates,
  setCoordinates,
  disabled,
}: {
  coordinates: FieldWorksiteOverridesCoordinates;
  setCoordinates: SetValueFn<FieldWorksiteOverridesCoordinates>;
  disabled: boolean;
}) {
  const { t } = useTranslation('common');
  const theme = useTheme();

  const fallbackSx = {
    borderRadius: '0.25rem',
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <FallbackErrorBoundary
      fallback={
        <Box sx={fallbackSx}>
          <Typography>{t('errorMessages.mapboxError')}</Typography>
        </Box>
      }>
      <MapboxMap coordinates={coordinates} onCoordinatesChange={(coords) => setCoordinates(coords)} disabled={disabled} />
    </FallbackErrorBoundary>
  );
}

type FieldWorksiteOverridesPostalCode = string;
const fieldWorksiteOverridesPostalCodeKey = createFieldKey<FieldWorksiteOverridesPostalCode>();
export function useFieldWorksiteOverridesPostalCodeRead(
  $key: ProjectBaseFields_WorksiteOverridesPostalCodeFragment$key | null | undefined,
) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteOverridesPostalCodeFragment on StructuredAddressResult {
        value {
          postalCode
        }
      }
    `,
    $key,
  );

  const worksiteOverridesPostalCode = useFieldValue(
    jobStageBaseFormContext,
    fieldWorksiteOverridesPostalCodeKey,
    () => $data?.value?.postalCode ?? '',
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldWorksiteOverridesPostalCodeKey);
  useMapper((v) => ({ projectBase: { assignedWorksiteInfo: { location: { structuredAddress: { postalCode: v } } } } }), [], 'save');

  return { worksiteOverridesPostalCode };
}

export function useFieldWorksiteOverridesPostalCode(
  $key: ProjectBaseFields_WorksiteOverridesPostalCodeFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { worksiteOverridesPostalCode, ...rest } = useFieldWorksiteOverridesPostalCodeRead($key);
  const setWorksiteOverridesPostalCode = useFieldSetter(jobStageBaseFormContext, fieldWorksiteOverridesPostalCodeKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldWorksiteOverridesPostalCodeKey);
  useValidation((v) => !required || !!v?.trim(), [required], 'transferable,submittable:required');

  const renderWorksiteOverridesPostalCode = () => (
    <WorksitePostalCodeInput value={worksiteOverridesPostalCode} setValue={setWorksiteOverridesPostalCode} disabled={disabled} />
  );

  return { worksiteOverridesPostalCode, setWorksiteOverridesPostalCode, renderWorksiteOverridesPostalCode, ...rest };
}

function WorksitePostalCodeInput({
  value,
  setValue,
  disabled,
}: {
  value: FieldWorksiteOverridesPostalCode;
  setValue: SetValueFn<FieldWorksiteOverridesPostalCode>;
  disabled: boolean;
}) {
  const { t } = useTranslation('worksite');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldWorksiteOverridesPostalCodeKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['reserve', 'transfer'],
  };

  return (
    <TextField
      label={<RequiredForInputLabel label={t('field.postalCode')} requiredFor={requiredFor} disabled={disabled} />}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => setValue(e.target.value.trim())}
      disabled={disabled}
      error={hasErrors}
      data-label-key='worksite.field.postalCode'
      inputProps={{ maxLength: 7 }}
      InputProps={{
        inputProps: {
          maxLength: 7,
        },
      }}
    />
  );
}

type FieldWorksiteOverridesApartment = string;
const fieldWorksiteOverridesApartmentKey = createFieldKey<FieldWorksiteOverridesApartment>();
export function useFieldWorksiteOverridesApartmentRead($key: ProjectBaseFields_WorksiteOverridesApartmentFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteOverridesApartmentFragment on StructuredAddressResult {
        value {
          apartment
        }
      }
    `,
    $key,
  );

  const worksiteOverridesApartment = useFieldValue(
    jobStageBaseFormContext,
    fieldWorksiteOverridesApartmentKey,
    () => $data?.value?.apartment ?? '',
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldWorksiteOverridesApartmentKey);
  useMapper((v) => ({ projectBase: { assignedWorksiteInfo: { location: { structuredAddress: { apartment: v } } } } }), [], 'save');

  return { worksiteOverridesApartment };
}

export function useFieldWorksiteOverridesApartment(
  $key: ProjectBaseFields_WorksiteOverridesApartmentFragment$key | null | undefined,
  disabled: boolean,
) {
  const { worksiteOverridesApartment, ...rest } = useFieldWorksiteOverridesApartmentRead($key);
  const setWorksiteOverridesApartment = useFieldSetter(jobStageBaseFormContext, fieldWorksiteOverridesApartmentKey);

  const renderWorksiteOverridesApartment = () => (
    <WorksiteApartmentInput value={worksiteOverridesApartment} setValue={setWorksiteOverridesApartment} disabled={disabled} />
  );

  return { worksiteOverridesApartment, setWorksiteOverridesApartment, renderWorksiteOverridesApartment, ...rest };
}

function WorksiteApartmentInput({
  value,
  setValue,
  disabled,
}: {
  value: FieldWorksiteOverridesPostalCode;
  setValue: SetValueFn<FieldWorksiteOverridesPostalCode>;
  disabled: boolean;
}) {
  const { t } = useTranslation('worksite');

  return (
    <TextField
      label={t('field.apartment')}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => setValue(e.target.value.trim())}
      disabled={disabled}
      data-label-key='worksite.field.apartment'
    />
  );
}

type FieldWorksiteOverridesCity = string;
const fieldWorksiteOverridesCityKey = createFieldKey<FieldWorksiteOverridesCity>();
export function useFieldWorksiteOverridesCityRead($key: ProjectBaseFields_WorksiteOverridesCityFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteOverridesCityFragment on StructuredAddressResult {
        value {
          city
        }
      }
    `,
    $key,
  );

  const worksiteOverridesCity = useFieldValue(jobStageBaseFormContext, fieldWorksiteOverridesCityKey, () => $data?.value?.city ?? '');
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldWorksiteOverridesCityKey);
  useMapper((v) => ({ projectBase: { assignedWorksiteInfo: { location: { structuredAddress: { city: v } } } } }), [], 'save');

  return { worksiteOverridesCity };
}

export function useFieldWorksiteOverridesCity(
  $key: ProjectBaseFields_WorksiteOverridesCityFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { worksiteOverridesCity, ...rest } = useFieldWorksiteOverridesCityRead($key);
  const setWorksiteOverridesCity = useFieldSetter(jobStageBaseFormContext, fieldWorksiteOverridesCityKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldWorksiteOverridesCityKey);
  useValidation((v) => !required || !!v?.trim(), [required], 'transferable,submittable:required');

  const renderWorksiteOverridesCity = () => (
    <WorksiteCityInput value={worksiteOverridesCity} setValue={setWorksiteOverridesCity} disabled={disabled} />
  );

  return { worksiteOverridesCity, setWorksiteOverridesCity, renderWorksiteOverridesCity, ...rest };
}

function WorksiteCityInput({
  value,
  setValue,
  disabled,
}: {
  value: FieldWorksiteOverridesCity;
  setValue: SetValueFn<FieldWorksiteOverridesCity>;
  disabled: boolean;
}) {
  const { t } = useTranslation('worksite');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldWorksiteOverridesCityKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['reserve', 'transfer'],
  };

  return (
    <TextField
      label={<RequiredForInputLabel label={t('field.city')} requiredFor={requiredFor} disabled={disabled} />}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => setValue(e.target.value.trim())}
      disabled={disabled}
      error={hasErrors}
      data-label-key='worksite.field.city'
    />
  );
}

type FieldWorksiteOverridesState = string;
const fieldWorksiteOverridesStateKey = createFieldKey<FieldWorksiteOverridesState>();
export function useFieldWorksiteOverridesStateRead($key: ProjectBaseFields_WorksiteOverridesStateFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteOverridesStateFragment on StructuredAddressResult {
        value {
          state
        }
      }
    `,
    $key,
  );

  const worksiteOverridesState = useFieldValue(jobStageBaseFormContext, fieldWorksiteOverridesStateKey, () => $data?.value?.state ?? '');
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldWorksiteOverridesStateKey);
  useMapper((v) => ({ projectBase: { assignedWorksiteInfo: { location: { structuredAddress: { state: v } } } } }), [], 'save');

  return { worksiteOverridesState };
}

export function useFieldWorksiteOverridesState(
  $key: ProjectBaseFields_WorksiteOverridesStateFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { worksiteOverridesState, ...rest } = useFieldWorksiteOverridesStateRead($key);
  const setWorksiteOverridesState = useFieldSetter(jobStageBaseFormContext, fieldWorksiteOverridesStateKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldWorksiteOverridesStateKey);
  useValidation((v) => !required || !!v?.trim(), [required], 'transferable,submittable:required');

  const renderWorksiteOverridesState = () => (
    <WorksiteStateInput value={worksiteOverridesState} setValue={setWorksiteOverridesState} disabled={disabled} />
  );

  return { worksiteOverridesState, setWorksiteOverridesState, renderWorksiteOverridesState, ...rest };
}

function WorksiteStateInput({
  value,
  setValue,
  disabled,
}: {
  value: FieldWorksiteOverridesState;
  setValue: SetValueFn<FieldWorksiteOverridesState>;
  disabled: boolean;
}) {
  const { t } = useTranslation('worksite');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldWorksiteOverridesStateKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['reserve', 'transfer'],
  };

  return (
    <TextField
      label={<RequiredForInputLabel label={t('field.province')} requiredFor={requiredFor} disabled={disabled} />}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => setValue(e.target.value.trim())}
      disabled={disabled}
      error={hasErrors}
      data-label-key='worksite.field.province'
    />
  );
}

export function useHasSatisfiedRequiredWorksiteAddressFields(
  $key: ProjectBaseFields_useHasSatisfiedRequiredWorksiteAddressFieldsFragment$key | null | undefined,
) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_useHasSatisfiedRequiredWorksiteAddressFieldsFragment on Location {
        ...ProjectBaseFields_WorksiteOverridesAddressReadFragment

        structuredAddress {
          ...ProjectBaseFields_WorksiteOverridesCityFragment
          ...ProjectBaseFields_WorksiteOverridesStateFragment
          ...ProjectBaseFields_WorksiteOverridesPostalCodeFragment
        }
      }
    `,
    $key,
  );

  const { worksiteOverridesAddress } = useFieldWorksiteOverridesAddressRead($data);
  const { worksiteOverridesCity } = useFieldWorksiteOverridesCityRead($data?.structuredAddress);
  const { worksiteOverridesState } = useFieldWorksiteOverridesStateRead($data?.structuredAddress);
  const { worksiteOverridesPostalCode } = useFieldWorksiteOverridesPostalCodeRead($data?.structuredAddress);

  return !!worksiteOverridesAddress && !!worksiteOverridesCity && !!worksiteOverridesState && !!worksiteOverridesPostalCode;
}

type FieldWorksiteOverridesRequirements = NonNullable<ForwardWorksiteRequirementAutocompleteProps<true>['value']>;
const fieldWorksiteOverridesRequirementsKey = createFieldKey<FieldWorksiteOverridesRequirements>();
function equalsById(left: { id: DataID }, right: { id: DataID }) {
  return left.id === right.id;
}
const fieldWorksiteOverridesRequirementsFragment = graphql`
  fragment ProjectBaseFields_RequirementsFragment on Requirement @relay(plural: true) {
    id
    label
  }
`;
export function useFieldWorksiteOverridesRequirementsRead(
  $key: ProjectBaseFields_RequirementsFragment$key | null | undefined,
  static$key: ProjectBaseFields_RequirementsFragment$key | null | undefined,
) {
  const $data = useFragment(fieldWorksiteOverridesRequirementsFragment, $key);
  const static$data = useFragment(fieldWorksiteOverridesRequirementsFragment, static$key);
  const worksiteOverridesRequirements = useFieldValue(jobStageBaseFormContext, fieldWorksiteOverridesRequirementsKey, () =>
    exclude($data ?? [], static$data ?? [], equalsById),
  );

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldWorksiteOverridesRequirementsKey);
  useMapper((v) => ({ projectBase: { assignedWorksiteInfo: { requirements: { ids: v.map((r) => r.id) } } } }), [], 'save');

  return { worksiteOverridesRequirements };
}
export function useFieldWorksiteOverridesRequirements(
  $key: ProjectBaseFields_RequirementsFragment$key | null | undefined,
  static$key: ProjectBaseFields_RequirementsFragment$key | null | undefined,
  disabled: boolean,
) {
  const { worksiteOverridesRequirements, ...rest } = useFieldWorksiteOverridesRequirementsRead($key, static$key);
  const static$data = useFragment(fieldWorksiteOverridesRequirementsFragment, static$key);

  const setWorksiteOverridesRequirementsInternal = useFieldSetter(jobStageBaseFormContext, fieldWorksiteOverridesRequirementsKey);

  const setWorksiteOverridesRequirements = useCallback<typeof setWorksiteOverridesRequirementsInternal>(
    (newValue) => {
      if (typeof newValue === 'function') {
        setWorksiteOverridesRequirementsInternal((old) => exclude(newValue(old) ?? [], static$data ?? [], equalsById));
      } else {
        setWorksiteOverridesRequirementsInternal(exclude(newValue ?? [], static$data ?? [], equalsById));
      }
    },
    [setWorksiteOverridesRequirementsInternal, static$data],
  );

  const displayedRequirements = useMemo(
    () => [...(static$data ?? []), ...exclude(worksiteOverridesRequirements ?? [], static$data ?? [], equalsById)],
    [worksiteOverridesRequirements, static$data],
  );

  const renderWorksiteOverridesRequirements = useCallback(
    (
      input$key: ProjectBaseFields_WorksiteRequirementsSuggestionsFragment$key | null | undefined,
      saleKind: ServiceCallKind | QuoteKind | null,
    ) => (
      <WorksiteRequirementsSuggested
        $key={input$key}
        value={displayedRequirements}
        setValue={setWorksiteOverridesRequirements}
        saleKind={saleKind}
        disabled={disabled}
        staticRequirements={static$data ?? []}
      />
    ),
    [disabled, displayedRequirements, setWorksiteOverridesRequirements, static$data],
  );

  const renderWorksiteOverridesRequirementsNoSuggestions = useCallback(
    () => (
      <WorksiteRequirementsInput
        value={displayedRequirements}
        setValue={setWorksiteOverridesRequirements}
        disabled={disabled}
        staticRequirements={static$data ?? []}
        suggestionPromptInput={null}
      />
    ),
    [disabled, displayedRequirements, setWorksiteOverridesRequirements, static$data],
  );

  return {
    worksiteOverridesRequirements,
    setWorksiteOverridesRequirements,
    renderWorksiteOverridesRequirements,
    renderWorksiteOverridesRequirementsNoSuggestions,
    ...rest,
  };
}

function WorksiteRequirementsSuggested({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
  staticRequirements,
}: {
  $key: ProjectBaseFields_WorksiteRequirementsSuggestionsFragment$key | null | undefined;
  value: FieldWorksiteOverridesRequirements;
  setValue: SetValueFn<FieldWorksiteOverridesRequirements>;
  saleKind: ServiceCallKind | QuoteKind | null;
  disabled: boolean;
  staticRequirements: NonNullable<FieldWorksiteOverridesRequirements>;
}) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteRequirementsSuggestionsFragment on ISale
      @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const suggestionPromptInput = useSuggestions($data, saleKind);

  return (
    <WorksiteRequirementsInput
      value={value}
      setValue={setValue}
      disabled={disabled}
      staticRequirements={staticRequirements}
      suggestionPromptInput={suggestionPromptInput}
    />
  );
}

function WorksiteRequirementsInput({
  value,
  setValue,
  disabled,
  staticRequirements,
  suggestionPromptInput,
}: {
  value: FieldWorksiteOverridesRequirements;
  setValue: SetValueFn<FieldWorksiteOverridesRequirements>;
  disabled: boolean;
  staticRequirements: NonNullable<FieldWorksiteOverridesRequirements>;
  suggestionPromptInput: SuggestionPromptInput | null;
}) {
  const { t } = useTranslation('worksite');
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <WorksiteRequirementAutocomplete
        multiple
        value={value}
        onChange={(values) => {
          // we need to strip static requirements from results since they are not really part of the value.
          setValue(values.filter((v) => !staticRequirements.some((sr) => sr.id === v.id)));
        }}
        suggestible={!!suggestionPromptInput}
        suggestionPromptInput={suggestionPromptInput}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const isDefaultRequirement = staticRequirements.some((r) => r.id === option.id);
            const handleDelete = isDefaultRequirement ? undefined : getTagProps({ index }).onDelete;
            return <Chip key={option.id} onDelete={handleDelete} label={option.label} size='small' disabled={disabled} />;
          })
        }
        getOptionDisabled={(option) => staticRequirements.some((r) => r.id === option.id)}
        textFieldProps={(params) => ({
          label: t('field.requirements', { ns: 'common' }),
          InputProps: {
            ...params.InputProps,
            startAdornment: (
              <>
                {value && value.length > 0 && (
                  <IconButton onClick={() => setDialogOpen(true)} sx={{ position: 'absolute', left: 0, top: 'calc(50% - 1.25rem)' }}>
                    <InfoIcon sx={(theme) => ({ color: theme.palette.grey.A400 })} />
                  </IconButton>
                )}
                {params.InputProps.startAdornment}
              </>
            ),
          },
        })}
        sx={value && value.length > 0 ? { '.MuiAutocomplete-inputRoot.MuiInputBase-adornedStart': { pl: '2.5rem' } } : {}}
        disabled={disabled}
      />
      <RequirementsDialog
        requirementIds={value?.map((r) => r.id) ?? []}
        onClose={() => setDialogOpen(false)}
        isOpen={dialogOpen}
        title={t('dialog.requirementsTitle', { ns: 'worksite' })}
      />
    </>
  );
}

const fieldWorksiteOverridesContactKey = createFieldKey<string>();
export function useFieldWorksiteOverridesContact(
  $key: ProjectBaseFields_WorksiteOverrides_ContactFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteOverrides_ContactFragment on IOverridableWorksite {
        contact
      }
    `,
    $key,
  );

  const [worksiteOverridesContact, setWorksiteOverridesContact] = useField(
    jobStageBaseFormContext,
    fieldWorksiteOverridesContactKey,
    () => $data?.contact ?? '',
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldWorksiteOverridesContactKey);
  useMapper((v) => ({ projectBase: { assignedWorksiteInfo: { contact: v || null } } }), [], 'save');

  const renderWorksiteOverridesContact = useCallback(
    () => <WorksiteContactInput value={worksiteOverridesContact} setValue={setWorksiteOverridesContact} disabled={disabled} />,
    [disabled, setWorksiteOverridesContact, worksiteOverridesContact],
  );

  return { worksiteOverridesContact, setWorksiteOverridesContact, renderWorksiteOverridesContact };
}

function WorksiteContactInput({ value, setValue, disabled }: { value: string; setValue: SetValueFn<string>; disabled: boolean }) {
  const { t } = useTranslation('worksite');

  return (
    <TextField
      label={t('field.contact')}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => setValue(e.target.value.trim())}
      disabled={disabled}
    />
  );
}

const worksiteOverridesPhoneNumberFieldKey = createFieldKey<string>();
export function useFieldWorksiteOverridesPhoneNumber(
  $key: ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment on IOverridableWorksite {
        phoneNumber
      }
    `,
    $key,
  );

  const [worksiteOverridesPhoneNumber, setWorksiteOverridesPhoneNumber] = useField(
    jobStageBaseFormContext,
    worksiteOverridesPhoneNumberFieldKey,
    () => $data?.phoneNumber ?? '',
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, worksiteOverridesPhoneNumberFieldKey);
  useMapper((v) => ({ projectBase: { assignedWorksiteInfo: { phoneNumber: v || null } } }), [], 'save');

  const renderWorksiteOverridesPhoneNumber = useCallback(
    () => <WorksitePhoneNumberInput value={worksiteOverridesPhoneNumber} setValue={setWorksiteOverridesPhoneNumber} disabled={disabled} />,
    [worksiteOverridesPhoneNumber, setWorksiteOverridesPhoneNumber, disabled],
  );

  return { worksiteOverridesPhoneNumber, setWorksiteOverridesPhoneNumber, renderWorksiteOverridesPhoneNumber };
}

function WorksitePhoneNumberInput({ value, setValue, disabled }: { value: string; setValue: SetValueFn<string>; disabled: boolean }) {
  const { t } = useTranslation('worksite');

  return (
    <TextField
      label={t('field.phoneNumber')}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      disabled={disabled}
      InputProps={{
        inputComponent: MaskInput,
        inputProps: {
          mask: '000-000-0000',
        },
      }}
    />
  );
}

export type FieldArrivalDate = DateTime<boolean> | null;
const fieldArrivalDateKey = createFieldKey<FieldArrivalDate>();
export function useFieldArrivalDateRead($key: ProjectBaseFields_ArrivalDateReadFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_ArrivalDateReadFragment on ProjectInternalBase @argumentDefinitions(isCopy: { type: "Boolean!" }) {
        arrivalDate @skip(if: $isCopy) {
          rawValue
        }
      }
    `,
    $key,
  );
  const arrivalDate = useFieldValue(jobStageBaseFormContext, fieldArrivalDateKey, () => parseDateTime($data?.arrivalDate?.rawValue));
  const arrivalDateIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldArrivalDateKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldArrivalDateKey);
  useMapper((val) => ({ projectBase: { arrivalDate: val?.toJSON() ?? null } }), [], 'save');
  useMapper(
    (val) => ({ salesRatesInput: { arrivalDate: val?.toJSON() ?? _throw('Arrival date is required to recalculate') } }),
    [],
    'recalculate',
  );

  return { arrivalDate, arrivalDateIsDirty };
}

export function useFieldArrivalDate(
  $key: ProjectBaseFields_ArrivalDateFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_ArrivalDateFragment on IJobStage @argumentDefinitions(isCopy: { type: "Boolean!" }) {
        ... on ServiceCall {
          transferredAt @skip(if: $isCopy)
        }
        projectBase {
          ...ProjectBaseFields_ArrivalDateReadFragment @arguments(isCopy: $isCopy)
        }
      }
    `,
    $key,
  );
  const isServiceCallTransferred = $data?.transferredAt != null;
  const { arrivalDate, ...rest } = useFieldArrivalDateRead($data?.projectBase);
  const setArrivalDate = useFieldSetter(jobStageBaseFormContext, fieldArrivalDateKey);

  const useValidationArrivalDate = useFieldValidation(jobStageBaseFormContext, fieldArrivalDateKey);
  useValidationArrivalDate((val) => (required ? !!val : true), [required], 'transferable,submittable:required');

  const editTimeEnabled = useFlag('app_enable_edit_time_when_transferred');

  const renderArrivalDate = useCallback(
    () =>
      isServiceCallTransferred && editTimeEnabled ? (
        <ArrivalDateTimeInput
          value={arrivalDate}
          setValue={setArrivalDate}
          disableDate
          disableTime={arrivalDate == null}
          required={required}
        />
      ) : (
        <ArrivalDateInput value={arrivalDate} setValue={setArrivalDate} disabled={disabled} />
      ),
    [arrivalDate, disabled, editTimeEnabled, isServiceCallTransferred, required, setArrivalDate],
  );

  return { arrivalDate, setArrivalDate, renderArrivalDate, useValidationArrivalDate, ...rest };
}

function ArrivalDateInput({
  value,
  setValue,
  disabled,
}: {
  value: FieldArrivalDate;
  setValue: SetValueFn<FieldArrivalDate>;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldArrivalDateKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(jobStageBaseFormContext, fieldArrivalDateKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['reserve', 'transfer'],
  };

  return (
    <RoundedDateTimePicker
      label={<RequiredForInputLabel label={t('field.project.date.arrival')} requiredFor={requiredFor} disabled={disabled} />}
      minutesStep={30}
      timeSteps={{ minutes: 30 }}
      value={value}
      onChange={(v) => setValue(v)}
      disabled={disabled}
      ampm={false}
      format={dateTimeFormat}
      slotProps={{
        textField: {
          inputProps: {
            'data-label-key': 'field.project.date.arrival',
          },
          error: hasErrors,
          helperText: message && t(message, { ...errorParams }),
        },
      }}
    />
  );
}

function ArrivalDateTimeInput({
  value,
  setValue,
  disableDate,
  disableTime,
}: {
  value: FieldArrivalDate;
  setValue: SetValueFn<FieldArrivalDate>;
  disableDate: boolean;
  disableTime: boolean;
  required: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldArrivalDateKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(jobStageBaseFormContext, fieldArrivalDateKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['reserve', 'transfer'],
  };

  return (
    <RoundedDateTimePicker
      split
      value={value}
      onChange={(v) => setValue(v)}
      dateInput={{
        fieldOnly: true,
        label: <RequiredForInputLabel label={t('field.project.date.arrival')} requiredFor={requiredFor} disabled={disableDate} />,
        disabled: disableDate,
        format: dateFormat,
        slotProps: {
          textField: {
            inputProps: {
              'data-label-key': 'field.project.date.arrival',
            },
            error: hasErrors,
            helperText: message && t(message, { ...errorParams }),
          },
        },
      }}
      timeInput={{
        minutesStep: 30,
        timeSteps: { minutes: 30 },
        ampm: false,
        format: timeFormat,
        disabled: disableTime,
        slotProps: {
          textField: {
            error: hasErrors,
          },
        },
      }}
    />
  );
}

const lengthInDaysMinValue = 1;
const lengthInDaysMaxValue = 365;
const hoursPerDayMinValue = 1;
const hoursPerDayMaxValue = 16;

export type FieldDurationLengthInDays = number | null;
const fieldDurationLengthInDaysKey = createFieldKey<FieldDurationLengthInDays>();
export function useFieldDurationLengthInDaysRead($key: ProjectBaseFields_DurationLengthInDaysFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_DurationLengthInDaysFragment on ProjectInternalBase {
        duration {
          lengthInDays
        }
      }
    `,
    $key,
  );
  const durationLengthInDays = useFieldValue(
    jobStageBaseFormContext,
    fieldDurationLengthInDaysKey,
    () => $data?.duration?.lengthInDays ?? null,
  );

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldDurationLengthInDaysKey);
  useMapper((v) => ({ projectBase: { duration: { lengthInDays: v } } }), [], 'save');

  return { durationLengthInDays };
}

export function useFieldDurationLengthInDays(
  $key: ProjectBaseFields_DurationLengthInDaysFragment$key | null | undefined,
  disabled: boolean,
) {
  const { durationLengthInDays, ...rest } = useFieldDurationLengthInDaysRead($key);
  const setDurationLengthInDays = useFieldSetter(jobStageBaseFormContext, fieldDurationLengthInDaysKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldDurationLengthInDaysKey);
  useValidation((v) => !!v && v <= lengthInDaysMaxValue, [], 'transferable,submittable:required');

  const renderDurationLengthInDays = useCallback(
    (
      input$key: ProjectBaseFields_DurationLengthInDaysInput_SuggestionsFragment$key | null | undefined,
      saleKind: ServiceCallKind | QuoteKind | null,
    ) => (
      <DurationLengthInDaysInput
        $key={input$key}
        saleKind={saleKind}
        value={durationLengthInDays}
        setValue={setDurationLengthInDays}
        disabled={disabled}
      />
    ),
    [disabled, durationLengthInDays, setDurationLengthInDays],
  );

  const renderDurationLengthInDaysNoSuggestions = useCallback(
    () => <DurationLengthInDaysInput_NoSuggestions value={durationLengthInDays} setValue={setDurationLengthInDays} disabled={disabled} />,
    [disabled, durationLengthInDays, setDurationLengthInDays],
  );

  return { durationLengthInDays, setDurationLengthInDays, renderDurationLengthInDays, renderDurationLengthInDaysNoSuggestions, ...rest };
}

function DurationLengthInDaysInput_NoSuggestions({
  value,
  setValue,
  disabled,
}: {
  value: FieldDurationLengthInDays;
  setValue: SetValueFn<FieldDurationLengthInDays>;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();

  return (
    <NumberInput
      label={t('field.project.date.duration')}
      min={lengthInDaysMinValue}
      max={lengthInDaysMaxValue}
      step={1}
      disabled={disabled}
      value={value}
      onChange={(v) => setValue(v)}
      InputProps={{
        inputProps: {
          'data-label-key': 'field.project.date.duration',
        },
        endAdornment: (
          <InputAdornment position='end'>
            {t('unit.full.day', {
              count: value ?? 0,
              ns: 'common',
            })}
          </InputAdornment>
        ),
      }}
    />
  );
}

function DurationLengthInDaysInput({
  $key,
  saleKind,
  value,
  setValue,
  disabled,
}: {
  $key: ProjectBaseFields_DurationLengthInDaysInput_SuggestionsFragment$key | null | undefined;
  saleKind: ServiceCallKind | QuoteKind | null;
  value: number | null;
  setValue: SetValueFn<number | null>;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldDurationLengthInDaysKey);

  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_DurationLengthInDaysInput_SuggestionsFragment on IJobStage
      @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const suggestionPromptInput = useSuggestions($data, saleKind);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['reserve', 'transfer'],
  };

  return (
    <NumberInput
      label={<RequiredForInputLabel label={t('field.project.date.duration')} requiredFor={requiredFor} disabled={disabled} />}
      min={lengthInDaysMinValue}
      max={lengthInDaysMaxValue}
      step={1}
      disabled={disabled}
      value={value}
      onChange={(v) => setValue(v)}
      error={hasErrors}
      InputProps={{
        inputProps: {
          'data-label-key': 'field.project.date.duration',
        },
        error: hasErrors,
        endAdornment: (
          <>
            <InputAdornment position='end'>
              {t('unit.full.day', {
                count: value ?? 0,
                ns: 'common',
              })}
            </InputAdornment>
            <InputAdornment position='end'>
              <Suggestions
                disabled={disabled}
                content={(close) => (
                  <DurationLengthInDaysSuggestions
                    suggestionPromptInput={suggestionPromptInput}
                    onChange={(v) => {
                      setValue(v);
                      close();
                    }}
                  />
                )}
              />
            </InputAdornment>
          </>
        ),
      }}
    />
  );
}

function DurationLengthInDaysSuggestions({
  suggestionPromptInput,
  onChange: handleChange,
}: {
  suggestionPromptInput: SuggestionPromptInput | null;
  onChange: (selectedValue: number) => void;
}) {
  const query = useLazyLoadQuery<ProjectBaseFields_DurationLengthInDays_SuggestionsQuery>(
    graphql`
      query ProjectBaseFields_DurationLengthInDays_SuggestionsQuery(
        $scoreThreshold: Float!
        $suggestionPrompt: SuggestionPromptInput!
        $suggestionCount: Int!
      ) {
        suggestedDurationInDays(scoreThreshold: $scoreThreshold, prompt: $suggestionPrompt, count: $suggestionCount) {
          value
          score
        }
      }
    `,
    {
      scoreThreshold: scoreThreshold,
      suggestionPrompt: suggestionPromptInput ?? emptySuggestionPromptInput,
      suggestionCount: suggestionCount,
    },
    { fetchPolicy: 'store-and-network' },
  );

  const data = query.suggestedDurationInDays ?? [];

  return (
    <AutomaticSuggestionList keySelector={(v) => `${v}`} items={data} onChange={(selectedValue: number) => handleChange(selectedValue)} />
  );
}

export type FieldDurationHoursPerDay = number | null;
const fieldDurationHoursPerDayKey = createFieldKey<FieldDurationHoursPerDay>();
export function useFieldDurationHoursPerDayRead($key: ProjectBaseFields_DurationHoursPerDayFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_DurationHoursPerDayFragment on ProjectInternalBase {
        duration {
          hoursPerDay
        }
      }
    `,
    $key,
  );
  const durationHoursPerDay = useFieldValue(
    jobStageBaseFormContext,
    fieldDurationHoursPerDayKey,
    () => $data?.duration?.hoursPerDay ?? null,
  );

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldDurationHoursPerDayKey);
  useMapper((v) => ({ projectBase: { duration: { hoursPerDay: v } } }), [], 'save');

  return { durationHoursPerDay };
}

export function useFieldDurationHoursPerDay($key: ProjectBaseFields_DurationHoursPerDayFragment$key | null | undefined, disabled: boolean) {
  const { durationHoursPerDay, ...rest } = useFieldDurationHoursPerDayRead($key);
  const setDurationHoursPerDay = useFieldSetter(jobStageBaseFormContext, fieldDurationHoursPerDayKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldDurationHoursPerDayKey);
  useValidation((v) => !!v, [], 'transferable:required');

  const renderDurationHoursPerDay = useCallback(
    (
      input$key: ProjectBaseFields_DurationHoursPerDayInput_SuggestionsFragment$key | null | undefined,
      saleKind: ServiceCallKind | QuoteKind | null,
    ) => (
      <DurationHoursPerDayInput
        $key={input$key}
        value={durationHoursPerDay}
        setValue={setDurationHoursPerDay}
        saleKind={saleKind}
        disabled={disabled}
      />
    ),
    [disabled, durationHoursPerDay, setDurationHoursPerDay],
  );

  const renderDurationHoursPerDay_NoSuggestions = useCallback(
    () => <DurationHoursPerDayInput_NoSuggestions value={durationHoursPerDay} setValue={setDurationHoursPerDay} disabled={disabled} />,
    [disabled, durationHoursPerDay, setDurationHoursPerDay],
  );

  return { durationHoursPerDay, setDurationHoursPerDay, renderDurationHoursPerDay, renderDurationHoursPerDay_NoSuggestions, ...rest };
}

function DurationHoursPerDayInput_NoSuggestions({
  value,
  setValue,
  disabled,
}: {
  value: FieldDurationHoursPerDay;
  setValue: SetValueFn<FieldDurationHoursPerDay>;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();

  return (
    <NumberInput
      label={t('field.project.date.durationPerDay')}
      min={hoursPerDayMinValue}
      max={hoursPerDayMaxValue}
      step={0.5}
      disabled={disabled}
      value={value}
      onChange={(v) => setValue(v)}
      InputProps={{
        inputProps: {
          'data-label-key': 'field.project.date.durationPerDay',
        },
        endAdornment: (
          <InputAdornment position='end'>
            {t('unit.full.hour', {
              count: value ?? 0,
              ns: 'common',
            })}
          </InputAdornment>
        ),
      }}
    />
  );
}

function DurationHoursPerDayInput({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
}: {
  $key: ProjectBaseFields_DurationHoursPerDayInput_SuggestionsFragment$key | null | undefined;
  value: number | null;
  setValue: SetValueFn<number | null>;
  saleKind: ServiceCallKind | QuoteKind | null;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldDurationHoursPerDayKey);

  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_DurationHoursPerDayInput_SuggestionsFragment on IJobStage
      @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const suggestionPromptInput = useSuggestions($data, saleKind);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    serviceCall: ['transfer'],
  };

  return (
    <NumberInput
      label={<RequiredForInputLabel label={t('field.project.date.durationPerDay')} requiredFor={requiredFor} disabled={disabled} />}
      min={hoursPerDayMinValue}
      max={hoursPerDayMaxValue}
      step={0.5}
      disabled={disabled}
      value={value}
      onChange={(v) => setValue(v)}
      error={hasErrors}
      InputProps={{
        inputProps: {
          'data-label-key': 'field.project.date.durationPerDay',
        },
        error: hasErrors,
        endAdornment: (
          <>
            <InputAdornment position='end'>
              {t('unit.full.hour', {
                count: value ?? 0,
                ns: 'common',
              })}
            </InputAdornment>
            <InputAdornment position='end'>
              <Suggestions
                disabled={disabled}
                content={(close) => (
                  <DurationHoursPerDaySuggestions
                    suggestionPromptInput={suggestionPromptInput}
                    onChange={(v) => {
                      setValue(v);
                      close();
                    }}
                  />
                )}
              />
            </InputAdornment>
          </>
        ),
      }}
    />
  );
}

function DurationHoursPerDaySuggestions({
  suggestionPromptInput,
  onChange: handleChange,
}: {
  suggestionPromptInput: SuggestionPromptInput | null;
  onChange: (selectedValue: number) => void;
}) {
  const query = useLazyLoadQuery<ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery>(
    graphql`
      query ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery(
        $scoreThreshold: Float!
        $suggestionPrompt: SuggestionPromptInput!
        $suggestionCount: Int!
      ) {
        suggestedHoursPerDay(scoreThreshold: $scoreThreshold, prompt: $suggestionPrompt, count: $suggestionCount) {
          value
          score
        }
      }
    `,
    {
      scoreThreshold: scoreThreshold,
      suggestionPrompt: suggestionPromptInput ?? emptySuggestionPromptInput,
      suggestionCount: suggestionCount,
    },
    { fetchPolicy: 'store-and-network' },
  );

  const data = query.suggestedHoursPerDay ?? [];

  return (
    <AutomaticSuggestionList keySelector={(v) => `${v}`} items={data} onChange={(selectedValue: number) => handleChange(selectedValue)} />
  );
}
