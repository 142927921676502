/**
 * @generated SignedSource<<f817d0dd9a3c803183728309784d9772>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useFavoriteEquipment_useUpdateBoomConfigurationInfo_initialValueFragment$data = {
  readonly manualConfiguration: {
    readonly userInput: {
      readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_Manual_BoomLengthFragment" | "CraneSelectorFields_Manual_CounterweightFragment" | "CraneSelectorFields_Manual_JibLengthFragment" | "CraneSelectorFields_Manual_MaxWeightFragment" | "CraneSelectorFields_Manual_OffsetAngleFragment" | "CraneSelectorFields_Manual_RadiusFragment">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"AdditionalCranesFields_AdditionalCranesManualCollectionFragment">;
  };
  readonly " $fragmentType": "useFavoriteEquipment_useUpdateBoomConfigurationInfo_initialValueFragment";
};
export type useFavoriteEquipment_useUpdateBoomConfigurationInfo_initialValueFragment$key = {
  readonly " $data"?: useFavoriteEquipment_useUpdateBoomConfigurationInfo_initialValueFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_useUpdateBoomConfigurationInfo_initialValueFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useFavoriteEquipment_useUpdateBoomConfigurationInfo_initialValueFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ManualConfigurationInternal",
      "kind": "LinkedField",
      "name": "manualConfiguration",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AdditionalCranesFields_AdditionalCranesManualCollectionFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ManualConfigurationInfo",
          "kind": "LinkedField",
          "name": "userInput",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CraneSelectorFields_Manual_BoomLengthFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CraneSelectorFields_Manual_JibLengthFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CraneSelectorFields_Manual_CounterweightFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CraneSelectorFields_Manual_OffsetAngleFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CraneSelectorFields_Manual_RadiusFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CraneSelectorFields_Manual_MaxWeightFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};

(node as any).hash = "113a6f1833b38bb0960ad076ec5ecc1c";

export default node;
