/**
 * @generated SignedSource<<85b34758c4b0574cf3c5d7c379de9135>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoomConfigurationAutocompleteQuery$variables = {
  capacity: number;
  configurationKindCode: number;
  equipmentKindCode: number;
  searchTerm?: string | null | undefined;
};
export type BoomConfigurationAutocompleteQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BoomConfigurationAutocompleteFragment">;
};
export type BoomConfigurationAutocompleteQuery = {
  response: BoomConfigurationAutocompleteQuery$data;
  variables: BoomConfigurationAutocompleteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "capacity"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "configurationKindCode"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "equipmentKindCode"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v4 = {
  "kind": "Variable",
  "name": "capacity",
  "variableName": "capacity"
},
v5 = {
  "kind": "Variable",
  "name": "configurationKindCode",
  "variableName": "configurationKindCode"
},
v6 = {
  "kind": "Variable",
  "name": "equipmentKindCode",
  "variableName": "equipmentKindCode"
},
v7 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v8 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 25
  },
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BoomConfigurationAutocompleteQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "BoomConfigurationAutocompleteFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "BoomConfigurationAutocompleteQuery",
    "selections": [
      {
        "alias": "searchResults",
        "args": (v8/*: any*/),
        "concreteType": "SearchBoomConfigurationsConnection",
        "kind": "LinkedField",
        "name": "searchBoomConfigurations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchBoomConfigurationsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoomConfigurationSnapshot",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "name": "label",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "boomLength",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "jibLength",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "counterweight",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sequence",
                          "storageKey": null
                        }
                      ],
                      "type": "BoomConfigurationSnapshot",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": true
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "searchResults",
        "args": (v8/*: any*/),
        "filters": [
          "searchTerm",
          "capacity",
          "equipmentKindCode",
          "configurationKindCode"
        ],
        "handle": "connection",
        "key": "BoomConfigurationAutocompleteFragment_searchResults",
        "kind": "LinkedHandle",
        "name": "searchBoomConfigurations"
      }
    ]
  },
  "params": {
    "cacheID": "91aafa378ccfd14b04eeb056d3ef7c9f",
    "id": null,
    "metadata": {},
    "name": "BoomConfigurationAutocompleteQuery",
    "operationKind": "query",
    "text": "query BoomConfigurationAutocompleteQuery(\n  $searchTerm: String\n  $capacity: Int!\n  $equipmentKindCode: Int!\n  $configurationKindCode: Int!\n) {\n  ...BoomConfigurationAutocompleteFragment_3nkxUZ\n}\n\nfragment BoomConfigurationAutocompleteFragment_3nkxUZ on Query {\n  searchResults: searchBoomConfigurations(searchTerm: $searchTerm, capacity: $capacity, equipmentKindCode: $equipmentKindCode, configurationKindCode: $configurationKindCode, first: 25) {\n    edges {\n      node {\n        id\n        ...BoomConfigurationSnapshotLabelResolver\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n\nfragment BoomConfigurationSnapshotLabelResolver on BoomConfigurationSnapshot {\n  boomLength\n  jibLength\n  counterweight\n  sequence\n}\n"
  }
};
})();

(node as any).hash = "97d6ccc4c2274513b3be8325c0e5c3a8";

export default node;
