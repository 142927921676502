/**
 * @generated SignedSource<<8cc7e7733ba17207aab9e8bba77bf9eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CraneSelectorFields_useFieldCraneSelectorAutomaticFavoriteConfigurationFragment$data = {
  readonly automaticConfiguration: {
    readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_CraneConfigurationsFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_CraneSelectorModeFragment" | "CraneSelectorFields_FavoriteConfigurationFragment">;
  readonly " $fragmentType": "CraneSelectorFields_useFieldCraneSelectorAutomaticFavoriteConfigurationFragment";
};
export type CraneSelectorFields_useFieldCraneSelectorAutomaticFavoriteConfigurationFragment$key = {
  readonly " $data"?: CraneSelectorFields_useFieldCraneSelectorAutomaticFavoriteConfigurationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_useFieldCraneSelectorAutomaticFavoriteConfigurationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CraneSelectorFields_useFieldCraneSelectorAutomaticFavoriteConfigurationFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CraneSelectorFields_CraneSelectorModeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CraneSelectorFields_FavoriteConfigurationFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomaticConfigurationInternal",
      "kind": "LinkedField",
      "name": "automaticConfiguration",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneSelectorFields_CraneConfigurationsFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};

(node as any).hash = "b2f222212cae821b52f0d63c2c9a3c0c";

export default node;
