/**
 * @generated SignedSource<<d3edb616cafe7be13a4251586f639461>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useFavoriteEquipmentSelectedFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_CraneSelectorModeFragment">;
  readonly " $fragmentType": "useFavoriteEquipmentSelectedFragment";
};
export type useFavoriteEquipmentSelectedFragment$key = {
  readonly " $data"?: useFavoriteEquipmentSelectedFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipmentSelectedFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useFavoriteEquipmentSelectedFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CraneSelectorFields_CraneSelectorModeFragment"
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};

(node as any).hash = "b83150e99b7c13ec6e989b47b3ec7a28";

export default node;
