import { ForwardedRef, forwardRef } from 'react';
import { DimensionInput, DimensionInputProps } from './DimensionInput';
import { isMassUnit, Mass, massUnits } from '../utils/dimensions/mass';
import { Logger } from '../utils/logging';

const logger = new Logger('MassInput');

export type MassInputProps = DimensionInputProps<Mass>;

export const MassInput = forwardRef(function MassInput(props: MassInputProps, ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>) {
  return (
    <DimensionInput<Mass>
      {...props}
      enabledUnits={props.enabledUnits ?? massUnits}
      ref={ref}
      factory={(scalar, unit) => new Mass(scalar, unit)}
      isValidUnit={isMassUnit}
      logger={logger}
      unitKey='mass'
    />
  );
});
