import Qty from 'js-quantities';
import { ValueObject } from 'value-object-cache';
import { Logger } from '../logging';
import { Namespace, TFunction } from 'i18next';

export interface DimensionProps<Unit extends string> {
  readonly scalar: number;
  readonly unit: Unit;
}

export type DimensionMinMax<T extends Dimension> = number | T | Record<T['unit'], number> | null | undefined;

/** A {@link Intl.NumberFormat} instance used to format scalars sent to the API:
 * - uses 'en-US' locale (i.e. use dot separators instead of commas even when the app is configured in French)
 * - forces "standard" notation by default (i.e. produces `'10000000000000000000000000'` instead of `'1e25'`)
 * - disables grouping (i.e. no thousand separators)
 * - enables maximum resolution (allowable by {@link Intl.NumberFormat}) of 20 fraction digits */
export const apiNumberFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 20, useGrouping: false });

export abstract class Dimension<
  Unit extends string = string,
  Props extends DimensionProps<Unit> = DimensionProps<Unit>,
> extends ValueObject<Props> {
  protected static readonly logger = new Logger('Dimension');

  /** Parse a serialized dimension string into a `Dimension` subclass. */
  protected static baseParse<T extends Dimension>(
    serializedDimension: string | null | undefined,
    isValidUnit: (unit: string) => unit is T['unit'],
    factory: (props: DimensionProps<T['unit']>) => T,
  ): T | null {
    if (serializedDimension == null) return null;

    const qty = Qty.parse(serializedDimension);
    if (!qty) {
      this.logger.error(`Failed to parse value ${JSON.stringify(serializedDimension)}: Qty.parse() returned null`);
      return null;
    }

    const { scalar } = qty;
    // Reject Infinity / -Infinity / NaN scalars
    if (!Number.isFinite(scalar)) {
      this.logger.error(
        `Failed to parse value ${JSON.stringify(serializedDimension)}: Qty.parse() returned non-finite scalar ${JSON.stringify(scalar)}`,
      );
      return null;
    }

    const unit = qty.units();
    // Reject invalid units
    if (!isValidUnit(unit)) {
      this.logger.error(
        `Failed to parse value ${JSON.stringify(serializedDimension)}: Qty.parse() returned invalid unit ${JSON.stringify(unit)}`,
      );
      return null;
    }

    return factory({ scalar, unit });
  }

  /** Returns the smallest of the provided `Dimension` objects, or `null` if none were provided. */
  static min(): null;
  static min<T extends Dimension>(dimension: T, ...dimensions: T[]): T;
  static min<T extends Dimension>(...dimensions: T[]): T | null;
  static min<T extends Dimension>(...dimensions: T[]): T | null {
    if (!dimensions.length) return null;
    return dimensions
      .map((dim) => [dim, dim.toQty()] as const)
      .reduce(([minDim, minQty], [curDim, curQty]) => (curQty.lt(minQty) ? [curDim, curQty] : [minDim, minQty]))[0];
  }

  /** Returns the largest of the provided `Dimension` objects, or `null` if none were provided. */
  static max(): null;
  static max<T extends Dimension>(dimension: T, ...dimensions: T[]): T;
  static max<T extends Dimension>(...dimensions: T[]): T | null;
  static max<T extends Dimension>(...dimensions: T[]): T | null {
    if (!dimensions.length) return null;
    return dimensions
      .map((dim) => [dim, dim.toQty()] as const)
      .reduce(([maxDim, maxQty], [curDim, curQty]) => (curQty.gt(maxQty) ? [curDim, curQty] : [maxDim, maxQty]))[0];
  }

  /**
   * Convert the current object into a {@link Qty} object from the `js-quantities` library.
   *
   * This method is protected because the internal use of `Qty` objects is an implementation detail that shouldn't be
   * exposed to the consumers of this class and its subclasses.
   */
  protected toQty(): Qty {
    return new Qty(this.scalar, this.unit);
  }

  get scalar() {
    return this.props.scalar;
  }

  get unit() {
    return this.props.unit;
  }

  /** Returns the smallest `Dimension` amongst this instance and the provided `Dimension` arguments. */
  min(...dimensions: this[]): this {
    return Dimension.min(this, ...dimensions);
  }

  /** Returns the largest `Dimension` amongst this instance and the provided `Dimension` arguments. */
  max(...dimensions: this[]): this {
    return Dimension.max(this, ...dimensions);
  }

  /** Returns a `Dimension` object that is no smaller than `min` and no larger than `max`.
   * `min` and `max` can both be either `null` or `undefined`.
   * Throws a `RangeError` if `min` wraps a dimension that is larger than `max`. */
  clamp(min?: this | null, max?: this | null): this {
    if (min && max && min.toQty().gt(max.toQty())) {
      throw new RangeError('Dimension.clamp(): min must be less than or equal to max');
    }
    const tmp = min ? this.max(min) : this;
    return max ? tmp.min(max) : tmp;
  }

  /** Return a `Dimension` object with the scalar rounded to one of the closest multiples of `step`.
   *
   * - If `step` is negative, a {@link RangeError} exception is thrown.
   * - If `step` is zero, the current object is returned.
   * - If `step` is positive, a new `Dimension` object is returned with the scalar rounded to one of the closest
   *   multiples of `step` according to the selected rounding mode (see below).
   *
   * The rounding mode can be altered by passing either `'round'`, `'trunc`', `'floor'` or `'ceil'` as the second
   * argument. The default is `'round'`. */
  toStep(step: number, mode: 'round' | 'trunc' | 'floor' | 'ceil' = 'round'): this {
    if (step < 0) throw new RangeError('Dimension.toStep(): step argument must be greater than or equal to 0');
    if (step === 0) return this;
    const stepInverse = 1 / step;
    const newScalar = Math[mode](this.scalar * stepInverse) / stepInverse;
    // Assume the subclass' constructor has the same signature as Dimension's constructor.
    // If it doesn't, the subclass will have to override toStep() to correctly invoke the constructor.
    return new (this.constructor as new (scalar: number, unit_: Unit) => this)(newScalar, this.unit);
  }

  /** Convert the current object to the provided unit. */
  toUnit(unit: Unit): this {
    if (unit === this.unit) return this;
    // Assume the subclass' constructor has the same signature as Dimension's constructor.
    // If it doesn't, the subclass will have to override toUnit() to correctly invoke the constructor.
    return new (this.constructor as new (scalar: number, unit_: Unit) => this)(this.toQty().to(unit).scalar, unit);
  }

  /** Resolve the effective min/max value from a {@link DimensionMinMax} value according to the specified unit. */
  protected getMinMax(minMax: DimensionMinMax<this>, unit: Unit): this | null {
    // Assume the subclass' constructor has the same signature as Dimension's constructor.
    // If it doesn't, the subclass will have to override getMinMax() to correctly invoke the constructor.
    const constructor = this.constructor as new (scalar: number, unit_: Unit) => this;
    if (minMax == null) return null;
    if (minMax instanceof constructor) return minMax;
    return new constructor(typeof minMax === 'number' ? minMax : (minMax as Record<Unit, number>)[unit], unit);
  }

  /** Normalize the `Dimension` object according to the provided `min`, `max` and `step`. */
  normalize(min: DimensionMinMax<this>, max: DimensionMinMax<this>, step: number | undefined): this {
    const minMass = this.getMinMax(min, this.unit)?.toStep(step ?? 0, 'ceil');
    const maxMass = this.getMinMax(max, this.unit)?.toStep(step ?? 0, 'floor');
    return this.clamp(minMass, maxMass)
      .toUnit(this.unit)
      .toStep(step ?? 0);
  }

  /**
   * Format a `Dimension` object in a format suitable for being displayed in a UI and following the current locale's
   * standard number format.
   *
   * Takes a `t()` translation function from the `i18next` module, and requires that a translation exists for the
   * `locale` key in the `common` namespace and that its value is the locale to use for the current configured language.
   */
  abstract format(t: TFunction<Namespace>): string;

  /** A protected method that implements the base formatting logic. This method is meant to be called from the subclass'
   * `format()` method with a hardcoded `unitKey` argument used to build unit translation keys for this specific kind of
   * `Dimension` object. */
  protected baseFormat(t: TFunction<Namespace>, unitKey: string) {
    const locale = t('locale', { ns: 'common' });
    const formatter = new Intl.NumberFormat(locale, { maximumFractionDigits: 20 });
    return {
      formatter,
      // https://unicode-explorer.com/c/00A0
      formattedString: `${formatter.format(this.scalar)}\u00a0${t(`unit.${unitKey}.short.${this.unit}`, { ns: 'common' })}`,
    };
  }

  /** Serialize the current object into a string ready to be sent to the backend API. */
  toJSON(): string {
    return `${apiNumberFormatter.format(this.scalar)} ${this.unit}`;
  }
}
