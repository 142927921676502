/**
 * @generated SignedSource<<b1df3f02c840fd6c9d94aad1b1c301b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useFavoriteEquipment_extraDataFragment$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CraneConfiguration_ConfigurationListItemSummary_FavoriteEquipmentFragment" | "CraneConfiguration_FavoritePrimaryEquipment_CardDataFragment" | "EquipmentFormSectionCompact_FavoriteEquipmentDataFragment" | "QuoteEquipmentFields_useTransportSegments_CraneInfos_DefaultDataFragment" | "SaleDateFields_FavoriteEquipmentDataFragment" | "SalesDetails_useBillingCodeSearchInput_FavoriteEquipmentDataFragment" | "ServiceCallSaveButton_useButtonColorDependingOnFormState_FavoriteEquipmentDataFragment" | "useAutomaticAddenda_favoriteEquipmentDataFragment" | "useSuggestions_FavoriteEquipmentDataFragment">;
  readonly " $fragmentType": "useFavoriteEquipment_extraDataFragment";
}>;
export type useFavoriteEquipment_extraDataFragment$key = ReadonlyArray<{
  readonly " $data"?: useFavoriteEquipment_extraDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_extraDataFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useFavoriteEquipment_extraDataFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquipmentFormSectionCompact_FavoriteEquipmentDataFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ServiceCallSaveButton_useButtonColorDependingOnFormState_FavoriteEquipmentDataFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleDateFields_FavoriteEquipmentDataFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SalesDetails_useBillingCodeSearchInput_FavoriteEquipmentDataFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSuggestions_FavoriteEquipmentDataFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CraneConfiguration_FavoritePrimaryEquipment_CardDataFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CraneConfiguration_ConfigurationListItemSummary_FavoriteEquipmentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useAutomaticAddenda_favoriteEquipmentDataFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuoteEquipmentFields_useTransportSegments_CraneInfos_DefaultDataFragment"
    }
  ],
  "type": "BoomConfigurationSnapshot",
  "abstractKey": null
};

(node as any).hash = "332abe52e8463372c87935225816ef47";

export default node;
