/**
 * @generated SignedSource<<3566f209b0c1df48882c7fd31476e652>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_WorksiteOverridesAddressFragment$data = {
  readonly structuredAddress: {
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteAddressInputFragment" | "ProjectBaseFields_WorksiteOverridesCityFragment" | "ProjectBaseFields_WorksiteOverridesCoordinatesFragment" | "ProjectBaseFields_WorksiteOverridesPostalCodeFragment" | "ProjectBaseFields_WorksiteOverridesStateFragment">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesAddressReadFragment">;
  readonly " $fragmentType": "ProjectBaseFields_WorksiteOverridesAddressFragment";
};
export type ProjectBaseFields_WorksiteOverridesAddressFragment$key = {
  readonly " $data"?: ProjectBaseFields_WorksiteOverridesAddressFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesAddressFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_WorksiteOverridesAddressFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverridesAddressReadFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StructuredAddressResult",
      "kind": "LinkedField",
      "name": "structuredAddress",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_WorksiteAddressInputFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_WorksiteOverridesCoordinatesFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_WorksiteOverridesCityFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_WorksiteOverridesStateFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_WorksiteOverridesPostalCodeFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Location",
  "abstractKey": null
};

(node as any).hash = "f17b502f58dd59311a4c36eb45373cc1";

export default node;
