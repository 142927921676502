/**
 * @generated SignedSource<<522e7d834d6493f0a7fdeb40cfdd5b17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignedWorksite_Overrides_ClientFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesAddress_ClientFragment" | "ProjectBaseFields_WorksiteOverridesName_ClientFragment">;
  readonly " $fragmentType": "AssignedWorksite_Overrides_ClientFragment";
};
export type AssignedWorksite_Overrides_ClientFragment$key = {
  readonly " $data"?: AssignedWorksite_Overrides_ClientFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_Overrides_ClientFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignedWorksite_Overrides_ClientFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverridesName_ClientFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverridesAddress_ClientFragment"
    }
  ],
  "type": "ClientInternalBase",
  "abstractKey": null
};

(node as any).hash = "477d43c30c5abc72e44727b53017da27";

export default node;
