/**
 * @generated SignedSource<<7f86045f794fdd86447d0255733f91c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_WorksiteOverridesCityFragment$data = {
  readonly value: {
    readonly city: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ProjectBaseFields_WorksiteOverridesCityFragment";
};
export type ProjectBaseFields_WorksiteOverridesCityFragment$key = {
  readonly " $data"?: ProjectBaseFields_WorksiteOverridesCityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesCityFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_WorksiteOverridesCityFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StructuredAddressData",
      "kind": "LinkedField",
      "name": "value",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StructuredAddressResult",
  "abstractKey": null
};

(node as any).hash = "f091ce79a3e4c8393d5e6abb82b0dd99";

export default node;
