/**
 * @generated SignedSource<<53cb54785aff923e1a76daa53280ad32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as vehicleIdLabelResolverType } from "../../__resolvers__/VehicleIdLabelResolver";
// Type assertion validating that `vehicleIdLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(vehicleIdLabelResolverType satisfies (
  rootKey: VehicleIdLabelResolver$key,
) => string | null | undefined);
export type useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromQueryResultFragment$data = ReadonlyArray<{
  readonly boomLength: string;
  readonly counterweight: string;
  readonly deletedAt: string | null | undefined;
  readonly id: string;
  readonly jibLength: string;
  readonly vehicleIds: ReadonlyArray<{
    readonly key: string;
    readonly label: string | null | undefined;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_extraDataFragment">;
  readonly " $fragmentType": "useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromQueryResultFragment";
}>;
export type useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromQueryResultFragment$key = ReadonlyArray<{
  readonly " $data"?: useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromQueryResultFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromQueryResultFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromQueryResultFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFavoriteEquipment_extraDataFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boomLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jibLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "counterweight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VehicleId",
      "kind": "LinkedField",
      "name": "vehicleIds",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VehicleIdLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../__resolvers__/VehicleIdLabelResolver').label,
          "path": "vehicleIds.label"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BoomConfigurationSnapshot",
  "abstractKey": null
};

(node as any).hash = "a4fd230b3a1a04c4992f0f025c43d556";

export default node;
