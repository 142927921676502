/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const transportKinds = ["crane", "jibAndCounterweight", "mobilisationAndDemobilisation"] as const;
export type TransportKind = typeof transportKinds[number];

export function isTransportKind(value: string): value is TransportKind {
  return transportKinds.includes(value)
}

export function asTransportKind(value: string): TransportKind | null {
  return isTransportKind(value) ? value : null;
}

export function castTransportKind(value: string): TransportKind {
  if(!isTransportKind(value)) {
    throw new Error(`Invalid Enum value for type "TransportKind": "${value}"`);
  }
  
  return value;
}
