import { useCallback, useEffect, useRef } from 'react';
import { FormContext, useFormIsDirty } from '../utils/forms';
import { BlockerFunction, useBlocker } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useCustomEventListener } from '../utils/customEvents';

const PREVENT_BLOCK_KEY = Symbol('$prevent_nav_block');

/**
 * Special State that allows to skip the blocking of React router all along
 * @example
 * const context = createFormContext();
 * function Foo() {
 *   const navigate = useNavigate();
 *   usePreventNavigationOnFormDirty(context);
 *
 *   // Whatever stuff for the fields + one change by the User in the UI
 *
 *   // This navigate won't be blocked
 *   useEffect(() => navigate('bar', { state: NAVIGATE_PREVENT_BLOCK_STATE });
 * }
 */
export const NAVIGATE_PREVENT_BLOCK_STATE = { [PREVENT_BLOCK_KEY]: true };

declare module '../utils/customEvents' {
  interface CustomEvents {
    /** To be used ONLY by the AppUpdateStore prior to reloading the app after an update. */
    disableUnloadBlockerForAppUpdate: void;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function usePreventNavigationOnFormDirty(contexts: FormContext<any>[], shouldBlockFn?: BlockerFunction) {
  const { t } = useTranslation('common');
  const isDirty = contexts.map(useFormIsDirty).some((dirty) => dirty);
  const shouldBlock: BlockerFunction = useCallback(
    (args) => {
      if (args.nextLocation.state?.[PREVENT_BLOCK_KEY]) {
        return false;
      }

      return isDirty && (shouldBlockFn ? shouldBlockFn(args) : args.currentLocation.pathname !== args.nextLocation.pathname);
    },
    [isDirty, shouldBlockFn],
  );
  const blocker = useBlocker(shouldBlock);

  // Listen for a custom event to disable the unload blocker so that the AppUpdateStore can reload the app after
  // installing an update without the reload being blocked by this hook.
  const unloadBlockerDisabledForAppUpdateRef = useRef(false);
  useCustomEventListener('disableUnloadBlockerForAppUpdate', () => {
    unloadBlockerDisabledForAppUpdateRef.current = true;
  });

  // Listen for window navigation and block outside navigation when dirty
  // Note: This does not work on iOS (mobile)
  useEffect(() => {
    const handler = (event: Event) => {
      if (!unloadBlockerDisabledForAppUpdateRef.current && isDirty) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handler);

    return () => window.removeEventListener('beforeunload', handler);
  }, [isDirty]);

  // Listen for internal navigation and block when dirty
  useEffect(() => {
    if (blocker.state === 'blocked') {
      const wantsToNavigate = window.confirm(t('errorMessages.unsavedChanges'));
      if (wantsToNavigate) {
        blocker.proceed?.();
      } else {
        blocker.reset?.();
      }
    }

    // Just make sure we reset on unmount
    return () => blocker.reset?.();
  }, [blocker, t]);

  return blocker;
}
