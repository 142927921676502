/**
 * @generated SignedSource<<497777e75983eae561b9768110e6541e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleEquipmentFields_TransportKindFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SaleEquipmentFields_TransportKindReadFragment">;
  readonly " $fragmentType": "SaleEquipmentFields_TransportKindFragment";
};
export type SaleEquipmentFields_TransportKindFragment$key = {
  readonly " $data"?: SaleEquipmentFields_TransportKindFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleEquipmentFields_TransportKindFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleEquipmentFields_TransportKindFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleEquipmentFields_TransportKindReadFragment"
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "4ed95600a1ff0532555dc6cdecf66dd2";

export default node;
