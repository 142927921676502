import * as Sentry from '@sentry/react';
import { useTheme } from '@mui/material';
import { Logger } from '../utils/logging';
import { errorBoundaryBeforeCaptureFn } from '../../AppErrorBoundary';

const logger = new Logger('FallbackErrorBoundary');

export function FallbackErrorBoundary({ children, ...rest }: Sentry.ErrorBoundaryProps) {
  const theme = useTheme();

  return (
    <Sentry.ErrorBoundary
      {...rest}
      beforeCapture={(scope) => errorBoundaryBeforeCaptureFn(scope, theme)}
      onError={(...args) => {
        logger.error(...args);
      }}>
      {children}
    </Sentry.ErrorBoundary>
  );
}
