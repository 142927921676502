export const appUpdate = {
  updateInProgress: 'The application is updating, please wait...',
  snackbar: {
    title: 'Update available',
    description:
      'An update to the latest available version will be performed in <strong>{{countdown}}</strong>. Save your changes before updating, otherwise they will be lost.',
    buttons: {
      close: 'Later',
      update: 'Update',
    },
  },
};
