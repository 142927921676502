/**
 * @generated SignedSource<<2ce8bd39ea036aee5127ac97a246e560>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as configurationKindLookupLabelResolverType } from "../../../__resolvers__/ConfigurationKindLookupLabelResolver";
// Type assertion validating that `configurationKindLookupLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(configurationKindLookupLabelResolverType satisfies (
  rootKey: ConfigurationKindLookupLabelResolver$key,
) => Record<string, string> | null | undefined);
export type CraneSelectorFields_CraneConfigurationKindFragment$data = {
  readonly configurationKind: {
    readonly code: number;
    readonly id: string;
    readonly label: Record<string, string> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "CraneSelectorFields_CraneConfigurationKindFragment";
};
export type CraneSelectorFields_CraneConfigurationKindFragment$key = {
  readonly " $data"?: CraneSelectorFields_CraneConfigurationKindFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_CraneConfigurationKindFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CraneSelectorFields_CraneConfigurationKindFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ConfigurationKindLookup",
      "kind": "LinkedField",
      "name": "configurationKind",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ConfigurationKindLookupLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../../__resolvers__/ConfigurationKindLookupLabelResolver').label,
          "path": "configurationKind.label"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "1d23133b0b17f247e0dec51ba1ff8771";

export default node;
