/**
 * @generated SignedSource<<02f050e60bb5e041fa8810b4060e838a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as requirementLabelResolverType } from "../../__resolvers__/RequirementLabelResolver";
// Type assertion validating that `requirementLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(requirementLabelResolverType satisfies (
  rootKey: RequirementLabelResolver$key,
) => string | null | undefined);
export type AssignedWorksite_useUpdateWorksiteQueryFragment$data = {
  readonly contact: string | null | undefined;
  readonly location: {
    readonly address: string;
    readonly structuredAddress: {
      readonly value: {
        readonly apartment: string | null | undefined;
        readonly city: string | null | undefined;
        readonly coordinates: {
          readonly latitude: number;
          readonly longitude: number;
        } | null | undefined;
        readonly postalCode: string | null | undefined;
        readonly state: string | null | undefined;
        readonly streetAddress: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly name: string | null | undefined;
  readonly phoneNumber: string | null | undefined;
  readonly requirements: ReadonlyArray<{
    readonly id: string;
    readonly label: string | null | undefined;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_Label_BaseFragment" | "AssignedWorksite_Overrides_BaseFragment" | "AssignedWorksite_Overrides_DenormalizableFragment">;
  readonly " $fragmentType": "AssignedWorksite_useUpdateWorksiteQueryFragment";
};
export type AssignedWorksite_useUpdateWorksiteQueryFragment$key = {
  readonly " $data"?: AssignedWorksite_useUpdateWorksiteQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_useUpdateWorksiteQueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignedWorksite_useUpdateWorksiteQueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssignedWorksite_Label_BaseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssignedWorksite_Overrides_BaseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssignedWorksite_Overrides_DenormalizableFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Requirement",
      "kind": "LinkedField",
      "name": "requirements",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RequirementLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../__resolvers__/RequirementLabelResolver').label,
          "path": "requirements.label"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contact",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StructuredAddressResult",
          "kind": "LinkedField",
          "name": "structuredAddress",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StructuredAddressData",
              "kind": "LinkedField",
              "name": "value",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "streetAddress",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Coordinates",
                  "kind": "LinkedField",
                  "name": "coordinates",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "latitude",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "longitude",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "apartment",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "city",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "state",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "postalCode",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worksite",
  "abstractKey": null
};

(node as any).hash = "96ed9a69d49af9516e5fc40c4acd5ef0";

export default node;
