import {
  createFieldKey,
  SetValueFn,
  useField,
  useFieldHasErrors,
  useFieldIsDirty,
  useFieldMapper,
  useFieldSetter,
  useFieldValidation,
  useFieldValue,
} from '../../common/utils/forms';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Checkbox, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactNode, useCallback } from 'react';
import { SaleEquipmentFields_PreventSubcontractingFragment$key } from './__generated__/SaleEquipmentFields_PreventSubcontractingFragment.graphql';
import { SaleEquipmentFields_PreventReplacementFragment$key } from './__generated__/SaleEquipmentFields_PreventReplacementFragment.graphql';
import { saleFormContext } from '../SaleFields';
import { QuoteKind } from '../../__enums__/QuoteKind';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { LengthInput } from '../../common/components/LengthInput';
import { Length, LengthUnit } from '../../common/utils/dimensions/length';
import { SaleEquipmentFields_BranchToWorksiteDistanceFragment$key } from './__generated__/SaleEquipmentFields_BranchToWorksiteDistanceFragment.graphql';
import { SxProps } from '@mui/system';
import { SaleEquipmentFields_BranchToWorksiteDistanceCalculatorFragment$key } from './__generated__/SaleEquipmentFields_BranchToWorksiteDistanceCalculatorFragment.graphql';
import { JobStage } from '../jobStage';
import { RequiredForInputLabel, RequiredForStatus } from '../RequiredForJobStatus';
import { BoxedFormControlLabel } from '../../common/BoxedFormControlLabel';
import { castTransportKind, TransportKind, transportKinds } from '../../__enums__/TransportKind';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { SelectPicker } from '../../common/components/SelectPicker';
import { SaleEquipmentFields_TransportKindReadFragment$key } from './__generated__/SaleEquipmentFields_TransportKindReadFragment.graphql';
import { SaleEquipmentFields_TransportKindFragment$key } from './__generated__/SaleEquipmentFields_TransportKindFragment.graphql';

export function useJobKindHasEquipment(kind: QuoteKind | ServiceCallKind) {
  return [
    'bare',
    'bareWithOperators',
    'laborRental',
    'laborRentalOnBare',
    'laborRentalOnOperated',
    'liftingTestOperated',
    'operatedHourly',
    'operatedMonthly',
  ].includes(kind);
}

export function useJobKindRequiresEquipment(kind: QuoteKind | ServiceCallKind | null) {
  if (kind === null) return false;
  return [
    'bare',
    'bareWithOperators',
    'operatedHourly',
    'operatedMonthly',
    'liftingTestOperated',
    'laborRentalOnBare',
    'laborRentalOnOperated',
  ].includes(kind);
}

const fieldPreventReplacementKey = createFieldKey<boolean>();
export function useFieldPreventReplacement($key: SaleEquipmentFields_PreventReplacementFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleEquipmentFields_PreventReplacementFragment on ISaleEquipment {
        preventReplacement
      }
    `,
    $key,
  );

  const [preventReplacement, setPreventReplacement] = useField(
    saleFormContext,
    fieldPreventReplacementKey,
    () => $data?.preventReplacement ?? false,
  );

  const useMapper = useFieldMapper(saleFormContext, fieldPreventReplacementKey);
  useMapper((v) => ({ equipmentSale: { preventReplacement: v } }), [], 'save');

  const renderPreventReplacement = useCallback(
    () => (
      <SaleEquipmentFields_CheckBoxInput
        value={preventReplacement}
        setValue={setPreventReplacement}
        labelKey='field.equipment.noReplacement'
        disabled={disabled}
      />
    ),
    [disabled, preventReplacement, setPreventReplacement],
  );

  return { preventReplacement, renderPreventReplacement };
}

const fieldPreventSubcontractingKey = createFieldKey<boolean>();
export function useFieldPreventSubcontracting(
  $key: SaleEquipmentFields_PreventSubcontractingFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment SaleEquipmentFields_PreventSubcontractingFragment on ISaleEquipment {
        preventSubcontracting
      }
    `,
    $key,
  );

  const [preventSubcontracting, setPreventSubcontracting] = useField(
    saleFormContext,
    fieldPreventSubcontractingKey,
    () => $data?.preventSubcontracting ?? false,
  );

  const useMapper = useFieldMapper(saleFormContext, fieldPreventSubcontractingKey);
  useMapper((v) => ({ equipmentSale: { preventSubcontracting: v } }), [], 'save');

  const renderPreventSubcontracting = useCallback(
    () => (
      <SaleEquipmentFields_CheckBoxInput
        value={preventSubcontracting}
        setValue={setPreventSubcontracting}
        labelKey='field.equipment.noSubcontractor'
        disabled={disabled}
      />
    ),
    [disabled, preventSubcontracting, setPreventSubcontracting],
  );

  return { preventSubcontracting, renderPreventSubcontracting };
}

function SaleEquipmentFields_CheckBoxInput({
  value,
  setValue,
  labelKey,
  disabled,
}: {
  value: boolean;
  setValue: SetValueFn<boolean>;
  labelKey: string;
  disabled: boolean;
}) {
  const { t } = useTranslation('craneSelector');

  return (
    <BoxedFormControlLabel
      disabled={disabled}
      control={<Checkbox checked={value} onChange={(e) => setValue(e.target.checked)} />}
      label={t(labelKey)}
      labelPlacement='end'
    />
  );
}

type FieldBranchToWorksiteDistance = Length | null;
const fieldBranchToWorksiteDistanceKey = createFieldKey<FieldBranchToWorksiteDistance>();

export function useFieldBranchToWorksiteDistanceRead($key: SaleEquipmentFields_BranchToWorksiteDistanceFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleEquipmentFields_BranchToWorksiteDistanceFragment on ISaleEquipment {
        branchToWorksiteDistance
      }
    `,
    $key,
  );
  const branchToWorksiteDistance = useFieldValue(saleFormContext, fieldBranchToWorksiteDistanceKey, () =>
    Length.parse($data?.branchToWorksiteDistance),
  );

  const branchToWorksiteDistanceIsDirty = useFieldIsDirty(saleFormContext, fieldBranchToWorksiteDistanceKey);

  const useMapper = useFieldMapper(saleFormContext, fieldBranchToWorksiteDistanceKey);
  useMapper(
    (value) => ({
      equipmentSale: {
        branchToWorksiteDistance: value?.toJSON() ?? null,
      },
    }),
    [],
    'save',
  );

  return { branchToWorksiteDistance, branchToWorksiteDistanceIsDirty };
}
export function useFieldBranchToWorksiteDistance(
  $key: SaleEquipmentFields_BranchToWorksiteDistanceFragment$key | null | undefined,
  disabled: boolean,
) {
  const { branchToWorksiteDistance, ...rest } = useFieldBranchToWorksiteDistanceRead($key);
  const setBranchToWorksiteDistance = useFieldSetter(saleFormContext, fieldBranchToWorksiteDistanceKey);

  const useValidation = useFieldValidation(saleFormContext, fieldBranchToWorksiteDistanceKey);
  useValidation((v) => !!v, [], 'transferable,submittable:required');

  const renderBranchToWorksiteDistance = useCallback(
    () => <BranchToWorksiteDistanceInput value={branchToWorksiteDistance} setValue={setBranchToWorksiteDistance} disabled={disabled} />,
    [branchToWorksiteDistance, disabled, setBranchToWorksiteDistance],
  );

  return {
    branchToWorksiteDistance,
    renderBranchToWorksiteDistance,
    ...rest,
  };
}

const branchToWorksiteDistanceLengthUnits = ['km'] as const satisfies LengthUnit[];
function BranchToWorksiteDistanceInput({
  value,
  setValue,
  disabled,
}: {
  value: FieldBranchToWorksiteDistance;
  setValue: SetValueFn<FieldBranchToWorksiteDistance>;
  disabled: boolean;
}) {
  const { t } = useTranslation('jobs');
  const hasErrors = useFieldHasErrors(saleFormContext, fieldBranchToWorksiteDistanceKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['transfer'],
  };

  return (
    <LengthInput
      enabledUnits={branchToWorksiteDistanceLengthUnits}
      value={value}
      label={
        <RequiredForInputLabel
          label={t('field.equipment.transport.branchToWorksiteDistance')}
          requiredFor={requiredFor}
          disabled={disabled}
        />
      }
      data-label-key='field.equipment.transport.branchToWorksiteDistance'
      onChange={(v) => setValue(v)}
      disabled={disabled}
      error={hasErrors && !disabled}
    />
  );
}

export type SaleEquipmentFields_BranchToWorksiteDistance_PresentFn = (render: {
  renderDistanceSaleToWorksiteFields: (sx?: SxProps<Theme>) => ReactNode;
}) => ReactNode;
export function SaleEquipmentFields_BranchToWorksiteDistance({
  $key,
  presentFn,
  disabled,
}: {
  $key: SaleEquipmentFields_BranchToWorksiteDistanceCalculatorFragment$key | null | undefined;
  presentFn: SaleEquipmentFields_BranchToWorksiteDistance_PresentFn;
  disabled: boolean;
}) {
  const $data = useFragment(
    graphql`
      fragment SaleEquipmentFields_BranchToWorksiteDistanceCalculatorFragment on ISale {
        equipment {
          ...SaleEquipmentFields_BranchToWorksiteDistanceFragment
        }
      }
    `,
    $key,
  );

  const { renderBranchToWorksiteDistance } = useFieldBranchToWorksiteDistance($data?.equipment, disabled);

  return presentFn({
    renderDistanceSaleToWorksiteFields: () => renderBranchToWorksiteDistance(),
  });
}

const fieldTransportKindKey = createFieldKey<TransportKind>();
export function useFieldTransportKindRead($key: SaleEquipmentFields_TransportKindReadFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleEquipmentFields_TransportKindReadFragment on ISale {
        equipment {
          transportKind
        }
      }
    `,
    $key,
  );

  const transportKind = useFieldValue(saleFormContext, fieldTransportKindKey, () =>
    $data?.equipment.transportKind ? castTransportKind($data.equipment.transportKind) : 'jibAndCounterweight',
  );
  const useMapper = useFieldMapper(saleFormContext, fieldTransportKindKey);
  useMapper((v) => ({ equipmentSale: { transportKind: v } }), [], 'save');
  useMapper((v) => ({ selectedTransportKind: v }), [], 'recalculate');

  return { transportKind };
}

export function useFieldTransportKind($key: SaleEquipmentFields_TransportKindFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleEquipmentFields_TransportKindFragment on ISale {
        ...SaleEquipmentFields_TransportKindReadFragment
      }
    `,
    $key,
  );

  const { transportKind } = useFieldTransportKindRead($data);
  const setTransportKind = useFieldSetter(saleFormContext, fieldTransportKindKey);

  const renderTransportKind = useCallback(() => {
    return <TransportKindInput value={transportKind} setValue={setTransportKind} disabled={disabled} />;
  }, [transportKind, setTransportKind, disabled]);

  return { transportKind, setTransportKind, renderTransportKind };
}

function TransportKindInput({
  value,
  setValue,
  disabled,
}: {
  value: TransportKind;
  setValue: SetValueFn<TransportKind>;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  return (
    <SelectPicker
      value={value}
      disableClearable
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t('transportKind.' + o)}
      options={transportKinds}
      onChange={(_, v) => setValue(v)}
      disabled={disabled}
      textFieldProps={(params) => ({
        ...params,
        disabled,
        label: t('field.equipment.transport.transportKind'),
      })}
    />
  );
}
