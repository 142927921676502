/**
 * @generated SignedSource<<f2d569daff9b4e85117803179ff05b8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_WorksiteOverridesNameFragment$data = {
  readonly location: {
    readonly structuredAddress: {
      readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesCityFragment" | "ProjectBaseFields_WorksiteOverridesCoordinatesFragment" | "ProjectBaseFields_WorksiteOverridesPostalCodeFragment" | "ProjectBaseFields_WorksiteOverridesStateFragment">;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesAddressFragment">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesName_ReadFragment">;
  readonly " $fragmentType": "ProjectBaseFields_WorksiteOverridesNameFragment";
};
export type ProjectBaseFields_WorksiteOverridesNameFragment$key = {
  readonly " $data"?: ProjectBaseFields_WorksiteOverridesNameFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesNameFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_WorksiteOverridesNameFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverridesName_ReadFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_WorksiteOverridesAddressFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StructuredAddressResult",
          "kind": "LinkedField",
          "name": "structuredAddress",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectBaseFields_WorksiteOverridesCoordinatesFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectBaseFields_WorksiteOverridesCityFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectBaseFields_WorksiteOverridesStateFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectBaseFields_WorksiteOverridesPostalCodeFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IOverridableWorksite",
  "abstractKey": "__isIOverridableWorksite"
};

(node as any).hash = "3f36ea078ee8a7ca908b0871d2da0845";

export default node;
