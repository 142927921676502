import { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { useOperations } from '../AppSharedState';
import { WORK_PLANNING_COPY_OPERATION_KEY } from './WorkPlanningCopyButton';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';

export type WorkPlanningCopyDialogRef = {
  readonly open: (onConfirm?: (search: string) => void) => void;
  readonly close: () => void;
};

export const WorkPlanningCopyDialog = forwardRef(function WorkPlanningCopyDialog(_, ref: ForwardedRef<WorkPlanningCopyDialogRef>) {
  const { t } = useAmbientTranslation('jobs', { nsMode: 'fallback' });
  const theme = useTheme();
  const { hasOperationInFlightForKey } = useOperations(WORK_PLANNING_COPY_OPERATION_KEY);

  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState<((search: string) => void) | undefined>();

  const [keepAttachments, setKeepAttachments] = useState<boolean>(true);

  const resetValues = useCallback(() => {
    setKeepAttachments(false);
  }, []);

  const handleConfirm = useCallback(() => {
    const params = new URLSearchParams({
      strategy: 'unlinked',
      keepAttachments: keepAttachments.toString(),
    });

    onConfirm?.(params.toString());
  }, [keepAttachments, onConfirm]);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
    resetValues();
  }, [resetValues]);

  useImperativeHandle(
    ref,
    () => ({
      open: (confirmFn) => {
        setIsOpen(true);
        setOnConfirm(() => confirmFn);
      },
      close: () => {
        setIsOpen(false);
        resetValues();
      },
    }),
    [resetValues],
  );

  return (
    <Dialog open={isOpen} onClose={handleCancel} maxWidth='md'>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <IconButton color='inherit' data-testid='closeButton' onClick={handleCancel} sx={{ ml: -1.5 }}>
          <CloseIcon />
        </IconButton>
        {t('dialog.copy.title')}
      </DialogTitle>
      <DialogContent sx={{ gap: '0.5rem', display: 'flex', flexDirection: 'column' }}>
        <Typography
          sx={{
            pt: '0.5rem',
            pb: '1rem',
            backgroundColor: theme.palette.background.paper,
          }}>
          {t('dialog.copy.body')}
        </Typography>
        <FormControlLabel
          control={<Switch checked={keepAttachments} value={keepAttachments} />}
          onChange={(_e, checked) => setKeepAttachments(checked)}
          label={t('dialog.copy.attachments')}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={hasOperationInFlightForKey}
          color='primary'
          onClick={handleConfirm}
          data-label-key='dialog.copy.confirmButton'>
          {t('dialog.copy.confirmButton')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});
