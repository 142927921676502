/**
 * @generated SignedSource<<ba83de95a95c9ff0b222b309cbdff23f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useFavoriteEquipmentFragment$data = {
  readonly automaticConfiguration: {
    readonly " $fragmentSpreads": FragmentRefs<"AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment">;
  };
  readonly manualConfiguration: {
    readonly userInput: {
      readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_CraneCapacityFragment" | "CraneSelectorFields_CraneConfigurationKindFragment" | "CraneSelectorFields_EquipmentKindFragment" | "CraneSelectorFields_Manual_BoomConfigurationFragment" | "CraneSelectorFields_Manual_OffsetAngleFragment" | "useFavoriteEquipment_useResetBoomConfigurationFragment">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"AdditionalCranesFields_AdditionalCranesManualCollectionFragment" | "useFavoriteEquipment_SynchronizeManual_FromFieldValuesFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_useFieldCraneSelectorAutomaticFavoriteConfigurationFragment" | "useFavoriteEquipment_SynchronizeAutomatic_FromFieldValuesFragment" | "useFavoriteEquipment_useUpdateAutomaticAdditionalConfigurationFields_InitialValueFragment" | "useFavoriteEquipment_useUpdateBoomConfigurationInfo_initialValueFragment">;
  readonly " $fragmentType": "useFavoriteEquipmentFragment";
};
export type useFavoriteEquipmentFragment$key = {
  readonly " $data"?: useFavoriteEquipmentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipmentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useFavoriteEquipmentFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFavoriteEquipment_useUpdateBoomConfigurationInfo_initialValueFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CraneSelectorFields_useFieldCraneSelectorAutomaticFavoriteConfigurationFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFavoriteEquipment_useUpdateAutomaticAdditionalConfigurationFields_InitialValueFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFavoriteEquipment_SynchronizeAutomatic_FromFieldValuesFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomaticConfigurationInternal",
      "kind": "LinkedField",
      "name": "automaticConfiguration",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ManualConfigurationInternal",
      "kind": "LinkedField",
      "name": "manualConfiguration",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useFavoriteEquipment_SynchronizeManual_FromFieldValuesFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AdditionalCranesFields_AdditionalCranesManualCollectionFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ManualConfigurationInfo",
          "kind": "LinkedField",
          "name": "userInput",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useFavoriteEquipment_useResetBoomConfigurationFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CraneSelectorFields_CraneCapacityFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CraneSelectorFields_Manual_OffsetAngleFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CraneSelectorFields_Manual_BoomConfigurationFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CraneSelectorFields_EquipmentKindFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CraneSelectorFields_CraneConfigurationKindFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};

(node as any).hash = "9d596a3a3a8b8a75296bbbc686c2aa56";

export default node;
