import { useMemo, useRef } from 'react';
import { Paths, PickDeep } from 'type-fest';
import { _throw } from '../utils/_throw';
import { relate, sequenceEqual } from '../utils/arrayUtils';

/**
 * Return the nested value at path in a given value.
 * @param value A value like `{ foo: { bar: { bizz: 4, buzz: '' } } }`
 * @param path A path like `'foo.bar.bizz'`
 */
function get<T, TPath extends Paths<T>>(value: T, path: TPath): Readonly<PickDeep<T, TPath>> {
  const steps = (path as string).split('.');

  // Dive as deep as possible in the source value while keeping track of the last processed step along the way.
  let stepValue = value as Record<PropertyKey, unknown>;

  for (const step of steps) {
    const val = stepValue[step];

    // Dive in value
    if (val == null) {
      return val as unknown as PickDeep<T, TPath>;
    }
    stepValue = val as Record<PropertyKey, unknown>;
  }

  return stepValue as PickDeep<T, TPath>;
}

/**
 * Returns the nested values for a collection of paths in a given value.
 * @see get
 * @param value A value like `{ foo: { bar: { bizz: 4, buzz: '' } } }`
 * @param paths A collection of paths like `['foo.bar.bizz']`
 */
function lens<T, TPaths extends Paths<T>>(value: T, ...paths: readonly TPaths[]): Readonly<PickDeep<T, TPaths>> {
  return paths.reduce(
    (acc, path) => {
      const steps = (path as string).split('.');
      const getStep = (depth: number) => steps[depth] ?? _throw(`Out of range. Couldn't index steps from path at position ${depth}.`);

      // Dive as deep as possible in the source value while keeping track of the last processed step along the way.
      let stepValue = value as Record<PropertyKey, unknown>;
      let stepAcc = acc as Record<PropertyKey, unknown>;

      for (
        let depth = 0;
        // Stopping one step shy of the end as, contrary to the simpler get algorithm, we need also to keep track of the
        // target instance for the final setter.
        depth < steps.length - 1;
        ++depth
      ) {
        const step = getStep(depth);
        const nextStep = getStep(depth + 1);

        // Dive in value
        stepValue = stepValue == null ? stepValue : (stepValue[step] as Record<PropertyKey, unknown>);

        // Dive in accumulator
        if (!(step in stepAcc)) {
          stepAcc[step] =
            stepValue != null
              ? // Defaults to actual typing when value is available
                Array.isArray(stepValue)
                ? []
                : {}
              : // Fallbacks to pathing heuristics when value is unavailable.
                // Need to check the next step in the list since we already dove in value.
                isNaN(+nextStep)
                ? {}
                : [];
        }
        stepAcc = stepAcc[step] as Record<PropertyKey, unknown>;
      }

      // Set the accumulator's value to deepest in the path
      const lastStep = getStep(steps.length - 1);
      stepAcc[lastStep] = stepValue == null ? stepValue : stepValue[lastStep];

      return acc;
    },
    {} as PickDeep<T, TPaths>,
  );
}

/**
 * Returns the nested values for a collection of paths in a given collection of values.
 *
 * This hook maintains an internal cache that minimizes value updates. It will only
 * produce new arrays, or entries, when needed, else it returns the previous entry.
 * @param values A collection of value like `[{ foo: { bar: { bizz: 4, buzz: '' } } }]`
 * @param paths A collection of paths like `['foo.bar.bizz']`
 */
export function useArrayLens<T, TPaths extends Paths<T>>(
  values: readonly T[],
  ...paths: readonly TPaths[]
): readonly Readonly<PickDeep<T, TPaths>>[] {
  const previousPathsRef = useRef(paths);
  const memoizedPaths = sequenceEqual(previousPathsRef.current, paths) ? previousPathsRef.current : paths;

  // Avoid recomputing resultsRef's initial value over and over
  const resultsRef = useRef<readonly Readonly<PickDeep<T, TPaths>>[]>(null as never);
  resultsRef.current ??= values.map((v) => lens(v, ...paths));

  const results = useMemo(() => {
    const candidates = values.map((v) => lens(v, ...memoizedPaths));

    // Detect paths changes and reset results when they happen.
    // Since the list of properties we're maintaining changed, the entire collection needs to be rebuilt.
    if (relate(memoizedPaths, previousPathsRef.current).some(([path, previousPath]) => path !== previousPath)) {
      resultsRef.current = candidates;
      return resultsRef.current;
    }

    // Create new results for candidates that are new to this update cycle
    if (candidates.length > resultsRef.current.length) {
      resultsRef.current = [...resultsRef.current, ...candidates.slice(resultsRef.current.length)];
    }

    // Remove old extra results that have been removed from the candidates in this update cycle
    if (candidates.length < resultsRef.current.length) {
      resultsRef.current = resultsRef.current.slice(0, candidates.length);
    }

    // Detect changes in individual array entries
    let hasChanged = false;
    const newResults = resultsRef.current.map((result, index) => {
      const candidate = candidates[index] ?? _throw(new Error('Previous results and candidates must have the same length'));

      // justification: it's not possible to instruct the compiler that path is guarantied to be part of result/candidate without doing a lot of extra, slow processing
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (memoizedPaths.some((path) => get(result, path as any) !== get(candidate, path as any))) {
        hasChanged = true;
        return candidate;
      }

      return result;
    });

    if (hasChanged) {
      resultsRef.current = newResults;
    }

    return resultsRef.current;
  }, [memoizedPaths, previousPathsRef, values]);

  previousPathsRef.current = memoizedPaths;

  return results;
}
