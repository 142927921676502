import { useEffect } from 'react';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GuayLogo } from '../common/svg/GuayLogo';

export function AppUpdatePage() {
  const { t } = useTranslation('appUpdate');

  useEffect(() => {
    document.body.classList.add('disable-scroll');
    return () => document.body.classList.remove('disable-scroll');
  }, []);

  return (
    <Box
      sx={{
        height: '100dvh',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        backgroundImage: `url(/imgs/fullscreen-background-dim.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1.5rem',
          gap: '1.5rem',
          margin: '2.5rem',
          alignItems: 'center',
          textAlign: 'center',
        }}>
        <GuayLogo />
        <Typography>{t('updateInProgress')}</Typography>
        <CircularProgress />
      </Paper>
    </Box>
  );
}
