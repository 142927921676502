import { Box, InputAdornment, SelectChangeEvent, Typography } from '@mui/material';
import { ForwardedRef, forwardRef, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnitInput } from './UnitInput';
import { NumberInput } from './NumberInput';
import { Logger } from '../utils/logging';
import { _throw } from '../utils/_throw';
import { isLengthUnit, Length, LengthUnit, lengthUnits } from '../utils/dimensions/length';
import { DimensionMinMax } from '../utils/dimensions';
import { sequenceEqual } from '../utils/arrayUtils';

const logger = new Logger('LengthInput');

interface Props {
  value: Length | null;
  onChange: (length: Length | null) => void;
  enabledUnits?: readonly LengthUnit[];
  /** Defaults to 0. */
  min?: DimensionMinMax<Length>;
  max?: DimensionMinMax<Length>;
  /** Defaults to 1. */
  step?: number;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  className?: string;
  label?: ReactNode;
  'data-label-key'?: string;
}

export const LengthInput = forwardRef(function LengthInput(
  {
    value,
    onChange,
    enabledUnits = lengthUnits,
    min = 0,
    max,
    step = 1,
    required,
    disabled,
    error,
    className,
    label,
    'data-label-key': dataLabelKey,
  }: Props,
  ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>,
) {
  const defaultUnit = enabledUnits[0] ?? _throw('Empty enabledUnits. At least one unit must be enabled.');

  // Initialize lastValue to null to force unit validation (below) on first render if initial value isn't actually null
  const [lastValue, setLastValue] = useState<typeof value>(null);
  const [lastEnabledUnits, setLastEnabledUnits] = useState<typeof enabledUnits>(enabledUnits);

  // Initialize these states from the contents of lastValue (whatever it is) to make sure they're in sync
  const [scalar, setScalar] = useState<number | undefined>(lastValue?.scalar);
  const [unit, setUnit] = useState<LengthUnit>(lastValue ? lastValue.unit : defaultUnit);
  const [inchesScalar, setInchesScalar] = useState<number | undefined>(lastValue?.inchesScalar);

  const { t } = useTranslation('common');

  if (value !== lastValue || enabledUnits !== lastEnabledUnits) {
    setLastValue(value);
    setLastEnabledUnits(enabledUnits);

    // If the only change is that enabledUnits has a new reference but the same contents, don't do anything.
    // This prevents UI glitches where lengths in 'ftin' unit are normalized (i.e. 1 foot 12 inches is converted to 2
    // feet) while the input is focused, which can happen if the reference of enabledUnits changes for every render,
    // as without the following check, changing the reference of enabledUnits would trigger the state reset code below.
    // In other words, this check allows not memoizing the value of enabledUnits.
    if (value === lastValue && sequenceEqual(enabledUnits, lastEnabledUnits)) return;

    if (!value) {
      // If value is null, it can mean one of two things:
      // - if local scalars are all valid, then the value was set to null from outside this component: clear both scalars
      // - if at least one local scalar is invalid, then we consider it's the reason why the value became null: keep
      //   the scalars untouched so that the user can fix the missing / invalid one
      // In both scenarios, the unit is only reset if it isn't valid anymore.
      if (scalar != null && (unit !== 'ftin' || inchesScalar != null)) {
        setScalar(undefined);
        setInchesScalar(undefined);
      }
      if (!enabledUnits.includes(unit)) setUnit(defaultUnit);
    } else if (!enabledUnits.includes(value.unit)) {
      logger.error('Detected unit is not enabled', value.unit, enabledUnits);
      setScalar(undefined);
      setUnit(defaultUnit);
      setInchesScalar(undefined);
    } else {
      setScalar(value.scalar);
      setUnit(value.unit); // safe since the unit was already validated in the condition of the `if` statement above
      setInchesScalar(value.inchesScalar);
    }
  }

  // Update lastValue and call onChange() with the provided value if different from lastValue.
  // Note: this does NOT update scalar, unit, or inchesScalar, since doing so would risk clearing both scalars when only
  // one of them is invalid / missing.
  const handleChange = (newValue: Length | null) => {
    if (newValue === lastValue) return;
    setLastValue(newValue);
    onChange(newValue);
  };

  const handleInchesScalarChange = (newInchesScalar: number | null) => {
    const normalizedInchesScalar = newInchesScalar != null && Number.isFinite(newInchesScalar) ? newInchesScalar : undefined;
    setInchesScalar(normalizedInchesScalar);
    // If the current unit isn't 'ftin', there's nothing else to do, because a new inchesScalar value will only affect
    // the dispatched value when the unit is 'ftin'. If the current unit is 'ftin' but the current scalar is undefined,
    // the last dispatched value should be null, so no need to dispatch another null.
    if (unit === 'ftin' && scalar != null) {
      handleChange(normalizedInchesScalar == null ? null : new Length(scalar, unit, normalizedInchesScalar));
    }
  };

  const handleScalarChange = (newScalar: number | null) => {
    const normalizedScalar = newScalar != null && Number.isFinite(newScalar) ? newScalar : undefined;
    setScalar(normalizedScalar);
    // If the current unit is 'ftin' and the current inchesScalar is undefined, the last dispatched value should be
    // null, so no need to dispatch another null. Otherwise, dispatch a new value based on the new scalar, and the
    // current unit and inchesScalar.
    if (unit !== 'ftin' || inchesScalar != null) {
      handleChange(normalizedScalar == null ? null : new Length(normalizedScalar, unit, unit === 'ftin' ? inchesScalar : undefined));
    }
  };

  // If all scalars required for the current unit are valid, normalize the length according to min/max/step, update
  // local scalars, and call onChange() with the normalized value.
  const handleBlur = () => {
    if (scalar != null && (unit !== 'ftin' || inchesScalar != null)) {
      const length = new Length(scalar, unit, unit === 'ftin' ? inchesScalar : undefined).normalize(min, max, step);
      setScalar(length.scalar);
      setInchesScalar(length.inchesScalar);
      handleChange(length);
    }
  };

  return (
    <Box display='flex' gap={1} data-label-key={dataLabelKey}>
      {unit === 'ftin' && (
        <DualUnitAdditionalField
          scalar={scalar}
          onChange={handleScalarChange}
          onBlur={handleBlur}
          required={required}
          label={label}
          error={error}
          disabled={disabled}
        />
      )}
      <UnitInput<LengthUnit>
        ref={ref}
        scalar={unit === 'ftin' ? inchesScalar : scalar}
        // eslint-disable-next-line react/jsx-handler-names
        onScalarChange={unit === 'ftin' ? handleInchesScalarChange : handleScalarChange}
        unit={unit}
        supportedUnits={enabledUnits}
        onUnitChange={(event: SelectChangeEvent) => {
          const eventUnit = event.target.value;
          const newUnit = isLengthUnit(eventUnit) && enabledUnits.includes(eventUnit) ? eventUnit : defaultUnit;
          if (newUnit === unit) return;
          setUnit(newUnit);
          if (scalar != null) {
            const length = new Length(scalar, newUnit, newUnit === 'ftin' ? 0 : undefined).normalize(min, max, step);
            setScalar(length.scalar);
            setInchesScalar(length.inchesScalar);
            handleChange(length);
          }
        }}
        onBlur={handleBlur}
        error={error}
        disabled={disabled}
        required={required}
        renderMenuValue={(v) => t(`unit.length.short.${v}`)}
        renderAdornmentValue={(v) => (v === 'ftin' ? t('unit.length.short.in') : t(`unit.length.short.${v}`))}
        className={className}
        label={unit === 'ftin' ? '' : label}
      />
    </Box>
  );
});

interface DualUnitProps {
  scalar: number | undefined;
  label?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  onBlur: () => void;
  onChange: (value: number | null) => void;
}

const DualUnitAdditionalField = forwardRef(function DualUnitAdditionalField(
  { scalar, label, required, disabled, error, onBlur: handleBlur, onChange: handleChange }: DualUnitProps,
  ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>,
) {
  const { t } = useTranslation('common');

  return (
    <NumberInput
      ref={ref}
      data-testid='unitTextBox'
      value={scalar ?? null}
      label={label}
      required={required}
      disabled={disabled}
      error={error}
      onBlur={handleBlur}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <Typography>{t('unit.length.short.ft')}</Typography>
          </InputAdornment>
        ),
      }}
    />
  );
});
