import { DateTime, Duration } from 'luxon';
import { useCallback, useEffect, useState } from 'react';

/** Default update interval for the countdown (1 second). */
const defaultUpdateInterval = Duration.fromObject({ second: 1 });

/** The options for {@link useCountdown}. */
export interface UseCountdownOptions {
  /** Whether to clamp the countdown to zero when the deadline is reached. Defaults to `true`. */
  clampToZero?: boolean;
  /** The interval between countdown updates, expressed as either a {@link Duration} object or a number of milliseconds.
   * Defaults to {@link defaultUpdateInterval}. */
  updateInterval?: Duration<true> | number;
}

/** A hook that returns a {@link Duration} object representing the time remaining until the given deadline, or `null` if
 * the deadline is `null`.
 *
 * The countdown will update every `opts.updateInterval`, which defaults to 1 second, and the components calling this
 * hook will be re-rendered accordingly.
 *
 * The returned countdown will be clamped to zero when the deadline is reached if `opts.clampToZero` is `true`, which is
 * the default.
 *
 * @param deadline The deadline to count down to.
 * @param opts The options for the countdown.
 */
export function useCountdown(deadline: DateTime<true>, opts?: UseCountdownOptions): Duration<true>;
export function useCountdown(deadline: DateTime<true> | null, opts?: UseCountdownOptions): Duration<true> | null;
export function useCountdown(
  deadline: DateTime<true> | null,
  { clampToZero = true, updateInterval = defaultUpdateInterval }: UseCountdownOptions = {},
): Duration<true> | null {
  const getCountdown = useCallback(() => {
    const now = DateTime.now();
    return deadline && (clampToZero && now >= deadline ? Duration.fromMillis(0) : deadline.diff(now));
  }, [clampToZero, deadline]);

  const [countdown, setCountdown] = useState<Duration<true> | null>(() => getCountdown());
  const [previousGetCountdown, setPreviousGetCountdown] = useState(() => getCountdown);

  if (getCountdown !== previousGetCountdown) {
    setCountdown(() => getCountdown());
    setPreviousGetCountdown(() => getCountdown);
  }

  // Schedule the next countdown update as needed.
  useEffect(() => {
    if (!deadline || !countdown || (clampToZero && countdown.toMillis() <= 0)) return;
    const updateIntervalMillis = typeof updateInterval === 'number' ? updateInterval : updateInterval!.toMillis();
    const nextTick = deadline.minus(Math.floor(countdown.toMillis() / updateIntervalMillis) * updateIntervalMillis).plus(1);
    const nextTickTimeoutId = setTimeout(() => setCountdown(() => getCountdown()), nextTick.diffNow().toMillis());
    return () => clearTimeout(nextTickTimeoutId);
  }, [clampToZero, countdown, deadline, getCountdown, updateInterval]);

  return countdown;
}
