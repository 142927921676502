/**
 * @generated SignedSource<<76a0b14600cfebb6e8e12d4e3f27f7b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CraneSelectorFields_FavoriteConfigurationFragment$data = {
  readonly automaticConfiguration: {
    readonly matchingConfigurations: ReadonlyArray<{
      readonly eTag: string;
      readonly isFavorite: boolean;
    }>;
  };
  readonly " $fragmentType": "CraneSelectorFields_FavoriteConfigurationFragment";
};
export type CraneSelectorFields_FavoriteConfigurationFragment$key = {
  readonly " $data"?: CraneSelectorFields_FavoriteConfigurationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_FavoriteConfigurationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CraneSelectorFields_FavoriteConfigurationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomaticConfigurationInternal",
      "kind": "LinkedField",
      "name": "automaticConfiguration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AutomaticConfigurationInfo",
          "kind": "LinkedField",
          "name": "matchingConfigurations",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isFavorite",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "eTag",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};

(node as any).hash = "d12d7b4d69b4d4e3d061d540ace8d026";

export default node;
