/**
 * @generated SignedSource<<fda1a85d0d99b558a2717094f4c48112>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleEquipmentFields_BranchToWorksiteDistanceCalculatorFragment$data = {
  readonly equipment: {
    readonly " $fragmentSpreads": FragmentRefs<"SaleEquipmentFields_BranchToWorksiteDistanceFragment">;
  };
  readonly " $fragmentType": "SaleEquipmentFields_BranchToWorksiteDistanceCalculatorFragment";
};
export type SaleEquipmentFields_BranchToWorksiteDistanceCalculatorFragment$key = {
  readonly " $data"?: SaleEquipmentFields_BranchToWorksiteDistanceCalculatorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleEquipmentFields_BranchToWorksiteDistanceCalculatorFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleEquipmentFields_BranchToWorksiteDistanceCalculatorFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "equipment",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleEquipmentFields_BranchToWorksiteDistanceFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "c7fcdbec3a0b1ddde691a135d4ce3554";

export default node;
