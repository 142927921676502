/**
 * @generated SignedSource<<df1898307ad5e6e08b0a712b80bfb925>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CraneSelectorFields_Manual_JibLengthFragment$data = {
  readonly jibLength: string | null | undefined;
  readonly " $fragmentType": "CraneSelectorFields_Manual_JibLengthFragment";
};
export type CraneSelectorFields_Manual_JibLengthFragment$key = {
  readonly " $data"?: CraneSelectorFields_Manual_JibLengthFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_Manual_JibLengthFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CraneSelectorFields_Manual_JibLengthFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jibLength",
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "553808c91b76d048a013bc020b61b3b8";

export default node;
