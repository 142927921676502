const app = {
  title: 'Guay Sales',
  version: 'Version',
  changeLanguage: 'Français',
};

const auth = {
  logout: 'Log out',
  redirectToConnectionPage: 'You will be redirected to the connection page.',
  unauthorizedLogin: 'Unauthorized Login',
  unauthorizedLoginMessage: 'You cannot access this application with this email address',
};

const logoutModal = {
  title: 'Logout',
  confirmationText: 'Are you sure you want to log out?',
  confirmButton: 'Log out',
};

export const layout = {
  app,
  auth,
  logoutModal,
  sidebar: {
    billingCodeRules: 'Billing Code Rules',
    clients: 'Clients',
    configuration: 'Configuration',
    craneCharts: 'Crane Charts',
    crm: 'CRM',
    dashboard: 'Dashboard',
    opportunities: 'Opportunities',
    maintenance: 'Maintenance',
    quotes: 'Quotes',
    representatives: 'Representatives',
    sales: 'Sales',
    serviceCalls: 'Service Calls',
    workPlannings: 'Work Plannings',
    worksites: 'Worksites',
  },
  notification: {
    empty: 'No notifications',
    loadNext: 'View more',
    markAllRead: 'Mark All Read',
    modificationRequestedNotification: 'Modification request for service call #{{ friendlyId }}',
    quoteApprovalRequestedNotification: 'The quote #{{ friendlyId }} requires approval',
    quoteApprovedNotification: 'The quote #{{ friendlyId }} is now approved and awaiting for a client response',
    quoteChangesRequestedNotification: 'The quote #{{ friendlyId }} requires a review',
    title: 'Notifications',
  },
};
