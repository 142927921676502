import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useFieldAssignedClientRead } from './fields/ClientBaseFields';
import {
  useFieldArrivalDateRead,
  useFieldWorksiteOverridesAddressRead,
  useFieldWorksiteOverridesCityRead,
  useFieldWorksiteOverridesNameRead,
  useFieldWorksiteOverridesPostalCodeRead,
  useFieldWorksiteOverridesStateRead,
} from './fields/ProjectBaseFields';
import { useFieldDispatchBranchRead, useFieldNatureOfWorkRead, useFieldNatureOfWorkSubCategoryRead } from './fields/SaleProjectFields';
import { useFieldCraneSelectorModeRead, useFieldManualVehicleIdsRead } from './fields/CraneSelectorFields';
import { useJobKindRequiresEquipment } from './fields/SaleEquipmentFields';
import { useOutletContext } from 'react-router';
import { SaleDetailsContextValue } from './SaleDetailsContextValue';
import { useMissingCostsDependenciesFragment$key } from './__generated__/useMissingCostsDependenciesFragment.graphql';
import { useMemo } from 'react';
import { useFavoriteEquipment_Selected } from './useFavoriteEquipment';
import { useArrayLens } from '../common/hooks/useLens';
import { _never } from '../common/utils/_never';

/**
 * Returns a list of tuples of translation key strings & their namespace which correspond to the missing cost dependencies required to edit the
 * fields of the cost page of a Service Call or Quote. Returns null if all required dependencies are filled.
 *
 * @param $key
 */
export function useMissingCostsDependencies(
  $key: useMissingCostsDependenciesFragment$key | null | undefined,
): (readonly [string, { readonly ns: string }])[] | null {
  const { kind } = useOutletContext<SaleDetailsContextValue>();

  const $data = useFragment(
    graphql`
      fragment useMissingCostsDependenciesFragment on ISale {
        clientBase {
          ...ClientBaseFields_useFieldAssignedClientFragment
        }
        projectBase {
          ...ProjectBaseFields_ArrivalDateReadFragment @arguments(isCopy: false)
          assignedWorksiteInfo {
            ...ProjectBaseFields_WorksiteOverridesName_ReadFragment
            location {
              ...ProjectBaseFields_WorksiteOverridesAddressReadFragment
              structuredAddress {
                ...ProjectBaseFields_WorksiteOverridesCityFragment
                ...ProjectBaseFields_WorksiteOverridesStateFragment
                ...ProjectBaseFields_WorksiteOverridesPostalCodeFragment
              }
            }
          }
        }
        project {
          ...SaleProjectFields_NatureOfWorkFragment
          ...SaleProjectFields_NatureOfWorkSubCategoryFragment
          ...SaleProjectFields_DispatchBranchFragment
        }
        equipmentBase {
          craneSelector {
            ...CraneSelectorFields_CraneSelectorModeFragment
            ...useFavoriteEquipmentSelectedFragment
            manualConfiguration {
              userInput {
                ...CraneSelectorFields_VehicleIdsFragment
              }
            }
          }
        }
      }
    `,
    $key,
  );

  const { assignedClient } = useFieldAssignedClientRead($data?.clientBase);
  const { worksiteOverridesName } = useFieldWorksiteOverridesNameRead($data?.projectBase.assignedWorksiteInfo);
  const { arrivalDate } = useFieldArrivalDateRead($data?.projectBase);
  const { worksiteOverridesAddress } = useFieldWorksiteOverridesAddressRead($data?.projectBase.assignedWorksiteInfo.location);
  const { worksiteOverridesCity } = useFieldWorksiteOverridesCityRead($data?.projectBase.assignedWorksiteInfo.location?.structuredAddress);
  const { worksiteOverridesState } = useFieldWorksiteOverridesStateRead(
    $data?.projectBase.assignedWorksiteInfo.location?.structuredAddress,
  );
  const { worksiteOverridesPostalCode } = useFieldWorksiteOverridesPostalCodeRead(
    $data?.projectBase.assignedWorksiteInfo.location?.structuredAddress,
  );
  const { natureOfWork } = useFieldNatureOfWorkRead($data?.project);
  const { natureOfWorkSubCategory } = useFieldNatureOfWorkSubCategoryRead($data?.project);
  const { craneSelectorMode } = useFieldCraneSelectorModeRead($data?.equipmentBase.craneSelector);
  const kindRequiresEquipment = useJobKindRequiresEquipment(kind);
  const { equipments } = useFavoriteEquipment_Selected($data?.equipmentBase.craneSelector);
  const boomConfigurationIds = useArrayLens(equipments, 'boomConfigurationId', 'active');
  const { dispatchBranch } = useFieldDispatchBranchRead($data?.project);
  const { vehicleIds } = useFieldManualVehicleIdsRead($data?.equipmentBase.craneSelector.manualConfiguration.userInput);

  const noFavoriteEquipmentSelected = (() => {
    switch (craneSelectorMode) {
      case 'lifts':
        return [['field.equipment.craneSelectorConfiguration', { ns: 'craneSelector' }] as const];
      case 'manual':
        return [['field.equipment.manualBoomConfiguration', { ns: 'craneSelector' }] as const];
      default:
        _never(craneSelectorMode);
    }
  })();

  return useMemo(() => {
    const missingDependencies = [
      ...(arrivalDate ? [] : [['field.project.date.arrival', { ns: 'serviceCall' }] as const]),
      ...(assignedClient ? [] : [['details.section.client', { ns: 'serviceCall' }] as const]),
      ...(dispatchBranch ? [] : [['field.project.branch.dispatchBranch', { ns: 'serviceCall' }] as const]),
      ...(kindRequiresEquipment && boomConfigurationIds.some((e) => e.boomConfigurationId == null) ? noFavoriteEquipmentSelected : []),
      ...(natureOfWork ? [] : [['field.project.work.natureOfWork', { ns: 'serviceCall' }] as const]),
      ...(natureOfWorkSubCategory ? [] : [['field.project.work.natureOfWorkSubCategory', { ns: 'serviceCall' }] as const]),
      ...(worksiteOverridesAddress ? [] : [['field.address', { ns: 'worksite' }] as const]),
      ...(worksiteOverridesCity ? [] : [['field.city', { ns: 'worksite' }] as const]),
      ...(worksiteOverridesState ? [] : [['field.province', { ns: 'worksite' }] as const]),
      ...(worksiteOverridesPostalCode ? [] : [['field.postalCode', { ns: 'worksite' }] as const]),
      ...(worksiteOverridesName ? [] : [['field.name', { ns: 'worksite' }] as const]),
      ...((craneSelectorMode === 'manual' && vehicleIds != null && !!vehicleIds.length) || craneSelectorMode !== 'manual'
        ? []
        : [['field.equipment.model', { ns: 'serviceCall' }] as const]),
    ];

    return missingDependencies.length > 0 ? missingDependencies : null;
  }, [
    arrivalDate,
    assignedClient,
    boomConfigurationIds,
    craneSelectorMode,
    dispatchBranch,
    kindRequiresEquipment,
    natureOfWork,
    natureOfWorkSubCategory,
    noFavoriteEquipmentSelected,
    vehicleIds,
    worksiteOverridesAddress,
    worksiteOverridesCity,
    worksiteOverridesName,
    worksiteOverridesPostalCode,
    worksiteOverridesState,
  ]);
}
