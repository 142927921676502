/**
 * @generated SignedSource<<45251f558f47963c343fdf1218254608>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as vehicleIdLabelResolverType } from "../../__resolvers__/VehicleIdLabelResolver";
// Type assertion validating that `vehicleIdLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(vehicleIdLabelResolverType satisfies (
  rootKey: VehicleIdLabelResolver$key,
) => string | null | undefined);
export type useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromInitialValuesFragment$data = {
  readonly additionalConfigurations: ReadonlyArray<{
    readonly boomConfiguration: {
      readonly boomLength: string;
      readonly counterweight: string;
      readonly id: string;
      readonly jibLength: string;
      readonly vehicleIds: ReadonlyArray<{
        readonly key: string;
        readonly label: string | null | undefined;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_extraDataFragment">;
    } | null | undefined;
    readonly deletedAt: string | null | undefined;
  }>;
  readonly userInput: {
    readonly boomConfiguration: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_extraDataFragment">;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_Manual_BoomLengthFragment" | "CraneSelectorFields_Manual_CounterweightFragment" | "CraneSelectorFields_Manual_JibLengthFragment" | "CraneSelectorFields_Manual_MaxWeightFragment" | "CraneSelectorFields_Manual_OffsetAngleFragment" | "CraneSelectorFields_Manual_RadiusFragment" | "CraneSelectorFields_VehicleIdsFragment">;
  };
  readonly " $fragmentType": "useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromInitialValuesFragment";
};
export type useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromInitialValuesFragment$key = {
  readonly " $data"?: useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromInitialValuesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromInitialValuesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "useFavoriteEquipment_extraDataFragment"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromInitialValuesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ManualConfigurationInfo",
      "kind": "LinkedField",
      "name": "userInput",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneSelectorFields_Manual_BoomLengthFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneSelectorFields_Manual_JibLengthFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneSelectorFields_Manual_OffsetAngleFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneSelectorFields_Manual_RadiusFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneSelectorFields_Manual_MaxWeightFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneSelectorFields_Manual_CounterweightFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneSelectorFields_VehicleIdsFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BoomConfigurationSnapshot",
          "kind": "LinkedField",
          "name": "boomConfiguration",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdditionalConfigurationInfo",
      "kind": "LinkedField",
      "name": "additionalConfigurations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deletedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BoomConfigurationSnapshot",
          "kind": "LinkedField",
          "name": "boomConfiguration",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "boomLength",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "jibLength",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "counterweight",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "VehicleId",
              "kind": "LinkedField",
              "name": "vehicleIds",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "key",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "fragment": {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "VehicleIdLabelResolver"
                  },
                  "kind": "RelayResolver",
                  "name": "label",
                  "resolverModule": require('./../../__resolvers__/VehicleIdLabelResolver').label,
                  "path": "additionalConfigurations.boomConfiguration.vehicleIds.label"
                }
              ],
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInternal",
  "abstractKey": null
};
})();

(node as any).hash = "3c8d19726e6b3eeb2e27853a53637d98";

export default node;
