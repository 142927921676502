/**
 * @generated SignedSource<<de071a3637d5f22e6bd3c7e25caf4b93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientBaseFields_ClientOverridesPostalCodeFragment$data = {
  readonly value: {
    readonly postalCode: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ClientBaseFields_ClientOverridesPostalCodeFragment";
};
export type ClientBaseFields_ClientOverridesPostalCodeFragment$key = {
  readonly " $data"?: ClientBaseFields_ClientOverridesPostalCodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_ClientOverridesPostalCodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientBaseFields_ClientOverridesPostalCodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StructuredAddressData",
      "kind": "LinkedField",
      "name": "value",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StructuredAddressResult",
  "abstractKey": null
};

(node as any).hash = "7cd0587320c489b755fdb7158bd8e046";

export default node;
