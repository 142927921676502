/**
 * @generated SignedSource<<c95c1d85af94b00aaf7b18c0e6447b3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as equipmentKindLookupLabelResolverType } from "../../../__resolvers__/EquipmentKindLookupLabelResolver";
// Type assertion validating that `equipmentKindLookupLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(equipmentKindLookupLabelResolverType satisfies (
  rootKey: EquipmentKindLookupLabelResolver$key,
) => Record<string, string> | null | undefined);
export type CraneSelectorFields_EquipmentKindFragment$data = {
  readonly equipmentKind: {
    readonly code: number;
    readonly id: string;
    readonly label: Record<string, string> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "CraneSelectorFields_EquipmentKindFragment";
};
export type CraneSelectorFields_EquipmentKindFragment$key = {
  readonly " $data"?: CraneSelectorFields_EquipmentKindFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_EquipmentKindFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CraneSelectorFields_EquipmentKindFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EquipmentKindLookup",
      "kind": "LinkedField",
      "name": "equipmentKind",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EquipmentKindLookupLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../../__resolvers__/EquipmentKindLookupLabelResolver').label,
          "path": "equipmentKind.label"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "a3357573ba386fde37196697ffe66dcb";

export default node;
