/**
 * @generated SignedSource<<26a99e13c723b711b2b6843d77c3135c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignedWorksite_UseHasWorksiteInfoFragment$data = {
  readonly contact: string | null | undefined;
  readonly location: {
    readonly structuredAddress: {
      readonly value: {
        readonly apartment: string | null | undefined;
        readonly city: string | null | undefined;
        readonly coordinates: {
          readonly __typename: "Coordinates";
        } | null | undefined;
        readonly postalCode: string | null | undefined;
        readonly state: string | null | undefined;
        readonly streetAddress: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly name: string | null | undefined;
  readonly phoneNumber: string | null | undefined;
  readonly requirementIds: ReadonlyArray<string>;
  readonly " $fragmentType": "AssignedWorksite_UseHasWorksiteInfoFragment";
};
export type AssignedWorksite_UseHasWorksiteInfoFragment$key = {
  readonly " $data"?: AssignedWorksite_UseHasWorksiteInfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_UseHasWorksiteInfoFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignedWorksite_UseHasWorksiteInfoFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StructuredAddressResult",
          "kind": "LinkedField",
          "name": "structuredAddress",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StructuredAddressData",
              "kind": "LinkedField",
              "name": "value",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "streetAddress",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "apartment",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Coordinates",
                  "kind": "LinkedField",
                  "name": "coordinates",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "city",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "state",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "postalCode",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requirementIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contact",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    }
  ],
  "type": "WorksiteInfoInternal",
  "abstractKey": null
};

(node as any).hash = "b43c8c5865893d3607f34a702bec774a";

export default node;
