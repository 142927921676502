/**
 * @generated SignedSource<<a2cc1afd014e89b33a341162c376389b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_WorksiteOverridesAddress_ClientFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteAddressInput_ClientFragment">;
  readonly " $fragmentType": "ProjectBaseFields_WorksiteOverridesAddress_ClientFragment";
};
export type ProjectBaseFields_WorksiteOverridesAddress_ClientFragment$key = {
  readonly " $data"?: ProjectBaseFields_WorksiteOverridesAddress_ClientFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesAddress_ClientFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_WorksiteOverridesAddress_ClientFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteAddressInput_ClientFragment"
    }
  ],
  "type": "ClientInternalBase",
  "abstractKey": null
};

(node as any).hash = "b133f0744395d3bb34e95b086f0ef7a6";

export default node;
