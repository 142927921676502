/**
 * @generated SignedSource<<c0f5efcd663d4b62fb5596af1cdbd62c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_WorksiteAddressInput_ClientFragment$data = {
  readonly assignedClient: {
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_CopyClientAddressButton_ClientFragment">;
  } | null | undefined;
  readonly assignedClientInfo: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_ClientOverrides_IsDirectSalesFragment" | "ProjectBaseFields_CopyClientAddressButton_ClientFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_useFieldAssignedClientFragment">;
  readonly " $fragmentType": "ProjectBaseFields_WorksiteAddressInput_ClientFragment";
};
export type ProjectBaseFields_WorksiteAddressInput_ClientFragment$key = {
  readonly " $data"?: ProjectBaseFields_WorksiteAddressInput_ClientFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteAddressInput_ClientFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ProjectBaseFields_CopyClientAddressButton_ClientFragment"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_WorksiteAddressInput_ClientFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientBaseFields_useFieldAssignedClientFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientInfoInternal",
      "kind": "LinkedField",
      "name": "assignedClientInfo",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientBaseFields_ClientOverrides_IsDirectSalesFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Client",
      "kind": "LinkedField",
      "name": "assignedClient",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ClientInternalBase",
  "abstractKey": null
};
})();

(node as any).hash = "1004021bc392142131a6b35b27c1d4c2";

export default node;
