import * as Sentry from '@sentry/react';
import { useTheme } from '@mui/material';
import ErrorFallback from './ErrorFallback';
import { DetailsLayout, SidebarContentProps } from './Layouts';
import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationMenu } from './SidebarDrawer';
import { errorBoundaryBeforeCaptureFn } from '../AppErrorBoundary';
import { Logger } from '../common/utils/logging';
import { useRetryCallback } from './useRetryCallback';

const logger = new Logger('DetailsPageErrorBoundary');

export function DetailsPageErrorBoundary({ heading, flagName, children }: { heading: string; flagName: string; children: ReactNode }) {
  const theme = useTheme();
  const { t } = useTranslation('common');
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={null} />, []);
  const handleRetry = useRetryCallback();

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => (
        <DetailsLayout heading={heading} flagName={flagName} sidebarProvider={sidebar} $key={null}>
          <ErrorFallback
            error={error}
            subtitleMessage={t('requestSupport')}
            onButtonClick={handleRetry(resetError)}
            buttonMessage={t('button.retry')}
          />
        </DetailsLayout>
      )}
      beforeCapture={(scope) => errorBoundaryBeforeCaptureFn(scope, theme)}
      onError={(...args) => {
        if (args[0] instanceof AggregateError) {
          logger.group(`AggregateError`);
          logger.error(args[0]);
          for (const error of args[0].errors) {
            logger.error(error);
          }
          logger.groupEnd();
        } else {
          logger.error(...args);
        }
      }}>
      {children}
    </Sentry.ErrorBoundary>
  );
}
