import { ArrayKeyType, KeyType, KeyTypeData } from 'react-relay/relay-hooks/helpers';
import { GraphQLTaggedNode } from 'relay-runtime';
import { useFragment } from 'react-relay';
import { useMemo } from 'react';

/**
 * The type to use for the keys of the fragment.
 * PluralKey<MyFragment$key>
 */
export type PluralKey<TKey> =
  TKey extends ReadonlyArray<infer U>
    ? ReadonlyArray<(U extends KeyType<ReadonlyArray<infer V>> ? KeyType<ReadonlyArray<V | null | undefined>> : never) | null | undefined>
    : never;

export type PluralData<TKey extends ArrayKeyType<TData>, TData = unknown> = ReadonlyArray<
  NonNullable<KeyTypeData<NonNullable<TKey[number]>>>[number] | null | undefined
>;

/**
 * Allows using a fragment with an array of nullable keys, returning an array of nullable data
 * that matches the array of keys.
 *
 * @param fragmentInput - The GraphQL fragment to use.
 * @param fragmentRef - An array of nullable keys for the fragment.
 * @returns An array of nullable data corresponding to the keys.
 *
 * Example:
 * If you pass [null, key1, key2, undefined] as fragmentRef, the result will be [null, data1, data2, null]
 */
export function useFragmentPlural<TKey extends ArrayKeyType>(fragmentInput: GraphQLTaggedNode, fragmentRef: TKey): PluralData<TKey>;
/**
 * Allows using a fragment with an array of nullable keys, returning an array of nullable data
 * that matches the array of keys.
 *
 * @param fragmentInput - The GraphQL fragment to use.
 * @param fragmentRef - An array of nullable keys for the fragment, or null/undefined.
 * @returns An array of nullable data corresponding to the keys, or null/undefined if fragmentRef is null/undefined.
 *
 * Example:
 * If you pass [null, key1, key2, undefined] as fragmentRef, the result will be [null, data1, data2, null]
 */
export function useFragmentPlural<TKey extends ArrayKeyType>(
  fragmentInput: GraphQLTaggedNode,
  fragmentRef: TKey | null | undefined,
): PluralData<TKey> | null | undefined;
/**
 * Allows using a fragment with an array of nullable keys, returning an array of nullable data
 * that matches the array of keys.
 *
 * @param fragmentInput - The GraphQL fragment to use.
 * @param fragmentRef - An array of nullable keys for the fragment, or null/undefined.
 * @returns An array of nullable data corresponding to the keys, or null/undefined if fragmentRef is null/undefined.
 *
 * Example:
 * If you pass [null, key1, key2, undefined] as fragmentRef, the result will be [null, data1, data2, null]
 */
export function useFragmentPlural<TKey extends ArrayKeyType>(
  fragmentInput: GraphQLTaggedNode,
  fragmentRef: TKey | null | undefined,
): PluralData<TKey> | null | undefined {
  const clean$keys = useMemo(() => fragmentRef?.filter((key) => key != null), [fragmentRef]);
  const $data = useFragment(fragmentInput, clean$keys);

  return useMemo(() => {
    if ($data == null || fragmentRef == null) return $data;
    let nextCleanIndex = 0;
    return fragmentRef.map((x) => (x == null ? x : $data[nextCleanIndex++]));
  }, [$data, fragmentRef]);
}
