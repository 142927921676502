import { useAppUpdate } from './AppUpdate';
import { useCountdown } from '../common/hooks/useCountdown';
import { Duration } from 'luxon';

const ONE_MINUTE = Duration.fromObject({ minute: 1 });

/** A hook that returns a formatted countdown string representing the time remaining until the app's pending update
 * installation deadline, or `null` if there are no pending updates. Based on {@link useCountdown}. */
export function useAppUpdateDeadlineCountdown(): string | null {
  const { pendingUpdateDeadline } = useAppUpdate();
  const countdown = useCountdown(pendingUpdateDeadline);
  return countdown && countdown.toFormat(countdown < ONE_MINUTE ? 'h:mm:ss' : 'h:mm');
}
