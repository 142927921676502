import { StrictMode, useEffect, useRef, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';
import { useFlag, useUnleashContext } from '@unleash/proxy-client-react';
import { GlobalLogLevel, Logger } from './common/utils/logging';
import './fonts.css';
import { EmployeeNotFoundErrorBoundary } from './auth/EmployeeNotFoundErrorBoundary';
import { EmptyLayout } from './layout/Layouts';
import { styled, Typography } from '@mui/material';
import { AppErrorBoundary } from './AppErrorBoundary';
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { InteractionStatus } from '@azure/msal-browser';
import { useSticky } from './common/hooks/useSticky';
import { loginRequest } from './authConfig';
import { appSharedStateContext } from './AppSharedState';
import { SharedStateStoreProvider } from './common/utils/sharedState';
import { findUserEmail } from './common/utils/findUserEmail';
import { setUser as setSentryUser } from '@sentry/react';
import { MaintenanceFallback } from './Maintenance';
import { useMaintenanceMode } from './useMaintenanceMode';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { useEffectEvent } from './common/utils/effectUtils';
import { AppUpdate } from './appUpdate/AppUpdate';
import { AppUpdateSnackbarHandler } from './appUpdate/AppUpdateSnackbarHandler';

export function App() {
  return (
    <AppRemoteVerboseLogging>
      <AppUpdate>
        <StrictMode>
          <AppErrorBoundary>
            <AuthenticatedApp>
              <AppContent />
            </AuthenticatedApp>
          </AppErrorBoundary>
        </StrictMode>
      </AppUpdate>
    </AppRemoteVerboseLogging>
  );
}

function AppRemoteVerboseLogging({ children }: { children: JSX.Element }) {
  const isEnabled = useFlag('app_verbose_logging');

  // All loggers from our logging module monitor this value.
  (window as GlobalLogLevel).LOG_LEVEL = isEnabled ? 'debug' : undefined;

  return children;
}

const msalLogger = new Logger('MSAL');
const unleashLogger = new Logger('Unleash');
function AuthenticatedApp({ children }: { children: JSX.Element }) {
  const { instance, inProgress } = useMsal();

  // Setup logger for msal events
  useEffect(() => {
    const callbackId = instance.addEventCallback((message) => {
      if (message.error) {
        msalLogger.error(message.eventType, message.error, message);
      } else if (message.interactionType !== 'silent') {
        msalLogger.info(message.eventType, message);
      }
    });
    return () => (callbackId ? instance.removeEventCallback(callbackId) : undefined);
  }, [instance]);

  const isAuthenticated = useIsAuthenticated();

  // Since there's a chance that we're already authenticated when this component mounts, we could flicker the children
  // in and out on first render before the useEffect has a chance to set the state to true. We prevent this by
  // preemptively guessing the correct state.
  const [unleashUpdateInProgress, setUnleashUpdateInProgress] = useState(isAuthenticated);
  const updateUnleashContext = useUnleashContext();

  const setContexts = useEffectEvent(() => {
    const account = instance.getAllAccounts()[0];
    const userEmail = findUserEmail(account);

    setSentryUser({ email: userEmail });

    setUnleashUpdateInProgress(true);
    updateUnleashContext({ userId: userEmail }).then(
      () => {
        unleashLogger.info('Unleash updated with userId', userEmail);
        setUnleashUpdateInProgress(false);
      },
      (e) => {
        unleashLogger.error('Failed to update context to userId', userEmail, e);
        setUnleashUpdateInProgress(false);
      },
    );
  });
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    setContexts();
  }, [isAuthenticated, setContexts]);

  // HACK: Due to msal's event base architecture, attempting to use the account too quickly after a login interaction
  //  resolves to a 'none' state causes the acquireAccessToken:silent event to fail and trigger an
  //  acquireAccessToken:redirect event instead. Delaying the transition to the 'none' state in our app workaround this
  //  issue.
  const inProgressSticky = useSticky(inProgress, 50, (v) => v !== 'none');

  // If an interaction is in progress, we wait for it to resolve before greeting our users with the app's content.
  if (inProgressSticky !== InteractionStatus.None || unleashUpdateInProgress) {
    // TODO: We might want to handle some interactions differently as to avoid flickering the UI if the user is already authenticated.
    //  For instance, maybe 'acquireToken' triggers in silent mode if a new token is actually required.
    //  However, it isn't evident which ones would require this special treatment.
    return <EmptyLayout />;
  }

  // At this point, if we aren't authenticated, it is safe to assume that the user needs to be redirected to the
  // authentication page.
  if (!isAuthenticated) {
    return <AppRedirectToConnectionPage />;
  }

  // We have an account. The id token and access token are cached. Render the app!
  return children;
}

function AppRedirectToConnectionPage() {
  const { instance } = useMsal();

  const { t } = useTranslation('layout');

  // HACK: This hack is used to prevent triggering the loginRedirect call twice in strict mode.
  // There is a way with msal to know if an "interaction" is ongoing, but it's not yet up to date
  // during the strict mode rerender, so we need to track the process ourselves.
  const strictModeHackRef = useRef<unknown>();
  useEffect(() => {
    if (strictModeHackRef.current) {
      return;
    }

    strictModeHackRef.current = instance.loginRedirect(loginRequest);
  }, [instance]);

  return (
    <EmptyLayout>
      <Typography>{t('auth.redirectToConnectionPage')}</Typography>
    </EmptyLayout>
  );
}

function AppContent() {
  const maintenanceMode = useMaintenanceMode();

  if (maintenanceMode) return <MaintenanceFallback />;

  return (
    <EmployeeNotFoundErrorBoundary>
      <DndProvider options={HTML5toTouch}>
        <SharedStateStoreProvider context={appSharedStateContext}>
          <SnackbarProvider
            hideIconVariant
            Components={{
              default: StyledMaterialDesignContent,
              success: StyledMaterialDesignContent,
              warning: StyledMaterialDesignContent,
              error: StyledMaterialDesignContent,
              info: StyledMaterialDesignContent,
            }}>
            <AppUpdateSnackbarHandler />
            <Outlet />
          </SnackbarProvider>
        </SharedStateStoreProvider>
      </DndProvider>
    </EmployeeNotFoundErrorBoundary>
  );
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  '&.notistack-MuiContent-default': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
}));
