import { Svg } from '../components/Svg';

export function Weight() {
  return (
    <Svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17 6H15V5C15 2.5 13.11 2 12 2C10.89 2 9 2.5 9 5V6H7C5.89 6 5 6 4.5 8L2 19C2 20.11 2.89 21 4 21H20C21.11 21 22 20.11 22 19L19.5 8C19 6 18.11 6 17 6ZM13.5 5C13.5 5.82843 12.8284 6.5 12 6.5C11.1716 6.5 10.5 5.82843 10.5 5C10.5 4.17157 11.1716 3.5 12 3.5C12.8284 3.5 13.5 4.17157 13.5 5Z'
        fill='black'
        fillOpacity='0.54'
      />
    </Svg>
  );
}

export function WeightOperated() {
  return (
    <Svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.1818 11L22 19C22 20.11 21.11 21 20 21H4C2.89 21 2 20.11 2 19L4.5 8C5 6 5.89 6 7 6H9V5C9 2.5 10.89 2 12 2C13.11 2 15 2.5 15 5V6H15.6098C13.6985 6.45778 11.5769 7.54341 11.5769 9.25V11H20.1818ZM12 6.5C12.8284 6.5 13.5 5.82843 13.5 5C13.5 4.17157 12.8284 3.5 12 3.5C11.1716 3.5 10.5 4.17157 10.5 5C10.5 5.82843 11.1716 6.5 12 6.5Z'
        fill='black'
        fillOpacity='0.54'
      />
      <path
        d='M17.5 5.5C18.7431 5.5 19.75 4.49312 19.75 3.25C19.75 2.00687 18.7431 1 17.5 1C16.2569 1 15.25 2.00687 15.25 3.25C15.25 4.49312 16.2569 5.5 17.5 5.5ZM17.5 6.625C15.9981 6.625 13 7.37875 13 8.875V10H22V8.875C22 7.37875 19.0019 6.625 17.5 6.625Z'
        fill='black'
        fillOpacity='0.54'
      />
    </Svg>
  );
}
