/**
 * @generated SignedSource<<791592e2cdedee8975932bcd9bdf1e57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignedWorksite_useUpdateWorksite_ClientFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesAddress_ClientFragment" | "ProjectBaseFields_WorksiteOverridesName_ClientFragment">;
  readonly " $fragmentType": "AssignedWorksite_useUpdateWorksite_ClientFragment";
};
export type AssignedWorksite_useUpdateWorksite_ClientFragment$key = {
  readonly " $data"?: AssignedWorksite_useUpdateWorksite_ClientFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_useUpdateWorksite_ClientFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignedWorksite_useUpdateWorksite_ClientFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverridesName_ClientFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverridesAddress_ClientFragment"
    }
  ],
  "type": "ClientInternalBase",
  "abstractKey": null
};

(node as any).hash = "1ed5f4bc6d7e17c523f51cb5d5408d50";

export default node;
