/**
 * @generated SignedSource<<149b17d6ce34f942bd7037adb2e2b271>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_WorksiteAddressInputFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_CopyClientAddressButtonFragment" | "ProjectBaseFields_QueriedCopyClientAddressButtonFragment">;
  readonly " $fragmentType": "ProjectBaseFields_WorksiteAddressInputFragment";
};
export type ProjectBaseFields_WorksiteAddressInputFragment$key = {
  readonly " $data"?: ProjectBaseFields_WorksiteAddressInputFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteAddressInputFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_WorksiteAddressInputFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_QueriedCopyClientAddressButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_CopyClientAddressButtonFragment"
    }
  ],
  "type": "StructuredAddressResult",
  "abstractKey": null
};

(node as any).hash = "9a5333c6a05655246a75ddc37e36bbb5";

export default node;
