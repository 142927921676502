/**
 * @generated SignedSource<<582110cb5f9c6addd5cc08ff3f819473>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useFavoriteEquipment_FavoriteEquipmentProviderFragment$data = {
  readonly automaticConfiguration: {
    readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromInitialValuesFragment">;
  };
  readonly manualConfiguration: {
    readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromInitialValuesFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipmentFragment">;
  readonly " $fragmentType": "useFavoriteEquipment_FavoriteEquipmentProviderFragment";
};
export type useFavoriteEquipment_FavoriteEquipmentProviderFragment$key = {
  readonly " $data"?: useFavoriteEquipment_FavoriteEquipmentProviderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_FavoriteEquipmentProviderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useFavoriteEquipment_FavoriteEquipmentProviderFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFavoriteEquipmentFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomaticConfigurationInternal",
      "kind": "LinkedField",
      "name": "automaticConfiguration",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromInitialValuesFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ManualConfigurationInternal",
      "kind": "LinkedField",
      "name": "manualConfiguration",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromInitialValuesFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};

(node as any).hash = "7e50c792f7e3dfc0765e8f8ff9dac74d";

export default node;
