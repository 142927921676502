import {
  createFieldKey,
  SetValueFn,
  useField,
  useFieldErrorsFirstMessage,
  useFieldHasErrors,
  useFieldIsDirty,
  useFieldMapper,
  useFieldSetter,
  useFieldValidation,
  useFieldValue,
} from '../../common/utils/forms';
import { DataID, useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useCallback, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { Chip, IconButton, TextField } from '@mui/material';
import { MaskInput } from '../../common/components/MaskInput';
import {
  ClientRequirementAutocomplete,
  ForwardClientRequirementAutocompleteProps,
} from '../../common/components/ClientRequirementAutocomplete';
import { exclude } from '../../common/utils/arrayUtils';
import InfoIcon from '@mui/icons-material/Info';
import { RequirementsDialog } from '../../common/dialogs/RequirementsDialog';
import { ClientBaseFields_ClientOverrides_IsDirectSalesFragment$key } from './__generated__/ClientBaseFields_ClientOverrides_IsDirectSalesFragment.graphql';
import { ClientBaseFields_ClientOverrides_PhoneNumberFragment$key } from './__generated__/ClientBaseFields_ClientOverrides_PhoneNumberFragment.graphql';
import { ClientBaseFields_RequirementsFragment$key } from './__generated__/ClientBaseFields_RequirementsFragment.graphql';
import { jobStageBaseFormContext } from '../JobStageBaseFields';
import { useSuggestions } from '../useSuggestions';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { SuggestionPromptInput } from '../../common/components/__generated__/SuggestionsFakeQuery.graphql';
import { ClientBaseFields_ClientRequirementsSuggestionsFragment$key } from './__generated__/ClientBaseFields_ClientRequirementsSuggestionsFragment.graphql';
import { ClientBaseFields_ClientOverrides_LanguageCodeFragment$key } from './__generated__/ClientBaseFields_ClientOverrides_LanguageCodeFragment.graphql';
import { SelectPicker } from '../../common/components/SelectPicker';
import { ConfirmationDialog } from '../../common/dialogs/ConfirmationDialog';
import { SalesDetails_useBillingCodeSearchInput_ClientFragment$key } from '../__generated__/SalesDetails_useBillingCodeSearchInput_ClientFragment.graphql';
import { castClientLanguage, ClientLanguage, clientLanguages } from '../../__enums__/ClientLanguage';
import { _throw } from '../../common/utils/_throw';
import { ClientBaseFields_useFieldAssignedClientFragment$key } from './__generated__/ClientBaseFields_useFieldAssignedClientFragment.graphql';
import { ForwardRepresentativeAutocompleteProps, RepresentativeAutocomplete } from '../../common/components/RepresentativeAutocomplete';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { InactiveStartAdornment } from '../../common/InactiveStartAdornment';
import { ClientBaseFields_RepresentativeFragment$key } from './__generated__/ClientBaseFields_RepresentativeFragment.graphql';
import { ClientBaseFields_ClientRepresentativeSuggestedFragment$key } from './__generated__/ClientBaseFields_ClientRepresentativeSuggestedFragment.graphql';
import { QuoteKind } from '../../__enums__/QuoteKind';
import { RequiredForInputLabel, RequiredForStatus } from '../RequiredForJobStatus';
import { JobStage } from '../jobStage';
import { MapboxSearchInput, MapboxSearchInputResponse } from '../../common/components/Mapbox';
import { SearchBoxSuggestion } from '@mapbox/search-js-core';
import { Coordinates } from '../../common/types/coordinates';
import { ClientBaseFields_ClientOverridesCoordinatesFragment$key } from './__generated__/ClientBaseFields_ClientOverridesCoordinatesFragment.graphql';
import { ClientBaseFields_ClientOverridesAddressReadFragment$key } from './__generated__/ClientBaseFields_ClientOverridesAddressReadFragment.graphql';
import { ClientBaseFields_ClientOverridesAddressFragment$key } from './__generated__/ClientBaseFields_ClientOverridesAddressFragment.graphql';
import { ClientBaseFields_ClientOverridesPostalCodeFragment$key } from './__generated__/ClientBaseFields_ClientOverridesPostalCodeFragment.graphql';
import { ClientBaseFields_ClientOverridesApartmentFragment$key } from './__generated__/ClientBaseFields_ClientOverridesApartmentFragment.graphql';
import { ClientBaseFields_ClientOverridesCityFragment$key } from './__generated__/ClientBaseFields_ClientOverridesCityFragment.graphql';
import { ClientBaseFields_ClientOverridesStateFragment$key } from './__generated__/ClientBaseFields_ClientOverridesStateFragment.graphql';
import { ClientBaseFields_ClientOverridesNameFragment$key } from './__generated__/ClientBaseFields_ClientOverridesNameFragment.graphql';
import { ClientBaseFields_ClientOverridesName_ReadFragment$key } from './__generated__/ClientBaseFields_ClientOverridesName_ReadFragment.graphql';

export type FieldAssignedClient = {
  id: DataID;
  etag: string;
  useBillingCodeSearchInput$key: SalesDetails_useBillingCodeSearchInput_ClientFragment$key | null | undefined;
} | null;
const fieldAssignedClientKey = createFieldKey<FieldAssignedClient>();
export function useFieldAssignedClientRead($key: ClientBaseFields_useFieldAssignedClientFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_useFieldAssignedClientFragment on ClientInternalBase {
        assignedClientId
        assignedClient {
          ...SalesDetails_useBillingCodeSearchInput_ClientFragment
        }
      }
    `,
    $key,
  );
  const assignedClient = useFieldValue(jobStageBaseFormContext, fieldAssignedClientKey, () =>
    !$data?.assignedClientId ? null : { id: $data.assignedClientId, etag: nanoid(), useBillingCodeSearchInput$key: $data.assignedClient },
  );
  const assignedClientIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldAssignedClientKey);
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldAssignedClientKey);
  useMapper((v) => ({ clientBase: { assignedClientId: v?.id ?? null } }), [], 'save');
  useMapper((v) => ({ salesRatesInput: { clientId: v?.id ?? _throw('Assigned client is required to recalculate') } }), [], 'recalculate');

  return { assignedClient, assignedClientIsDirty };
}

export function useFieldAssignedClient($key: ClientBaseFields_useFieldAssignedClientFragment$key | null | undefined) {
  const setAssignedClient = useFieldSetter(jobStageBaseFormContext, fieldAssignedClientKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldAssignedClientKey);
  useValidation((val) => val != null || 'error.clientIsRequired', [], 'transferable,submittable:required');

  return { setAssignedClient, ...useFieldAssignedClientRead($key) };
}

export function useFieldAssignedClientErrors() {
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldAssignedClientKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(jobStageBaseFormContext, fieldAssignedClientKey);

  return { assignedClientErrorMessage: message, assignedClientErrorParams: errorParams, assignedClientHasErrors: hasErrors };
}

const fieldClientOverridesIsDirectSalesKey = createFieldKey<boolean>();
export function useFieldClientOverridesIsDirectSalesRead(
  $key: ClientBaseFields_ClientOverrides_IsDirectSalesFragment$key | null | undefined,
) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverrides_IsDirectSalesFragment on IOverridableClient {
        isDirectSales
      }
    `,
    $key,
  );
  const clientOverridesIsDirectSales = useFieldValue(
    jobStageBaseFormContext,
    fieldClientOverridesIsDirectSalesKey,
    () => $data?.isDirectSales ?? false,
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesIsDirectSalesKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { isDirectSales: v } } }), [], 'save');

  return { clientOverridesIsDirectSales };
}

export function useFieldClientOverridesIsDirectSales($key: ClientBaseFields_ClientOverrides_IsDirectSalesFragment$key | null | undefined) {
  const rest = useFieldClientOverridesIsDirectSalesRead($key);
  const setClientOverridesIsDirectSales = useFieldSetter(jobStageBaseFormContext, fieldClientOverridesIsDirectSalesKey);

  return { setClientOverridesIsDirectSales, ...rest };
}

const fieldClientOverridesNameKey = createFieldKey<string>();
export function useFieldClientOverridesNameRead($key: ClientBaseFields_ClientOverridesName_ReadFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverridesName_ReadFragment on IOverridableClient {
        name
      }
    `,
    $key,
  );
  const clientOverridesName = useFieldValue(jobStageBaseFormContext, fieldClientOverridesNameKey, () => $data?.name ?? '');
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesNameKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { name: v } } }), [], 'save');

  return { clientOverridesName };
}

const CLIENT_NAME_MAX_LENGTH = 30;
export function useFieldClientOverridesName(
  $key: ClientBaseFields_ClientOverridesNameFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverridesNameFragment on IOverridableClient {
        ...ClientBaseFields_ClientOverridesName_ReadFragment
        location {
          ...ClientBaseFields_ClientOverridesAddressFragment
          structuredAddress {
            ...ClientBaseFields_ClientOverridesCoordinatesFragment
            ...ClientBaseFields_ClientOverridesCityFragment
            ...ClientBaseFields_ClientOverridesStateFragment
            ...ClientBaseFields_ClientOverridesPostalCodeFragment
          }
        }
      }
    `,
    $key,
  );

  const { clientOverridesName, ...rest } = useFieldClientOverridesNameRead($data);
  const setClientOverridesName = useFieldSetter(jobStageBaseFormContext, fieldClientOverridesNameKey);
  const { setClientOverridesAddress } = useFieldClientOverridesAddress($data?.location, disabled, required);
  const { setClientOverridesCoordinates } = useFieldClientOverridesCoordinates($data?.location?.structuredAddress);
  const { setClientOverridesCity } = useFieldClientOverridesCity($data?.location?.structuredAddress, disabled, required);
  const { setClientOverridesState } = useFieldClientOverridesState($data?.location?.structuredAddress, disabled, required);
  const { setClientOverridesPostalCode } = useFieldClientOverridesPostalCode($data?.location?.structuredAddress, disabled, required);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldClientOverridesNameKey);
  useValidation((v) => !required || !!v?.trim(), [required], 'transferable,submittable:required');

  const renderClientOverridesName = useCallback(
    () => (
      <ClientNameInput
        value={clientOverridesName}
        onChange={(v, response) => {
          if (typeof v === 'string') {
            setClientOverridesName(v.trim().substring(0, CLIENT_NAME_MAX_LENGTH).trim());
          } else {
            setClientOverridesName((response?.name ?? '').substring(0, CLIENT_NAME_MAX_LENGTH).trim());
            setClientOverridesAddress(response?.address ?? '');
            setClientOverridesCity(response?.place ?? '');
            setClientOverridesState(response?.region ?? '');
            setClientOverridesPostalCode(response?.postcode ?? '');
            setClientOverridesCoordinates(response?.coordinates ?? null);
          }
        }}
        disabled={disabled}
      />
    ),
    [
      clientOverridesName,
      disabled,
      setClientOverridesAddress,
      setClientOverridesCity,
      setClientOverridesCoordinates,
      setClientOverridesName,
      setClientOverridesPostalCode,
      setClientOverridesState,
    ],
  );

  return { clientOverridesName, setClientOverridesName, renderClientOverridesName, ...rest };
}

function ClientNameInput({
  value,
  onChange: handleChange,
  disabled,
}: {
  value: string;
  onChange: (value: string | SearchBoxSuggestion, retrieveResponse: MapboxSearchInputResponse | null) => void;
  disabled: boolean;
}) {
  const { t } = useTranslation('client');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldClientOverridesNameKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['transfer'],
  };

  return (
    <MapboxSearchInput
      value={value}
      onChange={handleChange}
      textFieldProps={(params) => ({
        ...params,
        onBlur: (e) => handleChange(e.target.value.trim(), null),
        error: hasErrors,
        label: <RequiredForInputLabel label={t('field.name')} requiredFor={requiredFor} disabled={disabled} />,
        inputProps: {
          ...params.inputProps,
          maxLength: CLIENT_NAME_MAX_LENGTH,
        },
      })}
      disabled={disabled}
      getOptionLabel={(o) => (typeof o === 'string' ? o : o.name)}
    />
  );
}

type FieldClientOverridesAddress = string;
const fieldClientOverridesAddressKey = createFieldKey<FieldClientOverridesAddress>();
export function useFieldClientOverridesAddressRead($key: ClientBaseFields_ClientOverridesAddressReadFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverridesAddressReadFragment on Location {
        address
        structuredAddress {
          value {
            streetAddress
          }
        }
      }
    `,
    $key,
  );

  const clientOverridesAddress = useFieldValue(
    jobStageBaseFormContext,
    fieldClientOverridesAddressKey,
    () => $data?.structuredAddress?.value?.streetAddress ?? $data?.address.replaceAll('\n', ' ') ?? '',
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesAddressKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { location: { structuredAddress: { streetAddress: v } } } } }), [], 'save');

  return { clientOverridesAddress };
}

export function useFieldClientOverridesAddress(
  $key: ClientBaseFields_ClientOverridesAddressFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverridesAddressFragment on Location {
        ...ClientBaseFields_ClientOverridesAddressReadFragment

        structuredAddress {
          ...ClientBaseFields_ClientOverridesCoordinatesFragment
          ...ClientBaseFields_ClientOverridesCityFragment
          ...ClientBaseFields_ClientOverridesStateFragment
          ...ClientBaseFields_ClientOverridesPostalCodeFragment
        }
      }
    `,
    $key,
  );

  const { clientOverridesAddress, ...rest } = useFieldClientOverridesAddressRead($data);
  const setClientOverridesAddress = useFieldSetter(jobStageBaseFormContext, fieldClientOverridesAddressKey);
  const { setClientOverridesCoordinates } = useFieldClientOverridesCoordinates($data?.structuredAddress);
  const { setClientOverridesCity } = useFieldClientOverridesCity($data?.structuredAddress, disabled, required);
  const { setClientOverridesState } = useFieldClientOverridesState($data?.structuredAddress, disabled, required);
  const { setClientOverridesPostalCode } = useFieldClientOverridesPostalCode($data?.structuredAddress, disabled, required);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldClientOverridesAddressKey);
  useValidation((v) => !required || !!v?.trim(), [required], 'transferable,submittable:required');

  const renderClientOverridesAddress = () => (
    <ClientAddressInput
      value={clientOverridesAddress}
      onChange={(v, response) => {
        if (typeof v === 'string') {
          setClientOverridesAddress(v);
        } else {
          setClientOverridesAddress(response?.address ?? '');
          setClientOverridesCity(response?.place ?? '');
          setClientOverridesState(response?.region ?? '');
          setClientOverridesPostalCode(response?.postcode ?? '');
          setClientOverridesCoordinates(response?.coordinates ?? null);
        }
      }}
      disabled={disabled}
    />
  );

  return { clientOverridesAddress, setClientOverridesAddress, renderClientOverridesAddress, ...rest };
}

function ClientAddressInput({
  value,
  onChange: handleChange,
  disabled,
}: {
  value: FieldClientOverridesAddress;
  onChange: (value: string | SearchBoxSuggestion, retrieveResponse: MapboxSearchInputResponse | null) => void;
  disabled: boolean;
}) {
  const { t } = useTranslation('client');

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['reserve', 'transfer'],
  };

  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldClientOverridesAddressKey);

  return (
    <MapboxSearchInput
      value={value}
      onChange={handleChange}
      textFieldProps={(params) => ({
        ...params,
        onBlur: (e) => handleChange(e.target.value.trim(), null),
        error: hasErrors,
        label: <RequiredForInputLabel label={t('field.address')} requiredFor={requiredFor} disabled={disabled} />,
      })}
      disabled={disabled}
    />
  );
}

type FieldClientOverridesCoordinates = Coordinates | null;
const fieldClientOverridesCoordinatesKey = createFieldKey<FieldClientOverridesCoordinates>();
export function useFieldClientOverridesCoordinatesRead($key: ClientBaseFields_ClientOverridesCoordinatesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverridesCoordinatesFragment on StructuredAddressResult {
        value {
          coordinates {
            latitude
            longitude
          }
        }
      }
    `,
    $key,
  );

  const clientOverridesCoordinates = useFieldValue(jobStageBaseFormContext, fieldClientOverridesCoordinatesKey, () =>
    $data?.value?.coordinates
      ? {
          latitude: $data.value.coordinates.latitude,
          longitude: $data.value.coordinates.longitude,
        }
      : null,
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesCoordinatesKey);
  useMapper(
    (v) => ({
      clientBase: {
        assignedClientInfo: {
          location: {
            structuredAddress: {
              coordinates: v
                ? {
                    latitude: v.latitude,
                    longitude: v.longitude,
                  }
                : null,
            },
          },
        },
      },
    }),
    [],
    'save',
  );

  return { clientOverridesCoordinates };
}

export function useFieldClientOverridesCoordinates($key: ClientBaseFields_ClientOverridesCoordinatesFragment$key | null | undefined) {
  const { clientOverridesCoordinates, ...rest } = useFieldClientOverridesCoordinatesRead($key);
  const setClientOverridesCoordinates = useFieldSetter(jobStageBaseFormContext, fieldClientOverridesCoordinatesKey);

  return { clientOverridesCoordinates, setClientOverridesCoordinates, ...rest };
}

type FieldClientOverridesPostalCode = string;
const fieldClientOverridesPostalCodeKey = createFieldKey<FieldClientOverridesPostalCode>();
export function useFieldClientOverridesPostalCodeRead($key: ClientBaseFields_ClientOverridesPostalCodeFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverridesPostalCodeFragment on StructuredAddressResult {
        value {
          postalCode
        }
      }
    `,
    $key,
  );

  const clientOverridesPostalCode = useFieldValue(
    jobStageBaseFormContext,
    fieldClientOverridesPostalCodeKey,
    () => $data?.value?.postalCode ?? '',
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesPostalCodeKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { location: { structuredAddress: { postalCode: v } } } } }), [], 'save');

  return { clientOverridesPostalCode };
}

export function useFieldClientOverridesPostalCode(
  $key: ClientBaseFields_ClientOverridesPostalCodeFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { clientOverridesPostalCode, ...rest } = useFieldClientOverridesPostalCodeRead($key);
  const setClientOverridesPostalCode = useFieldSetter(jobStageBaseFormContext, fieldClientOverridesPostalCodeKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldClientOverridesPostalCodeKey);
  useValidation((v) => !required || !!v?.trim(), [required], 'transferable,submittable:required');

  const renderClientOverridesPostalCode = () => (
    <ClientPostalCodeInput value={clientOverridesPostalCode} setValue={setClientOverridesPostalCode} disabled={disabled} />
  );

  return { clientOverridesPostalCode, setClientOverridesPostalCode, renderClientOverridesPostalCode, ...rest };
}

function ClientPostalCodeInput({
  value,
  setValue,
  disabled,
}: {
  value: FieldClientOverridesPostalCode;
  setValue: SetValueFn<FieldClientOverridesPostalCode>;
  disabled: boolean;
}) {
  const { t } = useTranslation('client');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldClientOverridesPostalCodeKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['reserve', 'transfer'],
  };

  return (
    <TextField
      label={<RequiredForInputLabel label={t('field.postalCode')} requiredFor={requiredFor} disabled={disabled} />}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => setValue(e.target.value.trim())}
      error={hasErrors}
      disabled={disabled}
      data-label-key='client.field.postalCode'
      inputProps={{ maxLength: 7 }}
      InputProps={{
        inputProps: {
          maxLength: 7,
        },
      }}
    />
  );
}

type FieldClientOverridesApartment = string;
const fieldClientOverridesApartmentKey = createFieldKey<FieldClientOverridesApartment>();
export function useFieldClientOverridesApartmentRead($key: ClientBaseFields_ClientOverridesApartmentFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverridesApartmentFragment on StructuredAddressResult {
        value {
          apartment
        }
      }
    `,
    $key,
  );

  const clientOverridesApartment = useFieldValue(
    jobStageBaseFormContext,
    fieldClientOverridesApartmentKey,
    () => $data?.value?.apartment ?? '',
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesApartmentKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { location: { structuredAddress: { apartment: v } } } } }), [], 'save');

  return { clientOverridesApartment };
}

export function useFieldClientOverridesApartment(
  $key: ClientBaseFields_ClientOverridesApartmentFragment$key | null | undefined,
  disabled: boolean,
) {
  const { clientOverridesApartment, ...rest } = useFieldClientOverridesApartmentRead($key);
  const setClientOverridesApartment = useFieldSetter(jobStageBaseFormContext, fieldClientOverridesApartmentKey);

  const renderClientOverridesApartment = () => (
    <ClientApartmentInput value={clientOverridesApartment} setValue={setClientOverridesApartment} disabled={disabled} />
  );

  return { clientOverridesApartment, setClientOverridesApartment, renderClientOverridesApartment, ...rest };
}

function ClientApartmentInput({
  value,
  setValue,
  disabled,
}: {
  value: FieldClientOverridesPostalCode;
  setValue: SetValueFn<FieldClientOverridesPostalCode>;
  disabled: boolean;
}) {
  const { t } = useTranslation('client');

  return (
    <TextField
      label={t('field.apartment')}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => setValue(e.target.value.trim())}
      disabled={disabled}
      data-label-key='client.field.apartment'
    />
  );
}

type FieldClientOverridesCity = string;
const fieldClientOverridesCityKey = createFieldKey<FieldClientOverridesCity>();
export function useFieldClientOverridesCityRead($key: ClientBaseFields_ClientOverridesCityFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverridesCityFragment on StructuredAddressResult {
        value {
          city
        }
      }
    `,
    $key,
  );

  const clientOverridesCity = useFieldValue(jobStageBaseFormContext, fieldClientOverridesCityKey, () => $data?.value?.city ?? '');
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesCityKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { location: { structuredAddress: { city: v } } } } }), [], 'save');

  return { clientOverridesCity };
}

export function useFieldClientOverridesCity(
  $key: ClientBaseFields_ClientOverridesCityFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { clientOverridesCity, ...rest } = useFieldClientOverridesCityRead($key);
  const setClientOverridesCity = useFieldSetter(jobStageBaseFormContext, fieldClientOverridesCityKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldClientOverridesCityKey);
  useValidation((v) => !required || !!v?.trim(), [required], 'transferable,submittable:required');

  const renderClientOverridesCity = () => (
    <ClientCityInput value={clientOverridesCity} setValue={setClientOverridesCity} disabled={disabled} />
  );

  return { clientOverridesCity, setClientOverridesCity, renderClientOverridesCity, ...rest };
}

function ClientCityInput({
  value,
  setValue,
  disabled,
}: {
  value: FieldClientOverridesCity;
  setValue: SetValueFn<FieldClientOverridesCity>;
  disabled: boolean;
}) {
  const { t } = useTranslation('client');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldClientOverridesCityKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['reserve', 'transfer'],
  };

  return (
    <TextField
      label={<RequiredForInputLabel label={t('field.city')} requiredFor={requiredFor} disabled={disabled} />}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => setValue(e.target.value.trim())}
      disabled={disabled}
      error={hasErrors}
      data-label-key='client.field.city'
    />
  );
}

type FieldClientOverridesState = string;
const fieldClientOverridesStateKey = createFieldKey<FieldClientOverridesState>();
export function useFieldClientOverridesStateRead($key: ClientBaseFields_ClientOverridesStateFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverridesStateFragment on StructuredAddressResult {
        value {
          state
        }
      }
    `,
    $key,
  );

  const clientOverridesState = useFieldValue(jobStageBaseFormContext, fieldClientOverridesStateKey, () => $data?.value?.state ?? '');
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesStateKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { location: { structuredAddress: { state: v } } } } }), [], 'save');

  return { clientOverridesState };
}

export function useFieldClientOverridesState(
  $key: ClientBaseFields_ClientOverridesStateFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { clientOverridesState, ...rest } = useFieldClientOverridesStateRead($key);
  const setClientOverridesState = useFieldSetter(jobStageBaseFormContext, fieldClientOverridesStateKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldClientOverridesStateKey);
  useValidation((v) => !required || !!v?.trim(), [required], 'transferable,submittable:required');

  const renderClientOverridesState = () => (
    <ClientStateInput value={clientOverridesState} setValue={setClientOverridesState} disabled={disabled} />
  );

  return { clientOverridesState, setClientOverridesState, renderClientOverridesState, ...rest };
}

function ClientStateInput({
  value,
  setValue,
  disabled,
}: {
  value: FieldClientOverridesState;
  setValue: SetValueFn<FieldClientOverridesState>;
  disabled: boolean;
}) {
  const { t } = useTranslation('client');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldClientOverridesStateKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['reserve', 'transfer'],
  };

  return (
    <TextField
      label={<RequiredForInputLabel label={t('field.province')} requiredFor={requiredFor} disabled={disabled} />}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => setValue(e.target.value.trim())}
      disabled={disabled}
      error={hasErrors}
      data-label-key='client.field.province'
    />
  );
}

const fieldClientOverridesPhoneNumberKey = createFieldKey<string>();
export function useFieldClientOverridesPhoneNumber(
  $key: ClientBaseFields_ClientOverrides_PhoneNumberFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverrides_PhoneNumberFragment on IOverridableClient {
        phoneNumber
      }
    `,
    $key,
  );

  const [clientOverridesPhoneNumber, setClientOverridesPhoneNumber] = useField(
    jobStageBaseFormContext,
    fieldClientOverridesPhoneNumberKey,
    () => $data?.phoneNumber ?? '',
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesPhoneNumberKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { phoneNumber: v } } }), [], 'save');

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldClientOverridesPhoneNumberKey);
  useValidation((v) => (required ? !!v : true), [required], 'transferable,submittable:required');

  const renderClientOverridesPhoneNumber = useCallback(
    () => <ClientPhoneNumberInput value={clientOverridesPhoneNumber} setValue={setClientOverridesPhoneNumber} disabled={disabled} />,
    [clientOverridesPhoneNumber, disabled, setClientOverridesPhoneNumber],
  );

  return { clientOverridesPhoneNumber, setClientOverridesPhoneNumber, renderClientOverridesPhoneNumber };
}

function ClientPhoneNumberInput({ value, setValue, disabled }: { value: string; setValue: SetValueFn<string>; disabled: boolean }) {
  const { t } = useTranslation('client');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldClientOverridesPhoneNumberKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['transfer'],
  };

  return (
    <TextField
      label={<RequiredForInputLabel label={t('field.phoneNumber')} requiredFor={requiredFor} disabled={disabled} />}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      error={hasErrors}
      disabled={disabled}
      InputProps={{
        inputComponent: MaskInput,
        inputProps: {
          error: hasErrors,
          mask: '000-000-0000',
        },
      }}
    />
  );
}

export type FieldClientOverridesRequirement = NonNullable<ForwardClientRequirementAutocompleteProps<true>['value']>;
const fieldClientOverridesRequirementsKey = createFieldKey<FieldClientOverridesRequirement>();
function equalsById(left: { id: DataID }, right: { id: DataID }) {
  return left.id === right.id;
}
const fieldClientOverridesRequirementsFragment = graphql`
  fragment ClientBaseFields_RequirementsFragment on Requirement @relay(plural: true) {
    id
    label
  }
`;
export function useFieldClientOverridesRequirementsRead(
  $key: ClientBaseFields_RequirementsFragment$key | null | undefined,
  static$key: ClientBaseFields_RequirementsFragment$key | null | undefined,
) {
  const $data = useFragment(fieldClientOverridesRequirementsFragment, $key);
  const static$data = useFragment(fieldClientOverridesRequirementsFragment, static$key);
  const clientOverridesRequirements = useFieldValue(jobStageBaseFormContext, fieldClientOverridesRequirementsKey, () =>
    exclude($data ?? [], static$data ?? [], equalsById),
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesRequirementsKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { requirements: v ? { ids: v.map((r) => r.id) } : null } } }), [], 'save');

  return { clientOverridesRequirements };
}

export function useFieldClientOverridesRequirements(
  $key: ClientBaseFields_RequirementsFragment$key | null | undefined,
  static$key: ClientBaseFields_RequirementsFragment$key | null | undefined,
  disabled: boolean,
) {
  const static$data = useFragment(fieldClientOverridesRequirementsFragment, static$key);

  const { clientOverridesRequirements, ...rest } = useFieldClientOverridesRequirementsRead($key, static$key);
  const setClientOverridesRequirementsInternal = useFieldSetter(jobStageBaseFormContext, fieldClientOverridesRequirementsKey);

  const setClientOverridesRequirements = useCallback<typeof setClientOverridesRequirementsInternal>(
    (newValue) => {
      if (typeof newValue === 'function') {
        setClientOverridesRequirementsInternal((old) => exclude(newValue(old) ?? [], static$data ?? [], equalsById));
      } else {
        setClientOverridesRequirementsInternal(exclude(newValue ?? [], static$data ?? [], equalsById));
      }
    },
    [setClientOverridesRequirementsInternal, static$data],
  );

  const displayedRequirements = useMemo(
    () => [...(static$data ?? []), ...(clientOverridesRequirements ?? [])],
    [clientOverridesRequirements, static$data],
  );

  const renderClientOverridesRequirements = useCallback(
    (
      input$key: ClientBaseFields_ClientRequirementsSuggestionsFragment$key | null | undefined,
      saleKind: ServiceCallKind | QuoteKind | null,
    ) => (
      <ClientRequirementsSuggested
        $key={input$key}
        value={displayedRequirements}
        setValue={setClientOverridesRequirements}
        saleKind={saleKind}
        disabled={disabled}
        staticRequirements={static$data ?? []}
      />
    ),
    [disabled, displayedRequirements, setClientOverridesRequirements, static$data],
  );

  const renderClientOverridesRequirementsNoSuggestions = useCallback(
    () => (
      <ClientRequirementsInput
        value={displayedRequirements}
        setValue={setClientOverridesRequirements}
        disabled={disabled}
        staticRequirements={static$data ?? []}
        suggestionPromptInput={null}
      />
    ),
    [disabled, displayedRequirements, setClientOverridesRequirements, static$data],
  );

  return {
    clientOverridesRequirements,
    setClientOverridesRequirements,
    renderClientOverridesRequirements,
    renderClientOverridesRequirementsNoSuggestions,
    ...rest,
  };
}

function ClientRequirementsSuggested({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
  staticRequirements,
}: {
  $key: ClientBaseFields_ClientRequirementsSuggestionsFragment$key | null | undefined;
  value: FieldClientOverridesRequirement;
  setValue: SetValueFn<FieldClientOverridesRequirement>;
  saleKind: ServiceCallKind | QuoteKind | null;
  disabled: boolean;
  staticRequirements: NonNullable<FieldClientOverridesRequirement>;
}) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientRequirementsSuggestionsFragment on ISale @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const suggestionPromptInput = useSuggestions($data, saleKind);

  return (
    <ClientRequirementsInput
      value={value}
      setValue={setValue}
      disabled={disabled}
      staticRequirements={staticRequirements}
      suggestionPromptInput={suggestionPromptInput}
    />
  );
}

function ClientRequirementsInput({
  value,
  setValue,
  disabled,
  staticRequirements,
  suggestionPromptInput,
}: {
  value: FieldClientOverridesRequirement;
  setValue: SetValueFn<FieldClientOverridesRequirement>;
  disabled: boolean;
  staticRequirements: NonNullable<FieldClientOverridesRequirement>;
  suggestionPromptInput: SuggestionPromptInput | null;
}) {
  const { t } = useTranslation('client');
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <ClientRequirementAutocomplete
        multiple
        value={value}
        onChange={(values) => {
          // we need to strip static requirements from results since they are not really part of the value.
          setValue(values?.filter((v) => !staticRequirements.some((sr) => sr.id === v.id)));
        }}
        suggestionPromptInput={suggestionPromptInput}
        suggestible={!!suggestionPromptInput}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const isDefaultRequirement = staticRequirements.some((r) => r.id === option.id);
            const handleDelete = isDefaultRequirement ? undefined : getTagProps({ index }).onDelete;
            return <Chip key={option.id} onDelete={handleDelete} label={option.label} size='small' disabled={disabled} />;
          })
        }
        getOptionDisabled={(option) => staticRequirements.some((r) => r.id === option.id)}
        textFieldProps={(params) => ({
          label: t('field.requirements', { ns: 'common' }),
          InputProps: {
            ...params.InputProps,
            startAdornment: (
              <>
                {value && value.length > 0 && (
                  <IconButton onClick={() => setDialogOpen(true)} sx={{ position: 'absolute', left: 0, top: 'calc(50% - 1.25rem)' }}>
                    <InfoIcon sx={(theme) => ({ color: theme.palette.grey.A400 })} />
                  </IconButton>
                )}
                {params.InputProps.startAdornment}
              </>
            ),
          },
        })}
        sx={value && value.length > 0 ? { '.MuiAutocomplete-inputRoot.MuiInputBase-adornedStart': { pl: '2.5rem' } } : {}}
        disabled={disabled}
      />
      <RequirementsDialog
        requirementIds={value?.map((r) => r.id) ?? []}
        onClose={() => setDialogOpen(false)}
        isOpen={dialogOpen}
        title={t('dialog.requirementsTitle', { ns: 'client' })}
      />
    </>
  );
}

const fieldClientOverridesLanguageCodeKey = createFieldKey<ClientLanguage | null>();
export function useFieldClientOverridesLanguageCodeRead(
  $key: ClientBaseFields_ClientOverrides_LanguageCodeFragment$key | null | undefined,
) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverrides_LanguageCodeFragment on IOverridableClient {
        language
      }
    `,
    $key,
  );
  const languageCode = useFieldValue(jobStageBaseFormContext, fieldClientOverridesLanguageCodeKey, () =>
    $data?.language ? castClientLanguage($data.language) : null,
  );
  const clientOverridesLanguageCodeIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldClientOverridesLanguageCodeKey);
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesLanguageCodeKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { language: v } } }), [], 'save');

  return { clientOverridesLanguageCode: languageCode, clientOverridesLanguageCodeIsDirty };
}
export function useFieldClientOverridesLanguageCode(
  $key: ClientBaseFields_ClientOverrides_LanguageCodeFragment$key | null | undefined,
  disabled: boolean,
) {
  const { clientOverridesLanguageCode, ...rest } = useFieldClientOverridesLanguageCodeRead($key);
  const setLanguageCode = useFieldSetter(jobStageBaseFormContext, fieldClientOverridesLanguageCodeKey);

  const renderClientOverridesLanguageCode = useCallback(
    () => <ClientLanguageInput value={clientOverridesLanguageCode} setValue={setLanguageCode} disabled={disabled} />,
    [clientOverridesLanguageCode, disabled, setLanguageCode],
  );

  return {
    clientOverridesLanguageCode,
    setClientOverridesLanguageCode: setLanguageCode,
    renderClientOverridesLanguageCode,
    ...rest,
  };
}

function ClientLanguageInput({
  value,
  setValue,
  disabled,
}: {
  value: ClientLanguage | null;
  setValue: SetValueFn<ClientLanguage | null>;
  disabled: boolean;
}) {
  const { t } = useTranslation('client');
  const [newValue, setNewValue] = useState<ClientLanguage | null>(null);

  const handleConfirm = useCallback(() => {
    if (newValue) {
      setValue(newValue);
    }
    setNewValue(null);
  }, [newValue, setValue]);

  const handleCancel = useCallback(() => setNewValue(null), []);

  return (
    <>
      <SelectPicker
        value={value ?? 'fr'}
        disableClearable
        getOptionKey={(o) => o}
        getOptionLabel={(o) => t(`language.${o}`, { ns: 'client' })}
        onChange={(_, v) => setNewValue(v)}
        options={clientLanguages}
        disabled={disabled}
        textFieldProps={(params) => ({
          ...params,
          label: t('field.preferredLanguage'),
        })}
      />
      <ConfirmationDialog
        title={t('warning', { ns: 'common' })}
        message={t('placeholder.changeLanguageWarning')}
        onConfirm={handleConfirm}
        showClose={false}
        onCancel={handleCancel}
        isOpen={!!newValue}
      />
    </>
  );
}

export type FieldRepresentative = NonNullable<ForwardRepresentativeAutocompleteProps['value']> | null;
const fieldRepresentativeKey = createFieldKey<FieldRepresentative>();
export function useFieldRepresentativeRead($key: ClientBaseFields_RepresentativeFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_RepresentativeFragment on ClientInternalBase {
        projectManager {
          id
          label
          deletedAt
        }
      }
    `,
    $key,
  );
  const representative = useFieldValue(jobStageBaseFormContext, fieldRepresentativeKey, () => $data?.projectManager ?? null);
  const isRepresentativeDirty = useFieldIsDirty(jobStageBaseFormContext, fieldRepresentativeKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldRepresentativeKey);
  useMapper((v) => ({ clientBase: { projectManagerId: v?.id ?? null } }), [], 'save');

  return { representative, isRepresentativeDirty };
}

export function useFieldRepresentative($key: ClientBaseFields_RepresentativeFragment$key | null | undefined, disabled: boolean) {
  const { representative, ...rest } = useFieldRepresentativeRead($key);
  const setRepresentative = useFieldSetter(jobStageBaseFormContext, fieldRepresentativeKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldRepresentativeKey);
  useValidation((v) => !!v, [], 'transferable,submittable:required');

  const renderRepresentative = useCallback(
    (input$key: ClientBaseFields_ClientRepresentativeSuggestedFragment$key | null | undefined, saleKind: QuoteKind | ServiceCallKind) => (
      <ClientRepresentativeSuggested
        $key={input$key}
        value={representative}
        setValue={setRepresentative}
        saleKind={saleKind}
        disabled={disabled}
      />
    ),
    [representative, setRepresentative, disabled],
  );

  const renderRepresentativeNoSuggestions = useCallback(
    () => (
      <ClientRepresentativeInput value={representative} setValue={setRepresentative} disabled={disabled} suggestionPromptInput={null} />
    ),
    [representative, setRepresentative, disabled],
  );

  return { representative, setRepresentative, renderRepresentative, renderRepresentativeNoSuggestions, ...rest };
}

function ClientRepresentativeSuggested({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
}: {
  $key: ClientBaseFields_ClientRepresentativeSuggestedFragment$key | null | undefined;
  value: FieldRepresentative;
  setValue: SetValueFn<FieldRepresentative>;
  saleKind: QuoteKind | ServiceCallKind;
  disabled: boolean;
}) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientRepresentativeSuggestedFragment on ISale @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );
  const suggestionPromptInput = useSuggestions($data, saleKind);

  return (
    <ClientRepresentativeInput
      value={value}
      setValue={setValue}
      disabled={disabled}
      suggestible
      suggestionPromptInput={suggestionPromptInput}
    />
  );
}

function ClientRepresentativeInput({
  value,
  setValue,
  disabled,
  suggestionPromptInput,
  suggestible,
}: {
  value: FieldRepresentative;
  setValue: SetValueFn<FieldRepresentative>;
  disabled: boolean;
  suggestionPromptInput: SuggestionPromptInput | null;
  suggestible?: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldRepresentativeKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(jobStageBaseFormContext, fieldRepresentativeKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = {
    quote: ['submit'],
    serviceCall: ['transfer'],
  };

  return (
    <RepresentativeAutocomplete
      value={value}
      onChange={(v) => setValue(v)}
      disabled={disabled}
      suggestionPromptInput={suggestionPromptInput}
      suggestible={suggestible}
      textFieldProps={(params) => ({
        label: <RequiredForInputLabel label={t('field.client.projectManager')} requiredFor={requiredFor} disabled={disabled} />,
        'data-label-key': 'field.client.projectManager',
        InputProps: {
          ...params.InputProps,
          startAdornment: (
            <>
              {value?.deletedAt && <InactiveStartAdornment />}
              {params.InputProps.startAdornment}
            </>
          ),
        },
        error: hasErrors,
        helperText: message ? t(message, { ns: 'common', ...errorParams }) : '',
      })}
    />
  );
}
