import { Box, InputAdornment, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import { useGeocodingCore, useSearchBoxCore, useSearchSession } from '@mapbox/search-js-react';
import { SearchBoxSuggestion } from '@mapbox/search-js-core';
import { forwardRef, HTMLAttributes, Ref, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from '../utils/debounce';
import { ForwardAutocompleteProps, SelectPicker, SelectPickerProps } from './SelectPicker';
import { resolvedLanguage } from '../../i18n';
import mapboxgl, { LngLat } from 'mapbox-gl';
import { Coordinates } from '../types/coordinates';
import 'mapbox-gl/dist/mapbox-gl.css';
import ErrorIcon from '@mui/icons-material/Error';
import { useEvent } from '../utils/effectUtils';
import { useOperations } from '../../AppSharedState';
import { useSnackbar } from 'notistack';

export const MAPBOX_SEARCH_BOX_RETRIEVE_OPERATION_KEY = 'Mapbox_SearchBoxRetrieve';
const BOUNDING_BOX: [number, number, number, number] = [-170.683594, 14.604847, -50.273438, 84.070747]; // Limit the mapbox features to this bounding box representing North America

// The necessary info between SearchBoxRetrieveResponse and GeocodingResponse for our use case
export type MapboxSearchInputResponse = {
  name: string;
  address: string | null;
  coordinates: Coordinates;
  place: string | null;
  region: string | null;
  postcode: string | null;
};

export function MapboxSearchInput({
  onChange,
  ...autocompleteProps
}: {
  onChange: (value: string | SearchBoxSuggestion, retrieveResponse: MapboxSearchInputResponse | null) => void;
} & Omit<
  SelectPickerProps<SearchBoxSuggestion, false, true, true> & ForwardAutocompleteProps<SearchBoxSuggestion, false, true, true>,
  'freeSolo' | 'autoSelect' | 'options' | 'onChange' | 'renderInput' | 'getOptionKey'
>) {
  const { i18n, t } = useTranslation('common');

  const [suggestions, setSuggestions] = useState<readonly SearchBoxSuggestion[]>([]);
  const [loading, setLoading] = useState(0);
  const [hasError, setHasError] = useState(false);
  const { startOperation, endOperation } = useOperations(MAPBOX_SEARCH_BOX_RETRIEVE_OPERATION_KEY);

  const accessToken = mapboxgl.accessToken ?? ''; // will be empty local mode, but we check token is setup before calling mapbox hooks
  const search = useSearchBoxCore({
    accessToken,
    bbox: BOUNDING_BOX,
    language: resolvedLanguage(i18n),
  });
  const session = useSearchSession(search);
  const geocode = useGeocodingCore({ accessToken, bbox: BOUNDING_BOX, language: resolvedLanguage(i18n) });

  const doQuery = useMemo(
    () =>
      debounce(async (v: string) => {
        // cancel previous session if still in flight and reset state
        session.abort();
        setHasError(false);

        // prevent querying when string is too short
        if (v.length < 3) {
          setSuggestions([]);
          return;
        }

        setLoading((prev) => prev + 1);
        try {
          const response = await session.suggest(v);
          setSuggestions(response.suggestions);
          setHasError(false);
        } catch (e) {
          // when error (example offline), clear suggestions and allow user to input free text
          setSuggestions([]);
          setHasError(true);

          console.error('Failed to fetch from Mapbox (suggest)', e);
        } finally {
          setLoading((prev) => prev - 1);
        }
      }, 400),
    [session],
  );

  return (
    <SelectPicker
      getOptionLabel={(o) => (typeof o === 'string' ? o : (o.address ?? o.name))}
      {...autocompleteProps}
      onChange={async (_, v, reason) => {
        if (!mapboxgl.accessToken || typeof v === 'string') return onChange(v, null);

        // We only want to retrieve the data if the user selects an option, otherwise it is freeSolo
        if (reason !== 'blur') {
          setHasError(false);
          const canRetrieve = session.canRetrieve(v);
          if (!canRetrieve) return onChange(v, null);

          try {
            startOperation();
            const retrieveResponse = await session.retrieve(v);
            const feature = retrieveResponse.features[0];
            let mappedResponse: MapboxSearchInputResponse | null = feature
              ? {
                  name: feature.properties.name,
                  address: feature.properties.address,
                  coordinates: {
                    latitude: feature.geometry.coordinates[1] ?? 0,
                    longitude: feature.geometry.coordinates[0] ?? 0,
                  },
                  place: feature.properties.context.place?.name ?? null,
                  region: feature.properties.context.region?.name ?? null,
                  postcode: feature.properties.context.postcode?.name ?? null,
                }
              : null;

            // if any of the nullable fields are missing, reverse geocode to get them
            if (mappedResponse && (!mappedResponse.region || !mappedResponse.place || !mappedResponse.postcode)) {
              const geocodeResponse = await geocode.reverse(
                { lat: mappedResponse.coordinates.latitude, lng: mappedResponse.coordinates.longitude },
                { sessionToken: session.sessionToken },
              );
              const context = geocodeResponse.features[0]?.properties.context ?? null;
              mappedResponse = {
                ...mappedResponse,
                place: context?.place?.name ?? null,
                region: context?.region?.name ?? null,
                postcode: context?.postcode?.name ?? null,
              };
            }

            onChange(v, mappedResponse);
            setHasError(false);
          } catch (e) {
            setHasError(true);
            console.error('Failed to fetch from Mapbox (retrieve)', e);
          } finally {
            endOperation();
          }
        }
      }}
      onInputChange={async (_, v, reason) => {
        if (mapboxgl.accessToken && reason !== 'reset') {
          doQuery(v);
        }
      }}
      freeSolo
      loading={loading !== 0}
      filterOptions={(o) => o}
      onClose={() => {
        // Cancel the current pending debounced request, if any
        doQuery.cancel();
      }}
      disableClearable
      options={suggestions}
      getOptionKey={(o) => (typeof o === 'string' ? o : o.mapbox_id)}
      isOptionEqualToValue={(o, v) => o.mapbox_id === v.mapbox_id}
      renderOption={(props, o) => {
        const { key, ...optionProps } = props;
        return (
          <ListItem {...optionProps} key={key}>
            <Stack>
              <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>{o.name}</Typography>
              <Typography sx={{ fontSize: '0.875rem' }}>{o.full_address}</Typography>
            </Stack>
          </ListItem>
        );
      }}
      textFieldProps={(params) => {
        const textFieldProps = autocompleteProps.textFieldProps?.(params);
        return {
          ...textFieldProps,
          InputProps: {
            ...textFieldProps?.InputProps,
            endAdornment: (
              <InputAdornment position='end'>
                {hasError && (
                  <Tooltip title={t('errorMessages.mapboxError')}>
                    <ErrorIcon color='error' />
                  </Tooltip>
                )}
                {textFieldProps?.InputProps?.endAdornment}
              </InputAdornment>
            ),
          },
        };
      }}
      ListboxComponent={MapBoxListBoxComponent}
    />
  );
}

const MapBoxListBoxComponent = forwardRef(function GoogleMapListBoxComponent(
  { children, ...rest }: HTMLAttributes<HTMLUListElement>,
  ref: Ref<HTMLUListElement>,
) {
  return (
    <ul {...rest} ref={ref}>
      {children}
      <li style={{ marginTop: '0.5rem' }}>
        <a
          href='https://www.mapbox.com/search-service'
          target='_blank'
          rel='noopener noreferrer'
          style={{ marginLeft: '1rem', fontSize: '14px', color: '#667F91' }}>
          Powered by Mapbox
        </a>
      </li>
    </ul>
  );
});

const DEFAULT_CENTER: mapboxgl.LngLatLike = { lat: 47.337065, lng: -73.463103 };
const DEFAULT_ZOOM = 4;
const DEFAULT_SPEED = 5;
const DEFAULT_MARKER_ZOOM = 15;

export function MapboxMap({
  coordinates,
  onCoordinatesChange,
  disabled,
}: {
  coordinates: Coordinates | null;
  onCoordinatesChange: (coords: Coordinates) => void;
  disabled: boolean;
}) {
  const mapContainer = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map>();
  const markerRef = useRef<mapboxgl.Marker>();
  const markerControlRef = useRef<MarkerControl>();

  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const updateMarker = useEvent((coords: Coordinates) => !disabled && onCoordinatesChange(coords));
  const createMap = useEvent(() => {
    if (!mapboxgl.accessToken || !mapContainer.current) return;

    const map = (mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: coordinates ? { lng: coordinates.longitude, lat: coordinates.latitude } : DEFAULT_CENTER,
      zoom: coordinates ? DEFAULT_MARKER_ZOOM : DEFAULT_ZOOM,
      maxZoom: 18,
    })
      .addControl(new mapboxgl.FullscreenControl())
      .addControl(
        new mapboxgl.GeolocateControl({ fitBoundsOptions: { speed: DEFAULT_SPEED, zoom: DEFAULT_MARKER_ZOOM } }).on('error', (err) => {
          console.error('GeolocateControl error:', err);
          if (err.code === err.PERMISSION_DENIED) {
            enqueueSnackbar(t('errorMessages.geolocatePermissionDenied'), { variant: 'warning' });
          }
        }),
      )
      .on('click', (event) => updateMarker({ latitude: event.lngLat.lat, longitude: event.lngLat.lng })));

    const marker = (markerRef.current = new mapboxgl.Marker().on('dragend', (event) =>
      updateMarker({ latitude: event.target.getLngLat().lat, longitude: event.target.getLngLat().lng }),
    ));

    markerControlRef.current = new MarkerControl({
      onClick: (lngLat: LngLat) => updateMarker({ latitude: lngLat.lat, longitude: lngLat.lng }),
    });

    return () => {
      map.remove();
      marker.remove();
    };
  });

  useLayoutEffect(() => createMap(), [createMap]);

  // React to coordinates change
  useLayoutEffect(() => {
    if (!mapRef.current || !markerRef.current) return;

    // When no coordinates, zoom out and center around default center
    if (!coordinates) {
      mapRef.current.flyTo({ center: DEFAULT_CENTER, speed: DEFAULT_SPEED, zoom: DEFAULT_ZOOM });
      return;
    }

    markerRef.current.setLngLat({ lat: coordinates.latitude, lng: coordinates.longitude }).addTo(mapRef.current);

    // Only fly to the coordinates in two cases:
    // 1. The zoom is the default zoom (when coordinates are null)
    // 2. The coordinates are changed and the marker is outside the current map bounds
    if (mapRef.current.getZoom() === DEFAULT_ZOOM || !mapRef.current.getBounds()?.contains(markerRef.current.getLngLat())) {
      mapRef.current.flyTo({ center: markerRef.current.getLngLat(), speed: DEFAULT_SPEED, zoom: DEFAULT_MARKER_ZOOM });
    }

    return () => {
      markerRef.current?.remove();
    };
  }, [coordinates]);

  // React to disabled change
  useLayoutEffect(() => {
    markerRef.current?.setDraggable(!disabled);
    const markerControl = markerControlRef.current;

    if (!markerControl || disabled) return;

    mapRef.current?.addControl(markerControl);

    return () => {
      mapRef.current?.removeControl(markerControl);
    };
  }, [disabled]);

  return (
    <Box
      ref={mapContainer}
      sx={(theme) => ({
        borderRadius: '0.25rem',
        border: `1px solid ${theme.palette.divider}`,
        height: '100%',
        width: '100%',
        [theme.breakpoints.down('md')]: {
          '.mapboxgl-ctrl-group > button': {
            width: '2.75rem',
            height: '2.75rem',
          },
        },
      })}
    />
  );
}

type MarkerControlOptions = {
  onClick: (lngLat: LngLat) => void;
};
class MarkerControl implements mapboxgl.IControl {
  constructor(options: MarkerControlOptions) {
    this._options = options;
  }

  _container: HTMLDivElement | undefined = undefined;
  _options: MarkerControlOptions = { onClick: () => {} };

  onAdd(map: mapboxgl.Map) {
    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this._container.innerHTML = `
      <button>
        <svg fill="#000000" viewBox="-79.14 -79.14 553.99 553.99" xml:space="preserve" stroke="#000000">
          <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738 c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388 C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191 c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z" />
        </svg>        
      </button>`;
    this._container.addEventListener('contextmenu', (e) => e.preventDefault());
    this._container.addEventListener('click', () => this._options.onClick(map.getCenter()));

    return this._container;
  }
  onRemove() {
    this._container?.parentNode?.removeChild(this._container);
  }
}
