/**
 * @generated SignedSource<<6332c614f51492ede3286b2a708019a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CraneSelectorFields_Manual_CounterweightFragment$data = {
  readonly counterweight: string | null | undefined;
  readonly " $fragmentType": "CraneSelectorFields_Manual_CounterweightFragment";
};
export type CraneSelectorFields_Manual_CounterweightFragment$key = {
  readonly " $data"?: CraneSelectorFields_Manual_CounterweightFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_Manual_CounterweightFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CraneSelectorFields_Manual_CounterweightFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "counterweight",
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "6e5dac48cd3d8b50c1ec2bd305c1554d";

export default node;
