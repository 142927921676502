/**
 * @generated SignedSource<<355ea40e4652fb394379cc871f8d1204>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CraneSelectorFields_CraneConfigurationsFragment$data = {
  readonly matchingConfigurations: ReadonlyArray<{
    readonly eTag: string;
    readonly " $fragmentSpreads": FragmentRefs<"CraneConfiguration_ConfigurationListItemFragment" | "useFavoriteEquipment_favoriteAutomaticEquipmentFragment">;
  }>;
  readonly " $fragmentType": "CraneSelectorFields_CraneConfigurationsFragment";
};
export type CraneSelectorFields_CraneConfigurationsFragment$key = {
  readonly " $data"?: CraneSelectorFields_CraneConfigurationsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_CraneConfigurationsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CraneSelectorFields_CraneConfigurationsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomaticConfigurationInfo",
      "kind": "LinkedField",
      "name": "matchingConfigurations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eTag",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneConfiguration_ConfigurationListItemFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useFavoriteEquipment_favoriteAutomaticEquipmentFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IMatchingConfigurations",
  "abstractKey": "__isIMatchingConfigurations"
};

(node as any).hash = "d2e2ac1cffa3a8ab0eb1c8ced746d5d1";

export default node;
