/**
 * @generated SignedSource<<805796a2e1b097fb276fe447a0b2e8f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_WorksiteOverridesName_ClientFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesAddress_ClientFragment">;
  readonly " $fragmentType": "ProjectBaseFields_WorksiteOverridesName_ClientFragment";
};
export type ProjectBaseFields_WorksiteOverridesName_ClientFragment$key = {
  readonly " $data"?: ProjectBaseFields_WorksiteOverridesName_ClientFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesName_ClientFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_WorksiteOverridesName_ClientFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverridesAddress_ClientFragment"
    }
  ],
  "type": "ClientInternalBase",
  "abstractKey": null
};

(node as any).hash = "baa98f63440f8cd3d2c119d81f20f877";

export default node;
