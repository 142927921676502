import { useEffect, useRef } from 'react';

/**
 * A custom hook that accepts a value and returns the value it was provided during the previous render.
 * This overload returns `undefined` on first render.
 */
export function usePreviousValue<V>(value: V): V | undefined;

/**
 * A custom hook that accepts a value and returns the value it was provided during the previous render.
 * This overload returns `initialValue` on first render.
 */
export function usePreviousValue<V>(value: V, initialValue: V): V;

export function usePreviousValue(value: unknown, initialValue?: unknown): unknown {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

/**
 * A custom hook that accepts a value and returns the value it was provided during the previous render,
 * including cancelled renders.
 * This overload returns `undefined` on first render.
 * @param value
 */
export function usePreviousRenderValue<V>(value: V): V | undefined;

/**
 * A custom hook that accepts a value and returns the value it was provided during the previous render,
 * including cancelled renders.
 * This overload returns `initialValue` on first render.
 */
export function usePreviousRenderValue<V>(value: V, initialValue: V): V;

export function usePreviousRenderValue(value: unknown, initialValue?: unknown): unknown {
  const ref = useRef(initialValue);
  const cur = ref.current;

  ref.current = value;

  return cur;
}
