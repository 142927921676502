/**
 * @generated SignedSource<<543dec9197a14b63cf2df8bc8d94c0f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useFavoriteEquipment_SynchronizeManual_FromFieldValuesFragment$data = {
  readonly userInput: {
    readonly boomConfiguration: {
      readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_extraDataFragment">;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_Manual_BoomConfigurationFragment" | "CraneSelectorFields_Manual_BoomLengthFragment" | "CraneSelectorFields_Manual_CounterweightFragment" | "CraneSelectorFields_Manual_JibLengthFragment" | "CraneSelectorFields_Manual_MaxWeightFragment" | "CraneSelectorFields_Manual_OffsetAngleFragment" | "CraneSelectorFields_Manual_RadiusFragment" | "CraneSelectorFields_VehicleIdsFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AdditionalCranesFields_AdditionalCranesManualCollectionFragment">;
  readonly " $fragmentType": "useFavoriteEquipment_SynchronizeManual_FromFieldValuesFragment";
};
export type useFavoriteEquipment_SynchronizeManual_FromFieldValuesFragment$key = {
  readonly " $data"?: useFavoriteEquipment_SynchronizeManual_FromFieldValuesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_SynchronizeManual_FromFieldValuesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useFavoriteEquipment_SynchronizeManual_FromFieldValuesFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdditionalCranesFields_AdditionalCranesManualCollectionFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ManualConfigurationInfo",
      "kind": "LinkedField",
      "name": "userInput",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneSelectorFields_Manual_BoomConfigurationFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneSelectorFields_Manual_BoomLengthFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneSelectorFields_Manual_JibLengthFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneSelectorFields_Manual_OffsetAngleFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneSelectorFields_Manual_RadiusFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneSelectorFields_Manual_MaxWeightFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneSelectorFields_Manual_CounterweightFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CraneSelectorFields_VehicleIdsFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BoomConfigurationSnapshot",
          "kind": "LinkedField",
          "name": "boomConfiguration",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useFavoriteEquipment_extraDataFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInternal",
  "abstractKey": null
};

(node as any).hash = "8568d3cab1894392eb509a2bffee508a";

export default node;
