import { QuoteStatus } from '../../__enums__/QuoteStatus';
import { QuoteKind } from '../../__enums__/QuoteKind';
import { TransportKind } from '../../__enums__/TransportKind';
import { LoadingUnloading } from '../../__enums__/LoadingUnloading';
import { TransportSegmentKind } from '../../__enums__/TransportSegmentKind';
import { CalculatorFlatbedBoomCounterweightTransportEntryKind } from '../../__enums__/CalculatorFlatbedBoomCounterweightTransportEntryKind';
import { AdjustmentSegmentKind } from '../../__enums__/AdjustmentSegmentKind';
import { AdjustmentUnit } from '../../__enums__/AdjustmentUnit';

export const quote = {
  addenda: 'Addenda',
  alert: {
    supervisionComment: 'Commentaire de supervision',
    costLinesAdded_one: '{{count}} ligne de coût a été ajoutée',
    costLinesAdded_other: '{{count}} lignes de coût ont été ajoutées',
  },
  ariaLabels: {
    main: 'Créer une soumission au type grue opérée (à l’heure)',
    more: 'Montrer les options',
    options: {
      accessoriesRental: 'Créer une soumission au type location d’accessoires',
      bare: 'Créer une soumission au type grue non-opérée',
      laborRental: 'Créer une soumission au type location de main d’oeuvre',
      liftingPlan: 'Créer une soumission au type plan de levage',
      liftingTest: 'Créer une soumission au type test de levage',
      liftingTestOperated: 'Créer une soumission au type test de levage opéré',
      operatedHourly: 'Créer une soumission au type grue opérée (à l’heure)',
      operatedMonthly: 'Créer une soumission au type grue opérée (mensuelle)',
      rollingEquipment: 'Créer une soumission au type matériel roulant',
      bareWithOperators: 'Créer une soumission au type grue non-opérée avec opérateurs',
      storage: 'Créer une soumission au type entreposage',
    } satisfies Record<QuoteKind, string>,
  },
  button: {
    approve: 'Approuver',
    decline: 'Décliner',
    modify: 'Modifier',
    returnToInWriting: 'Retourner en saisie',
    rejectBySupervisor: 'Rejeter',
    accept: 'Accepter',
  },
  comment: 'Commentaire',
  costs: 'Coûts',
  dateTime: {
    sevenDays: '7 jours',
    fourteenDays: '14 jours',
    thirtyDays: '30 jours',
    sixtyDays: '60 jours',
  },
  dialog: {
    cancel: {
      title: 'Annuler la soumission',
      confirmButton: 'Annuler la soumission',
      body: 'Êtes-vous certain de vouloir annuler cette soumission\u00a0? Tous les changements non sauvegardés seront perdus. Cette opération est irréversible.',
    },
    copy: {
      title: 'Copier la soumission',
      confirmButton: 'Copier la soumission',
    },
    email: {
      title: 'Envoyer par courriel',
      sendToProjectManager: 'Chargé de projet',
      sendToClientRepresentative: 'Représentant au compte client',
      sendToCurrentUser: 'L’utilisateur connecté',
      sendButton: 'Envoyer',
      unavailable: 'non disponible',
      error: 'Une erreur s’est produite pendant l’envoi de courriel.',
    },
    accept: {
      title: 'Accepter la soumission',
      body: 'Êtes-vous certain de vouloir accepter cette soumission\u00a0? Cette opération est irréversible. Un ou plusieurs appels de service seront automatiquement créés',
      cancelButton: 'Non',
      confirmButton: 'Oui',
    },
    decline: {
      title: 'Décliner la soumission',
      body: 'Êtes-vous sur de vouloir décliner la soumission\u00a0? Cette opération est irréversible.',
      cancelButton: 'Non',
      confirmButton: 'Oui',
    },
  },
  error: {
    errorDuringDeclineByClient: 'Une erreur s’est produite pendant le déclin',
    errorDuringAccept: 'Une erreur s’est produite pendant l’acceptation',
    errorDuringRequestChange: 'Une erreur s’est produite pendant la demande de modification',
    errorDuringRequestReview: 'Une erreur s’est produite pendant la demande d’approbation',
    errorDuringRejectBySupervisor: 'Une erreur s’est produite pendant le rejet',
    errorDuringApproveBySupervisor: 'Une erreur s’est produite pendant l’acceptation',
    errorDuringReturnToInWriting: 'Une erreur s’est produite pendant le retour en saisie',
    arrivalDateAfterReadyDate: 'La date d’arrivée doit être plus petite ou égale à la date prête',
    clientIsRequired: 'Veuillez sélectionner un client',
    worksiteIsRequired: 'Veuillez sélectionner un chantier',
  },
  field: {
    accessory: {
      accessoryLine_one: "{{ count }} ligne d'accessoire",
      accessoryLine_other: "{{ count }} lignes d'accessoire",
      accessory: 'Accessoire',
      billed: 'Facturé',
      noAccessory: 'Aucun accessoire',
      overline: 'Accessoires',
      qty: 'Qté.',
      type: 'Type d’accessoire',
      billable: 'Facturable',
      quantity: 'Quantité',
      rate: 'Taux',
    },
    addenda: {
      addenda_one: '{{ count }} addenda',
      addenda_other: '{{ count }} addendas',
      title: 'Titre',
      kind: 'Type',
      description: 'Description',
    },
    attachment: {
      attachment_one: '{{ count }} pièce jointe',
      attachment_other: '{{ count }} pièces jointes',
    },
    comment: {
      supervisorComment: 'Commentaire',
    },
    cost: {
      costLine_one: '{{ count }} ligne de coût',
      costLine_other: '{{ count }} lignes de coût',
      overline: 'Coûts',
      billingCode: 'Nom du code de facturation',
      billable: 'Facturable',
      crane: 'Grue',
      workForceType: 'Main d’oeuvre',
      labor: 'Main d’oeuvre',
      noLine: 'Aucune ligne',
      quantity: 'Quantité',
      qty: 'Qté.',
      rate: 'Taux',
      rateUpdateReason: 'Raison du changement de taux',
    },
    client: {
      inTheCareOf: 'A/S',
      orderNumber: '# Commande',
      projectManager: 'Chargé de projet',
      requirement: 'Exigences',
    },
    equipment: {
      kind: 'Genre d’équipement',
      type: 'Genre',
      boomConfiguration: 'Configuration de flèche',
      craneConfiguration: 'Configuration de grue',
      craneSelectionMode: 'Sélection de la grue',
      lifts: 'Levages',
      configurationList: 'Liste de configurations',
      configuration: 'Configuration',
      otherInformation: 'Autres informations',
      transport: {
        transportKind: 'Type de transport',
        segments: {
          kind: 'Type de segment',
          craneSpeed: '{{ crane }} Vitesse',
          craneDistance: '{{ crane }} Distance',
          primaryCraneSpeed: '$t(field.equipment.transport.segments.craneSpeed, {"crane": "P"})',
          primaryCraneDistance: '$t(field.equipment.transport.segments.craneDistance, {"crane": "P"})',
          additionalCraneSpeed: '$t(field.equipment.transport.segments.craneSpeed, {"crane": "A{{ craneIndex }}"})',
          additionalCraneDistance: '$t(field.equipment.transport.segments.craneDistance, {"crane": "A{{ craneIndex }}"})',
        },
        craneTransportRate: 'Principale',
        additionalCraneTransportRate: 'Additionnelle {{ craneIndex }}',
        boomCounterweightTransport: 'Transport de flèche et contrepoids',
        flatbedTransport: 'Transport par fardier',
      },
    },
    project: {
      branch: {
        dispatchBranch: 'Succursale de répartition',
        overline: 'Succursales',
        salesBranch: 'Succursale de ventes',
      },
      date: {
        arrival: 'Date d’arrivée',
        date: 'Date',
        departure: 'Date de départ',
        duration: 'Durée des travaux',
        durationPerDay: 'Durée par jour',
        isSecondServing: '2e tablée',
        includeWeekendsAndHolidays: 'Inclure fin de semaine et fériés',
        notes: 'Notes et informations complémentaires',
        forbidOvertime: 'Aucun temps supplémentaire',
        ready: 'Date prête',
      },
      work: {
        isEstablishedSchedule: 'Horaire établi',
        natureOfWork: 'Nature du travail',
        natureOfWorkSubCategory: 'Catégorie',
        overline: 'Travail',
        workDescription: 'Description du travail',
        workSchedule: 'Horaire de travail',
      },
    },
    questions: {
      hasParticularSituation_one: '{{ count }} situation particulière',
      hasParticularSituation_other: '{{ count }} situations particulières',
      hasNoParticularSituation: 'Aucune situation particulière',
      worksiteVisited: 'Chantier visité',
      worksiteNotVisited: 'Chantier non visité',
    },
    costs: {
      goToDesktop: 'Poursuivre sur l’application web pour accéder à la calculette de soumission',
    },
  },
  info: 'Infos',
  kind: {
    accessoriesRental: 'Location d’accessoires',
    bare: 'Grue non-opérée',
    bareWithOperators: 'Grue non-opérée avec opérateurs',
    laborRental: 'Location de main d’oeuvre',
    liftingPlan: 'Plan de levage',
    liftingTest: 'Test de levage',
    liftingTestOperated: 'Test de levage opéré',
    operatedHourly: 'Grue opérée (à l’heure)',
    operatedMonthly: 'Grue opérée (mensuelle)',
    rollingEquipment: 'Matériel roulant',
    storage: 'Entreposage',
  } satisfies Record<QuoteKind, string>,
  kindShort: {
    accessoriesRental: 'Location d’accessoires',
    bare: 'Non-opérée',
    bareWithOperators: 'Non-opérée avec opérateurs',
    laborRental: 'Location de main d’oeuvre',
    liftingPlan: 'Plan de levage',
    liftingTest: 'Test de levage',
    liftingTestOperated: 'Test de levage opéré',
    operatedHourly: 'Opérée (à l’heure)',
    operatedMonthly: 'Opérée (mensuelle)',
    rollingEquipment: 'Matériel roulant',
    storage: 'Entreposage',
  } satisfies Record<QuoteKind, string>,
  list: {
    column: {
      friendlyId: 'ID',
      client: 'Client',
      worksite: 'Chantier',
      kind: 'Type',
      crane: 'Grue',
      status: 'Statut',
      date: 'Date',
    },
  },
  pages: {
    accessories: {
      title: 'Accessoires',
    },
    addenda: {
      title: 'Addenda',
    },
    addendaAndAttachments: {
      title: 'Addenda et pièces jointes',
    },
    comment: {
      title: 'Commentaire',
    },
    costLine: {
      title: 'Lignes de coût',
    },
    client: {
      title: 'Informations du client',
      requirementDescriptionsTitle: 'Exigences client',
      subsection: {
        management: 'Gestion',
      },
    },
    details: {
      copyTitle: 'Copier la soumission',
      copyTitleShort: 'Copier la soumission',
      shortTitle: 'Soumission',
      title: 'Soumission',
    },
    list: {
      tabs: {
        myClients: 'Mes clients',
        myJobs: 'Mes soumissions',
        myJobsShort: 'Mes soums.',
        myRegion: 'Ma région',
      },
    },
    project: {
      title: 'Informations du project',
      subsection: {
        branches: 'Succursales',
        dateAndTime: 'Dates et heures',
        work: 'Travail',
        worksite: 'Chantier',
      },
    },
    questions: {
      title: 'Questions',
    },
    equipment: {
      title: 'Informations de l’équipement',
      subsection: {
        craneSelector: 'Sélecteur de grue',
      },
    },
    costs: {
      title: 'Informations des coûts',
      subsection: {
        additionalCranes: 'Grues additionnelles',
        mainCrane: 'Grue principale',
        emptyCraneConfiguration: 'Aucune configuration sélectionnée',
      },
    },
  },
  placeholder: {
    accessoryInfo: 'Sélectionnez des accessoires (facultatif)',
    accessoryOutOfInventory: 'Écrire l’accessoire',
    addAddenda: 'Ajouter un addenda',
    addendaInfo: 'Ajouter des addenda (facultatif)',
    clientInfo: 'Entrez les informations du client',
    equipmentInfo: 'Entrez les informations de l’équipement',
    projectInfo: 'Entrez les informations du projet',
    questionsInfo: 'Répondre aux questions de base',
    costsInfo: 'Entrez les informations des coûts',
    selectConfiguration: 'Sélectionner une configuration',
  },
  section: {
    addenda: 'Addenda',
    addendaAndAttachments: 'Addendas et pièces jointes',
    calculator: {
      action: {
        calculate: 'Calculer',
        refresh: 'Recalculer',
        apply: 'Appliquer',
      },
      additionalFees: {
        header: 'Frais additionnels',
      },
      adjustments: {
        header: 'Ajustements',
        segmentKind: {
          departure: 'Aller',
          return: 'Retour',
          all: 'Tous',
        } satisfies Record<AdjustmentSegmentKind, string>,
        unit: {
          amount: 'Montant',
          percentage: 'Pourcentage',
        } satisfies Record<AdjustmentUnit, string>,
      },
      assemblyDisassembly: {
        header: 'Montage et démontage',
        kind: {
          departure: '{{ crane }} - Montage {{ kind }}',
          return: '{{ crane }} - Démontage {{ kind }}',
        } satisfies Record<TransportSegmentKind, string>,
      },
      departureTotal: 'Montant aller',
      description: 'Description',
      descriptionPlaceholder: 'Écrire la description',
      distance: 'Distance',
      flatbedBoomCounterweightTransport: {
        header: 'Transport par fardier et transport de flèche et contrepoids',
        kind: {
          flatbed: '{{ crane }} - {{ description }} - {{ transportSegmentKind }}',
          boomAndCounterweightFirstTrip: '{{ crane }} - {{ description }} - V1 - {{ transportSegmentKind }}',
          boomAndCounterweightOtherTrips: '{{ crane }} - {{ description }} - V2+ - {{ transportSegmentKind }}',
        } satisfies Record<CalculatorFlatbedBoomCounterweightTransportEntryKind, string>,
      },
      header: 'Calculette',
      hours: 'Heures',
      loadingUnloading: {
        header: 'Chargement et déchargement',
        kind: {
          loading: 'Chargement',
          unloading: 'Déchargement',
        } satisfies Record<LoadingUnloading, string>,
      },
      minimumPrice: 'Charge min.',
      mobileCraneTransport: {
        header: 'Transport de la grue mobile',
        kind: {
          mainCrane: {
            departure: 'P - Aller',
            return: 'P - Retour',
          } satisfies Record<TransportSegmentKind, string>,
          additionalCrane: {
            departure: 'A{{ index }} - Aller',
            return: 'A{{ index }} - Retour',
          } satisfies Record<TransportSegmentKind, string>,
        },
      },
      otherTransport: {
        header: 'Transport autres',
      },
      placeholder: 'Appuyer sur le bouton calculer pour activer la calculette de soumission',
      pleaseRecalculate: 'Attention, un recalcul est requis',
      quantity: 'Quantité',
      rate: 'Taux',
      returnTotal: 'Montant retour',
      segmentKind: 'Type de segment',
      speed: 'Vitesse',
      subTotal: 'Sous-total',
      supplementalEquipmentAndWorkForce: {
        header: "Équipement et main d'oeuvre supplémentaire",
      },
      surcharge: 'Surcharge',
      total: 'Total',
      travellingExpensesFee: 'Frais Km',
      type: 'Type',
      unit: 'Unité',
      value: 'Valeur',
    },
    client: 'Client',
    comment: 'Commentaire',
    costLines: 'Lignes de coût',
    project: 'Projet',
    questions: 'Questions',
    equipment: 'Équipement',
    accessories: 'Accessoires',
    costs: 'Coûts',
    transport: {
      header: 'Transport',
      segments: 'Segments',
      craneTransportRate: 'Taux de grue',
      transportRate: 'Taux de transport',
    },
  },
  quotes: 'Soumissions',
  status: {
    accepted: 'Accepté',
    canceled: 'Annulé',
    inWriting: 'En saisie',
    declined: 'Décliné',
    revising: 'En revue',
    inApproval: 'En approbation',
    awaitingClient: 'Attente client',
    locked: 'Verrouillé',
  } satisfies Record<QuoteStatus, string>,
  transportKind: {
    crane: 'Transport de grue à prix fixe',
    jibAndCounterweight: 'Transport de flèche et contrepoids',
    mobilisationAndDemobilisation: 'Mobilisation et démobilisation',
  } satisfies Record<TransportKind, string>,
  transportSegmentKind: {
    departure: 'Aller',
    return: 'Retour',
  } satisfies Record<TransportSegmentKind, string>,
};
