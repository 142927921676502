/**
 * @generated SignedSource<<e6066bfed7c8aec9da54928214f1cda8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignedWorksite_useAssignedWorksiteFragment$data = {
  readonly clientBase: {
    readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_useUpdateWorksite_ClientFragment" | "ProjectBaseFields_WorksiteOverridesAddress_ClientFragment" | "ProjectBaseFields_WorksiteOverridesName_ClientFragment">;
  };
  readonly projectBase: {
    readonly assignedWorksite: {
      readonly requirements: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_RequirementsFragment">;
      }>;
    } | null | undefined;
    readonly assignedWorksiteInfo: {
      readonly location: {
        readonly structuredAddress: {
          readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesApartmentFragment" | "ProjectBaseFields_WorksiteOverridesCityFragment" | "ProjectBaseFields_WorksiteOverridesCoordinatesFragment" | "ProjectBaseFields_WorksiteOverridesPostalCodeFragment" | "ProjectBaseFields_WorksiteOverridesStateFragment">;
        } | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesAddressFragment">;
      } | null | undefined;
      readonly requirements: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_RequirementsFragment">;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesNameFragment" | "ProjectBaseFields_WorksiteOverrides_ContactFragment" | "ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_useUpdateWorksiteFragment" | "ProjectBaseFields_AssignedWorksiteFragment">;
  };
  readonly " $fragmentType": "AssignedWorksite_useAssignedWorksiteFragment";
};
export type AssignedWorksite_useAssignedWorksiteFragment$key = {
  readonly " $data"?: AssignedWorksite_useAssignedWorksiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_useAssignedWorksiteFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "Requirement",
  "kind": "LinkedField",
  "name": "requirements",
  "plural": true,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_RequirementsFragment"
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignedWorksite_useAssignedWorksiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectInternalBase",
      "kind": "LinkedField",
      "name": "projectBase",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssignedWorksite_useUpdateWorksiteFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Worksite",
          "kind": "LinkedField",
          "name": "assignedWorksite",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_AssignedWorksiteFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WorksiteInfoInternal",
          "kind": "LinkedField",
          "name": "assignedWorksiteInfo",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectBaseFields_WorksiteOverridesNameFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectBaseFields_WorksiteOverrides_ContactFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment"
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Location",
              "kind": "LinkedField",
              "name": "location",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectBaseFields_WorksiteOverridesAddressFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StructuredAddressResult",
                  "kind": "LinkedField",
                  "name": "structuredAddress",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProjectBaseFields_WorksiteOverridesCoordinatesFragment"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProjectBaseFields_WorksiteOverridesCityFragment"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProjectBaseFields_WorksiteOverridesApartmentFragment"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProjectBaseFields_WorksiteOverridesStateFragment"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProjectBaseFields_WorksiteOverridesPostalCodeFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientInternalBase",
      "kind": "LinkedField",
      "name": "clientBase",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_WorksiteOverridesName_ClientFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_WorksiteOverridesAddress_ClientFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssignedWorksite_useUpdateWorksite_ClientFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IJobStage",
  "abstractKey": "__isIJobStage"
};
})();

(node as any).hash = "70978e64c940d595bf98a9216b89413f";

export default node;
