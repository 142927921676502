import graphql from 'babel-plugin-relay/macro';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { ElementType, forwardRef, ReactElement, Ref, RefAttributes, useCallback } from 'react';
import { ChipTypeMap } from '@mui/material';
import {
  AutocompleteMetadata,
  ConnectionNode,
  ConnectionPaginatedAutocomplete,
  ForwardPaginatedAutocompleteProps,
  Queryable,
} from './PaginatedAutocomplete';
import { useTranslation } from 'react-i18next';
import { convertToTsQuery } from '../utils/stringUtils';
import { AddendumTemplateAutocompleteListFragment$data } from './__generated__/AddendumTemplateAutocompleteListFragment.graphql';
import { AddendumTemplateAutocompleteQuery } from './__generated__/AddendumTemplateAutocompleteQuery.graphql';

export type ForwardAddendumTemplateAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = ForwardPaginatedAutocompleteProps<
  ConnectionNode<AddendumTemplateAutocompleteListFragment$data>,
  'fragment' | 'onQuery' | 'getOptionLabel',
  Multiple,
  DisableClearable,
  ChipComponent
>;

export const AddendumTemplateAutocomplete = forwardRef<
  HTMLInputElement,
  ForwardPaginatedAutocompleteProps<
    ConnectionNode<AddendumTemplateAutocompleteListFragment$data> & AutocompleteMetadata,
    'fragment' | 'onQuery' | 'getOptionLabel',
    boolean,
    boolean,
    ElementType
  >
>(function AddendumTemplateAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    ...paginatedAutocompleteProps
  }: ForwardPaginatedAutocompleteProps<
    ConnectionNode<AddendumTemplateAutocompleteListFragment$data> & AutocompleteMetadata,
    'fragment' | 'onQuery' | 'getOptionLabel',
    Multiple,
    DisableClearable,
    ChipComponent
  >,
  ref: Ref<HTMLInputElement>,
) {
  const { t } = useTranslation('common');
  const env = useRelayEnvironment();

  const handleQuery = useCallback(
    (searchTerm: string | null) =>
      fetchQuery<AddendumTemplateAutocompleteQuery>(
        env,
        graphql`
          query AddendumTemplateAutocompleteQuery($clientCulture: String, $searchTerm: String) {
            ...AddendumTemplateAutocompleteListFragment @arguments(clientCulture: $clientCulture, searchTerm: $searchTerm)
          }
        `,
        {
          searchTerm: convertToTsQuery(searchTerm),
        },
      ),
    [env],
  );
  return (
    <ConnectionPaginatedAutocomplete<
      ConnectionNode<AddendumTemplateAutocompleteListFragment$data> & AutocompleteMetadata,
      Multiple,
      DisableClearable,
      ChipComponent
    >
      ref={ref as Ref<HTMLInputElement & Queryable>}
      fragment={graphql`
        fragment AddendumTemplateAutocompleteListFragment on Query
        @refetchable(queryName: "addendumTemplateAutocomplete2ListFragmentQuery")
        @argumentDefinitions(
          clientCulture: { type: "String" }
          searchTerm: { type: "String" }
          cursor: { type: "String" }
          count: { type: "Int", defaultValue: 25 }
        ) {
          searchResults: searchAddendumTemplates(searchTerm: $searchTerm, clientCulture: $clientCulture, after: $cursor, first: $count)
            @connection(key: "addendumTemplateAutocomplete2ListFragment_searchResults") {
            edges {
              node {
                id
                label
                kind
              }
            }
          }
        }
      `}
      onQuery={handleQuery}
      groupBy={(option) => t(`addendumKind.${option.kind}`, { ns: 'jobs' })}
      textFieldProps={(params) => ({ ...params, label: t('field.addendumTemplates') })}
      {...paginatedAutocompleteProps}
    />
  );
}) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: ForwardPaginatedAutocompleteProps<
    ConnectionNode<AddendumTemplateAutocompleteListFragment$data>,
    'fragment' | 'onQuery' | 'getOptionLabel',
    Multiple,
    DisableClearable,
    ChipComponent
  > &
    RefAttributes<HTMLInputElement>,
) => ReactElement;
