// Types for messages sent by clients (windows) to the service worker

export enum ClientMessageTypes {
  Update = 'appUpdate.update',
  HasOtherWindowsQuery = 'appUpdate.hasOtherWindowsQuery',
}

export interface ClientMessages {
  [ClientMessageTypes.Update]: { readonly hardMode: boolean };
  [ClientMessageTypes.HasOtherWindowsQuery]: { readonly queryId: string };
}

export type ClientMessage = {
  [Type in keyof ClientMessages]: { readonly type: Type; readonly payload: ClientMessages[Type] };
}[keyof ClientMessages];

// Types for messages sent by the service worker to clients (windows)

export enum ServiceWorkerMessageTypes {
  UpdateStarted = 'appUpdate.updateStarted',
  UpdateFailure = 'appUpdate.updateFailure',
  HardUpdateSuccess = 'appUpdate.hardUpdateSuccess',
  HasOtherWindowsResponse = 'appUpdate.hasOtherWindowsResponse',
}

export interface ServiceWorkerMessages {
  [ServiceWorkerMessageTypes.UpdateStarted]: { readonly hardMode: boolean };
  [ServiceWorkerMessageTypes.UpdateFailure]: { readonly error: unknown };
  [ServiceWorkerMessageTypes.HardUpdateSuccess]: void;
  [ServiceWorkerMessageTypes.HasOtherWindowsResponse]: { readonly queryId: string; readonly hasOtherWindows: boolean };
}

export type ServiceWorkerMessage = {
  [Type in keyof ServiceWorkerMessages]: { readonly type: Type; readonly payload: ServiceWorkerMessages[Type] };
}[keyof ServiceWorkerMessages];
