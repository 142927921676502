/**
 * @generated SignedSource<<3dbb91720bb4dea5caaaa9997199e6bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_WorksiteOverridesCoordinatesFragment$data = {
  readonly value: {
    readonly coordinates: {
      readonly latitude: number;
      readonly longitude: number;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ProjectBaseFields_WorksiteOverridesCoordinatesFragment";
};
export type ProjectBaseFields_WorksiteOverridesCoordinatesFragment$key = {
  readonly " $data"?: ProjectBaseFields_WorksiteOverridesCoordinatesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesCoordinatesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_WorksiteOverridesCoordinatesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StructuredAddressData",
      "kind": "LinkedField",
      "name": "value",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Coordinates",
          "kind": "LinkedField",
          "name": "coordinates",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latitude",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "longitude",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StructuredAddressResult",
  "abstractKey": null
};

(node as any).hash = "83b5d953fa06a9eda9e6fbc1ba81c7c1";

export default node;
