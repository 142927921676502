/**
 * @generated SignedSource<<bef62ddef04e4b109e9a28acf0460197>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useFavoriteEquipment_favoriteAutomaticEquipmentFragment$data = {
  readonly boomConfigurationId: string;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_useSynchronizeFavoriteEquipments_FromQueryPassthroughFragment">;
  readonly " $fragmentType": "useFavoriteEquipment_favoriteAutomaticEquipmentFragment";
};
export type useFavoriteEquipment_favoriteAutomaticEquipmentFragment$key = {
  readonly " $data"?: useFavoriteEquipment_favoriteAutomaticEquipmentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_favoriteAutomaticEquipmentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useFavoriteEquipment_favoriteAutomaticEquipmentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boomConfigurationId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFavoriteEquipment_useSynchronizeFavoriteEquipments_FromQueryPassthroughFragment"
    }
  ],
  "type": "AutomaticConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "185a2e025a04729cc9cc63384ca00d1d";

export default node;
