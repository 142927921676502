/**
 * @generated SignedSource<<54506c9d90dddcd2ce37f8b8d6f0b6f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_QueriedCopyClientAddressButtonFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_CopyClientAddressButtonFragment">;
  readonly " $fragmentType": "ProjectBaseFields_QueriedCopyClientAddressButtonFragment";
};
export type ProjectBaseFields_QueriedCopyClientAddressButtonFragment$key = {
  readonly " $data"?: ProjectBaseFields_QueriedCopyClientAddressButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_QueriedCopyClientAddressButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_QueriedCopyClientAddressButtonFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_CopyClientAddressButtonFragment"
    }
  ],
  "type": "StructuredAddressResult",
  "abstractKey": null
};

(node as any).hash = "dcc26cbff66bc2c4fe51630b461a4ed4";

export default node;
