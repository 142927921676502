import { ClientMessages, ClientMessageTypes, ServiceWorkerMessage, ServiceWorkerMessageTypes } from './types';

export type ServiceWorkerMessageEvent = MessageEvent<ServiceWorkerMessage>;

export type ServiceWorkerMessageEventListener = (event: ServiceWorkerMessageEvent) => void;

const isServiceWorkerMessageEvent = (event: MessageEvent): event is ServiceWorkerMessageEvent =>
  event.source instanceof ServiceWorker && Object.values(ServiceWorkerMessageTypes).includes(event.data?.type) && 'payload' in event.data;

export const onServiceWorkerMessage = (listener: ServiceWorkerMessageEventListener): (() => void) => {
  const wrappedListener = (event: MessageEvent) => isServiceWorkerMessageEvent(event) && listener(event);
  navigator.serviceWorker?.addEventListener('message', wrappedListener);
  return () => navigator.serviceWorker?.removeEventListener('message', wrappedListener);
};

export const sendMessageToServiceWorker = <T extends ClientMessageTypes>(type: T, payload: ClientMessages[T]): boolean => {
  if (!navigator.serviceWorker.controller) return false;
  navigator.serviceWorker.controller.postMessage({ type, payload });
  return true;
};
