import { Button } from '@mui/material';
import { Update } from '@mui/icons-material';
import { useAppUpdateDeadlineCountdown } from './useAppUpdateDeadlineCountdown';
import { dispatchCustomEvent } from '../common/utils/customEvents';

export function AppUpdateToolbarButton() {
  const countdown = useAppUpdateDeadlineCountdown();

  if (!countdown) return;

  return (
    <Button startIcon={<Update />} variant='outlined' color='warning' onClick={() => dispatchCustomEvent('togglePendingAppUpdateSnackbar')}>
      {countdown}
    </Button>
  );
}
