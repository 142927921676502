/**
 * @generated SignedSource<<4463bd5ee1150840d8509a4e1ef93c92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type JibKind = "fixedLattice" | "foldable" | "variableSpanLattice" | "variableSpanLatticeWithOffset" | "%future added value";
import { FragmentRefs } from "relay-runtime";
import { label as vehicleIdLabelResolverType } from "../../__resolvers__/VehicleIdLabelResolver";
// Type assertion validating that `vehicleIdLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(vehicleIdLabelResolverType satisfies (
  rootKey: VehicleIdLabelResolver$key,
) => string | null | undefined);
export type useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromInitialValuesFragment$data = {
  readonly additionalConfigurations: ReadonlyArray<{
    readonly boomConfiguration: {
      readonly boomLength: string;
      readonly counterweight: string;
      readonly id: string;
      readonly jibLength: string;
      readonly vehicleIds: ReadonlyArray<{
        readonly key: string;
        readonly label: string | null | undefined;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_extraDataFragment">;
    } | null | undefined;
    readonly deletedAt: string | null | undefined;
  }>;
  readonly selectedMatchingConfiguration: {
    readonly boomConfiguration: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_extraDataFragment">;
    };
    readonly boomConfigurationName: string | null | undefined;
    readonly boomExtensionLength: string | null | undefined;
    readonly boomLength: string;
    readonly counterweight: string;
    readonly isFavorite: boolean;
    readonly jib: {
      readonly offsetAngle: string;
    } | null | undefined;
    readonly jibExtensionLength: string;
    readonly jibKind: JibKind | null | undefined;
    readonly jibLength: string;
    readonly maxWeight: string;
    readonly radius: string;
    readonly vehicleId: {
      readonly key: string;
      readonly label: string | null | undefined;
    };
  } | null | undefined;
  readonly " $fragmentType": "useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromInitialValuesFragment";
};
export type useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromInitialValuesFragment$key = {
  readonly " $data"?: useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromInitialValuesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromInitialValuesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "boomLength",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jibLength",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "counterweight",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v4 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "VehicleIdLabelResolver"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "useFavoriteEquipment_extraDataFragment"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromInitialValuesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomaticConfigurationInfo",
      "kind": "LinkedField",
      "name": "selectedMatchingConfiguration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFavorite",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "boomConfigurationName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "boomExtensionLength",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jibExtensionLength",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "radius",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxWeight",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "VehicleId",
          "kind": "LinkedField",
          "name": "vehicleId",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "fragment": (v4/*: any*/),
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/VehicleIdLabelResolver').label,
              "path": "selectedMatchingConfiguration.vehicleId.label"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jibKind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "jib",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "offsetAngle",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "concreteType": "BoomConfigurationSnapshot",
            "kind": "LinkedField",
            "name": "boomConfiguration",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          "action": "THROW"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdditionalConfigurationInfo",
      "kind": "LinkedField",
      "name": "additionalConfigurations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deletedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BoomConfigurationSnapshot",
          "kind": "LinkedField",
          "name": "boomConfiguration",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "VehicleId",
              "kind": "LinkedField",
              "name": "vehicleIds",
              "plural": true,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "fragment": (v4/*: any*/),
                  "kind": "RelayResolver",
                  "name": "label",
                  "resolverModule": require('./../../__resolvers__/VehicleIdLabelResolver').label,
                  "path": "additionalConfigurations.boomConfiguration.vehicleIds.label"
                }
              ],
              "storageKey": null
            },
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AutomaticConfigurationInternal",
  "abstractKey": null
};
})();

(node as any).hash = "47f782c01884d0f8c160fb9d9e81ead2";

export default node;
