import * as Sentry from '@sentry/react';

export const logLevels = ['trace', 'debug', 'log', 'info', 'warn', 'error'] as const;
export type LogLevel = (typeof logLevels)[number];
export const logLevelOrder: Readonly<Record<LogLevel, number>> = logLevels.reduce(
  (acc, level, index) => Object.assign(acc, { [level]: index }),
  {} as Record<LogLevel, number>,
);

export type GlobalLogLevel = { LOG_LEVEL?: LogLevel };

/** A helper type to make sure the {@link Logger} class implements a logging method for each log level. */
type LoggerLogLevelMethods = {
  [K in LogLevel]: (message?: unknown, ...optionalParams: unknown[]) => void;
};

/**
 * A simple logger class that can be used to log messages to the console or other sinks.
 *
 * The constructor accepts two arguments: a logger name, and an optional list of sinks. If no sinks are provided, the
 * logger will try to find a parent logger with sinks, and use those. A parent logger is defined as a logger whose name
 * starts with a prefix of the child logger's name, when split on `'.'` characters. If no parent logger has sinks, the
 * logger will default to logging to both the console and to Sentry (although only `'warn'` and `'error'` log levels are
 * forwarded to Sentry). The default should be suitable for most use cases.
 */
export class Logger implements LoggerLogLevelMethods {
  private static readonly loggers = new Map<string, Logger>();

  private _minLevel: LogLevel | undefined;
  get minLevel(): LogLevel {
    return this._minLevel ?? (window as GlobalLogLevel)?.LOG_LEVEL ?? (process.env.NODE_ENV === 'development' ? 'trace' : 'info');
  }
  set minLevel(value: LogLevel) {
    this._minLevel = value;
  }

  constructor(
    private readonly name: string,
    private readonly sinks?: readonly Sink[],
  ) {
    if (Logger.loggers.has(name)) {
      loggerLogger.warn(`Logger ${JSON.stringify(name)} already exists; overwriting it.`);
    }
    Logger.loggers.set(name, this);
  }

  trace(message?: unknown, ...optionalParams: unknown[]): void {
    this.forward('trace', [message, ...optionalParams]);
  }

  debug(message?: unknown, ...optionalParams: unknown[]): void {
    this.forward('debug', [message, ...optionalParams]);
  }

  log(message?: unknown, ...optionalParams: unknown[]): void {
    this.forward('log', [message, ...optionalParams]);
  }

  info(message?: unknown, ...optionalParams: unknown[]): void {
    this.forward('info', [message, ...optionalParams]);
  }

  warn(message?: unknown, ...optionalParams: unknown[]): void {
    this.forward('warn', [message, ...optionalParams]);
  }

  error(message?: unknown, ...optionalParams: unknown[]): void {
    this.forward('error', [message, ...optionalParams]);
  }

  group(...label: unknown[]): void {
    this.forward('group', label);
  }

  groupCollapsed(...label: unknown[]): void {
    this.forward('groupCollapsed', label);
  }

  groupEnd(): void {
    this.forward('groupEnd', []);
  }

  private forward(level: LogLevel | 'group' | 'groupCollapsed' | 'groupEnd', params: unknown[]): void {
    if (level === 'group' || level === 'groupCollapsed' || level === 'groupEnd') {
      for (const sink of this.getSinks()) {
        sink[level](this.name, ...params);
      }
    } else if (logLevelOrder[level] >= logLevelOrder[this.minLevel]) {
      for (const sink of this.getSinks()) {
        sink.handleLog(this.name, level, params);
      }
    }
  }

  private getSinks(): readonly Sink[] {
    if (this.sinks) return this.sinks;

    const nameParts = this.name.split('.').slice(0, -1);
    while (nameParts.length) {
      const parent = Logger.loggers.get(nameParts.join('.'));
      if (parent?.sinks) return parent.sinks;
      nameParts.pop();
    }

    return [consoleSink, sentrySink];
  }
}

const loggerLogger = new Logger('Logger');

export interface Sink {
  handleLog(loggerName: string, level: LogLevel, params: unknown[]): void;
  group(loggerName: string, ...label: unknown[]): void;
  groupCollapsed(loggerName: string, ...label: unknown[]): void;
  groupEnd(loggerName: string): void;
}

export const consoleSink: Sink = {
  handleLog(loggerName, level, params) {
    console[level](`[${loggerName}]`, ...params);
  },
  group(loggerName, ...label) {
    console.group(`[${loggerName}]`, ...label);
  },
  groupCollapsed(loggerName, ...label) {
    console.groupCollapsed(`[${loggerName}]`, ...label);
  },
  groupEnd() {
    console.groupEnd();
  },
};

const sentrySeverityLevelFromLogLevel: Readonly<Record<LogLevel, Sentry.SeverityLevel>> = {
  trace: 'debug',
  debug: 'debug',
  // Note: our 'info' log level is more severe than 'log', but for Sentry it's the other way around. We still map them
  // 1:1 for simplicity, but this could lead to unexpected results when combining a minimum log level with Sentry
  // logging. As of writing, the Sentry sink only processes 'warn' and 'error' logs, so this isn't an issue.
  log: 'log',
  info: 'info',
  warn: 'warning',
  error: 'error',
};

export const sentrySink: Sink = {
  handleLog(loggerName, level, params) {
    // Don't log anything less severe than 'warn' to Sentry to avoid getting alerts for non-error logs
    if (logLevelOrder[level] < logLevelOrder['warn']) return;
    const error = params.find((p) => p instanceof Error);
    if (error) {
      Sentry.captureException(error, {
        level: sentrySeverityLevelFromLogLevel[level],
        // Strip the captured exception from the params to avoid sending it to sentry twice, saving some space & money
        extra: Object.fromEntries(Object.entries(params.map((p) => (p === error ? '[captured exception]' : p)))),
      });
    } else {
      Sentry.captureMessage(`[${loggerName}] ${params.join(' ')}`, {
        level: sentrySeverityLevelFromLogLevel[level],
        extra: Object.fromEntries(Object.entries(params)),
      });
    }
  },
  group() {
    // Not implemented
  },
  groupCollapsed() {
    // Not implemented
  },
  groupEnd() {
    // Not implemented
  },
};
