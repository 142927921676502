const button = {
  copyFromClient: 'Copier l’adresse du client',
  selectWorksite: 'Sélectionnez un chantier',
};

const dialog = {
  addWorksite: 'Nouveau chantier',
  selectWorksite: 'Sélectionner un chantier',
  noResult: 'Aucun résultat trouvé',
  automaticSuggestions: 'Suggestions automatiques',
  allWorksites: 'Tous les chantiers',
  clientWorksites: 'Chantiers du client',
  requirementsTitle: 'Exigences du chantier',
};

const error = {
  worksiteIsRequired: 'Veuillez sélectionner un chantier',
  errorWhileLoading: 'Une erreur est survenue en chargeant le chantier',
};

const field = {
  address: 'Adresse du chantier',
  apartment: 'Appartement',
  city: 'Ville',
  province: 'Province',
  postalCode: 'Code postal',
  contact: 'Sur le chantier voir',
  name: 'Nom du chantier',
  phoneNumber: 'Téléphone',
  requirement: 'Exigences du chantier',
};

const placeholder = {
  noWorksiteSelected: 'Aucun',
};

export const worksite = {
  button,
  dialog,
  error,
  field,
  new: 'Nouveau',
  placeholder,
  work: 'Chantier',
};
