/**
 * @generated SignedSource<<962f6399c8f14d1c56f42db21f31b932>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_WorksiteOverridesName_ReadFragment$data = {
  readonly name: string | null | undefined;
  readonly " $fragmentType": "ProjectBaseFields_WorksiteOverridesName_ReadFragment";
};
export type ProjectBaseFields_WorksiteOverridesName_ReadFragment$key = {
  readonly " $data"?: ProjectBaseFields_WorksiteOverridesName_ReadFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesName_ReadFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_WorksiteOverridesName_ReadFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "IOverridableWorksite",
  "abstractKey": "__isIOverridableWorksite"
};

(node as any).hash = "6ee61afc1dc9f123495b0b3a42507725";

export default node;
