/**
 * @generated SignedSource<<1746c4b9542236818ec9efc79447be4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useFavoriteEquipment_useUpdateBoomConfigurationInfoFragment$data = {
  readonly boomLength: string;
  readonly counterweight: string;
  readonly jibLength: string;
  readonly " $fragmentType": "useFavoriteEquipment_useUpdateBoomConfigurationInfoFragment";
};
export type useFavoriteEquipment_useUpdateBoomConfigurationInfoFragment$key = {
  readonly " $data"?: useFavoriteEquipment_useUpdateBoomConfigurationInfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_useUpdateBoomConfigurationInfoFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useFavoriteEquipment_useUpdateBoomConfigurationInfoFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boomLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jibLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "counterweight",
      "storageKey": null
    }
  ],
  "type": "BoomConfigurationSnapshot",
  "abstractKey": null
};

(node as any).hash = "ac8ddcfcfb8e71a69f66cffd780d2549";

export default node;
