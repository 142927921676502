/**
 * @generated SignedSource<<b4561c3dde16bd7d8da0cf1a3383fc2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as boomConfigurationSnapshotLabelResolverType } from "../../__resolvers__/BoomConfigurationSnapshotLabelResolver";
// Type assertion validating that `boomConfigurationSnapshotLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(boomConfigurationSnapshotLabelResolverType satisfies (
  rootKey: BoomConfigurationSnapshotLabelResolver$key,
) => string | null | undefined);
import { label as configurationKindLookupLabelResolverType } from "../../__resolvers__/ConfigurationKindLookupLabelResolver";
// Type assertion validating that `configurationKindLookupLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(configurationKindLookupLabelResolverType satisfies (
  rootKey: ConfigurationKindLookupLabelResolver$key,
) => Record<string, string> | null | undefined);
import { label as craneCapacityLabelResolverType } from "../../__resolvers__/CraneCapacityLabelResolver";
// Type assertion validating that `craneCapacityLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(craneCapacityLabelResolverType satisfies (
  rootKey: CraneCapacityLabelResolver$key,
) => string | null | undefined);
import { label as equipmentKindLookupLabelResolverType } from "../../__resolvers__/EquipmentKindLookupLabelResolver";
// Type assertion validating that `equipmentKindLookupLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(equipmentKindLookupLabelResolverType satisfies (
  rootKey: EquipmentKindLookupLabelResolver$key,
) => Record<string, string> | null | undefined);
export type AdditionalCranesFields_AdditionalCranesManualCollectionFragment$data = {
  readonly additionalConfigurations: ReadonlyArray<{
    readonly boomConfiguration: {
      readonly id: string;
      readonly label: string | null | undefined;
      readonly preparationHours: number;
      readonly vehicleIds: ReadonlyArray<{
        readonly key: string;
        readonly make: string | null | undefined;
        readonly model: string | null | undefined;
      }>;
    } | null | undefined;
    readonly capacity: {
      readonly capacity: number;
      readonly label: string | null | undefined;
    } | null | undefined;
    readonly configurationKind: {
      readonly code: number;
      readonly id: string;
      readonly label: Record<string, string> | null | undefined;
    } | null | undefined;
    readonly deletedAt: string | null | undefined;
    readonly equipmentKind: {
      readonly code: number;
      readonly id: string;
      readonly label: Record<string, string> | null | undefined;
    } | null | undefined;
    readonly id: string;
  }>;
  readonly " $fragmentType": "AdditionalCranesFields_AdditionalCranesManualCollectionFragment";
};
export type AdditionalCranesFields_AdditionalCranesManualCollectionFragment$key = {
  readonly " $data"?: AdditionalCranesFields_AdditionalCranesManualCollectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdditionalCranesFields_AdditionalCranesManualCollectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdditionalCranesFields_AdditionalCranesManualCollectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdditionalConfigurationInfo",
      "kind": "LinkedField",
      "name": "additionalConfigurations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CraneCapacity",
          "kind": "LinkedField",
          "name": "capacity",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "capacity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "fragment": {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CraneCapacityLabelResolver"
              },
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/CraneCapacityLabelResolver').label,
              "path": "additionalConfigurations.capacity.label"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquipmentKindLookup",
          "kind": "LinkedField",
          "name": "equipmentKind",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "fragment": {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EquipmentKindLookupLabelResolver"
              },
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/EquipmentKindLookupLabelResolver').label,
              "path": "additionalConfigurations.equipmentKind.label"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ConfigurationKindLookup",
          "kind": "LinkedField",
          "name": "configurationKind",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "fragment": {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ConfigurationKindLookupLabelResolver"
              },
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/ConfigurationKindLookupLabelResolver').label,
              "path": "additionalConfigurations.configurationKind.label"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BoomConfigurationSnapshot",
          "kind": "LinkedField",
          "name": "boomConfiguration",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "fragment": {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BoomConfigurationSnapshotLabelResolver"
              },
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/BoomConfigurationSnapshotLabelResolver').label,
              "path": "additionalConfigurations.boomConfiguration.label"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "preparationHours",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "VehicleId",
              "kind": "LinkedField",
              "name": "vehicleIds",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "key",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "make",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "model",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deletedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInternal",
  "abstractKey": null
};
})();

(node as any).hash = "1dee8398a0fcbb9299157664a7545dff";

export default node;
