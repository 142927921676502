/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const jibKinds = [

  /**
  * Fléchette en treillis fixe
  */
  "fixedLattice",

  /**
  * Fléchette repliable
  */
  "foldable",

  /**
  * Fléchette en treillis à volée variable
  */
  "variableSpanLattice",

  /**
  * Fléchette en treillis à volée variable avec offset
  */
  "variableSpanLatticeWithOffset"
] as const;
export type JibKind = typeof jibKinds[number];

export function isJibKind(value: string): value is JibKind {
  return jibKinds.includes(value)
}

export function asJibKind(value: string): JibKind | null {
  return isJibKind(value) ? value : null;
}

export function castJibKind(value: string): JibKind {
  if(!isJibKind(value)) {
    throw new Error(`Invalid Enum value for type "JibKind": "${value}"`);
  }
  
  return value;
}
