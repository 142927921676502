/**
 * @generated SignedSource<<ba72e0ee7093b1dc725f75a764650dca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_WorksiteOverridesStateFragment$data = {
  readonly value: {
    readonly state: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ProjectBaseFields_WorksiteOverridesStateFragment";
};
export type ProjectBaseFields_WorksiteOverridesStateFragment$key = {
  readonly " $data"?: ProjectBaseFields_WorksiteOverridesStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesStateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_WorksiteOverridesStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StructuredAddressData",
      "kind": "LinkedField",
      "name": "value",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StructuredAddressResult",
  "abstractKey": null
};

(node as any).hash = "27f18da299ba861d312ffa95b6e50d1c";

export default node;
