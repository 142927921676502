/**
 * @generated SignedSource<<1b878805081f6d921c3a3f92da1a247b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as boomConfigurationSnapshotLabelResolverType } from "../../__resolvers__/BoomConfigurationSnapshotLabelResolver";
// Type assertion validating that `boomConfigurationSnapshotLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(boomConfigurationSnapshotLabelResolverType satisfies (
  rootKey: BoomConfigurationSnapshotLabelResolver$key,
) => string | null | undefined);
import { label as configurationKindLookupLabelResolverType } from "../../__resolvers__/ConfigurationKindLookupLabelResolver";
// Type assertion validating that `configurationKindLookupLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(configurationKindLookupLabelResolverType satisfies (
  rootKey: ConfigurationKindLookupLabelResolver$key,
) => Record<string, string> | null | undefined);
import { label as equipmentKindLookupLabelResolverType } from "../../__resolvers__/EquipmentKindLookupLabelResolver";
// Type assertion validating that `equipmentKindLookupLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(equipmentKindLookupLabelResolverType satisfies (
  rootKey: EquipmentKindLookupLabelResolver$key,
) => Record<string, string> | null | undefined);
export type useFavoriteEquipment_useUpdateManualBoomConfigurationFields_AdditionalBoomConfigurationFragment$data = ReadonlyArray<{
  readonly capacity: number;
  readonly configurationKind: {
    readonly code: number;
    readonly id: string;
    readonly label: Record<string, string> | null | undefined;
  } | null | undefined;
  readonly equipmentKind: {
    readonly code: number;
    readonly id: string;
    readonly label: Record<string, string> | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly label: string | null | undefined;
  readonly " $fragmentType": "useFavoriteEquipment_useUpdateManualBoomConfigurationFields_AdditionalBoomConfigurationFragment";
}>;
export type useFavoriteEquipment_useUpdateManualBoomConfigurationFields_AdditionalBoomConfigurationFragment$key = ReadonlyArray<{
  readonly " $data"?: useFavoriteEquipment_useUpdateManualBoomConfigurationFields_AdditionalBoomConfigurationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_useUpdateManualBoomConfigurationFields_AdditionalBoomConfigurationFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useFavoriteEquipment_useUpdateManualBoomConfigurationFields_AdditionalBoomConfigurationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquipmentKindLookup",
      "kind": "LinkedField",
      "name": "equipmentKind",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EquipmentKindLookupLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../__resolvers__/EquipmentKindLookupLabelResolver').label,
          "path": "equipmentKind.label"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConfigurationKindLookup",
      "kind": "LinkedField",
      "name": "configurationKind",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ConfigurationKindLookupLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../__resolvers__/ConfigurationKindLookupLabelResolver').label,
          "path": "configurationKind.label"
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "BoomConfigurationSnapshotLabelResolver"
      },
      "kind": "RelayResolver",
      "name": "label",
      "resolverModule": require('./../../__resolvers__/BoomConfigurationSnapshotLabelResolver').label,
      "path": "label"
    }
  ],
  "type": "BoomConfigurationSnapshot",
  "abstractKey": null
};
})();

(node as any).hash = "c80a5c04d6c51a8aa5234341e5a4253b";

export default node;
