/**
 * @generated SignedSource<<ec2325868742691012291c159874f9cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useFavoriteEquipment_useResetBoomConfigurationFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CraneSelectorFields_CraneCapacityFragment" | "CraneSelectorFields_CraneConfigurationKindFragment" | "CraneSelectorFields_EquipmentKindFragment" | "CraneSelectorFields_Manual_BoomConfigurationFragment">;
  readonly " $fragmentType": "useFavoriteEquipment_useResetBoomConfigurationFragment";
};
export type useFavoriteEquipment_useResetBoomConfigurationFragment$key = {
  readonly " $data"?: useFavoriteEquipment_useResetBoomConfigurationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFavoriteEquipment_useResetBoomConfigurationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useFavoriteEquipment_useResetBoomConfigurationFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CraneSelectorFields_CraneCapacityFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CraneSelectorFields_EquipmentKindFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CraneSelectorFields_CraneConfigurationKindFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CraneSelectorFields_Manual_BoomConfigurationFragment"
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "a490f853a8a1ab279cf4e40c53d12e49";

export default node;
