import { Duration } from 'luxon';

declare global {
  interface Window {
    readonly env: Record<string, string>;
  }
}

// These values come from ../public/config.js
const env = { ...window.env };

export const config = {
  APP_VERSION: env.APP_VERSION,
  API_URL: env.API_URL,
  WS_URL: env.WS_URL,
  AUTH_API_SCOPE: env.AUTH_API_SCOPE,
  AUTH_AUTHORITY: env.AUTH_AUTHORITY,
  AUTH_CLIENT_ID: env.AUTH_CLIENT_ID,
  AUTH_LOGIN_REDIRECT: env.AUTH_LOGIN_REDIRECT,
  AUTH_REDIRECT_URI: env.AUTH_REDIRECT_URI,
  RELEASE_DATE: env.RELEASE_DATE,
  SENTRY_DSN: env.SENTRY_DSN,
  SENTRY_ENVIRONMENT: env.SENTRY_ENVIRONMENT,
  SENTRY_RELEASE: env.SENTRY_RELEASE,
  SENTRY_TRACESSAMPLERATE: env.SENTRY_TRACESSAMPLERATE ? +env.SENTRY_TRACESSAMPLERATE : undefined,
  SENTRY_REPLAYSSESSIONSAMPLERATE: env.SENTRY_REPLAYSSESSIONSAMPLERATE ? +env.SENTRY_REPLAYSSESSIONSAMPLERATE : undefined,
  SENTRY_REPLAYSONERRORSAMPLERATE: env.SENTRY_REPLAYSONERRORSAMPLERATE ? +env.SENTRY_REPLAYSONERRORSAMPLERATE : undefined,
  FEATURE_FLAG_HOST: env.FEATURE_FLAG_HOST,
  FEATURE_FLAG_CLIENT_KEY: env.FEATURE_FLAG_CLIENT_KEY,
  CAN_RESEED_DATA: env.CAN_RESEED_DATA === 'true',
  CAN_DELETE_DATA: env.CAN_DELETE_DATA === 'true',
  CAN_RESYNC_DATA: env.CAN_RESYNC_DATA === 'true',
  SHOW_COPY_TOKEN: env.SHOW_COPY_TOKEN === 'true',
  MAPBOX_API_ACCESS_TOKEN_GUAY_SALES_WEB: env.MAPBOX_API_ACCESS_TOKEN_GUAY_SALES_WEB,
  APP_UPDATE_GRACE_PERIOD: ((): Duration<true> => {
    const duration = Duration.fromISOTime(env.APP_UPDATE_GRACE_PERIOD ?? '');
    if (!duration.isValid) throw new Error(`Invalid value for config param APP_UPDATE_GRACE_PERIOD: ${env.APP_UPDATE_GRACE_PERIOD}`);
    return duration;
  })(),
  APP_UPDATE_LAST_WARNING_TIME: ((): Duration<true> => {
    const duration = Duration.fromISOTime(env.APP_UPDATE_LAST_WARNING_TIME ?? '');
    if (!duration.isValid)
      throw new Error(`Invalid value for config param APP_UPDATE_LAST_WARNING_TIME: ${env.APP_UPDATE_LAST_WARNING_TIME}`);
    return duration;
  })(),
};
