/**
 * @generated SignedSource<<22ecfc67456cae5f3cefa04b6edf74dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignedWorksite_useUpdateWorksiteFragment$data = {
  readonly assignedWorksite: {
    readonly requirements: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_RequirementsFragment">;
    }>;
  } | null | undefined;
  readonly assignedWorksiteInfo: {
    readonly location: {
      readonly structuredAddress: {
        readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesApartmentFragment" | "ProjectBaseFields_WorksiteOverridesCityFragment" | "ProjectBaseFields_WorksiteOverridesCoordinatesFragment" | "ProjectBaseFields_WorksiteOverridesPostalCodeFragment" | "ProjectBaseFields_WorksiteOverridesStateFragment">;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesAddressFragment">;
    } | null | undefined;
    readonly requirements: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_RequirementsFragment">;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverridesNameFragment" | "ProjectBaseFields_WorksiteOverrides_ContactFragment" | "ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment">;
  };
  readonly " $fragmentType": "AssignedWorksite_useUpdateWorksiteFragment";
};
export type AssignedWorksite_useUpdateWorksiteFragment$key = {
  readonly " $data"?: AssignedWorksite_useUpdateWorksiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_useUpdateWorksiteFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "Requirement",
  "kind": "LinkedField",
  "name": "requirements",
  "plural": true,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_RequirementsFragment"
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignedWorksite_useUpdateWorksiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Worksite",
      "kind": "LinkedField",
      "name": "assignedWorksite",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorksiteInfoInternal",
      "kind": "LinkedField",
      "name": "assignedWorksiteInfo",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_WorksiteOverridesNameFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_WorksiteOverrides_ContactFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Location",
          "kind": "LinkedField",
          "name": "location",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectBaseFields_WorksiteOverridesAddressFragment"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "StructuredAddressResult",
              "kind": "LinkedField",
              "name": "structuredAddress",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectBaseFields_WorksiteOverridesCoordinatesFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectBaseFields_WorksiteOverridesApartmentFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectBaseFields_WorksiteOverridesCityFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectBaseFields_WorksiteOverridesStateFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectBaseFields_WorksiteOverridesPostalCodeFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectInternalBase",
  "abstractKey": null
};
})();

(node as any).hash = "0a9ff8aff51202e6e9fda37ce244f7e6";

export default node;
