import { useFieldAssignedClientRead, useFieldClientOverridesRequirementsRead, useFieldRepresentativeRead } from './fields/ClientBaseFields';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { SuggestionPromptInput } from '../common/components/__generated__/SuggestionsFakeQuery.graphql';
import { useSuggestionsFragment$key } from './__generated__/useSuggestionsFragment.graphql';
import {
  useFieldDispatchBranchRead,
  useFieldIsSecondServingRead,
  useFieldNatureOfWorkRead,
  useFieldNatureOfWorkSubCategoryRead,
  useFieldSalesBranchRead,
  useFieldWorkDescriptionRead,
} from './fields/SaleProjectFields';
import {
  useFieldAssignedWorksiteRead,
  useFieldDurationHoursPerDayRead,
  useFieldDurationLengthInDaysRead,
  useFieldWorksiteOverridesRequirementsRead,
} from './fields/ProjectBaseFields';
import { useFieldInTheCareOfRead } from './fields/SaleClientFields';
import { ServiceCallKind } from '../__enums__/ServiceCallKind';
import { useFieldAccessoryLinesCollectionRead } from './accessoryLines/AccessoryLinesFields';
import { useMemo } from 'react';
import { useFavoriteEquipment_Selected } from './useFavoriteEquipment';
import { useSuggestions_FavoriteEquipmentDataFragment$key } from './__generated__/useSuggestions_FavoriteEquipmentDataFragment.graphql';
import { QuoteKind } from '../__enums__/QuoteKind';

export function useSuggestions(
  $key: useSuggestionsFragment$key | null | undefined,
  saleKind: QuoteKind | ServiceCallKind | null,
): SuggestionPromptInput {
  /**
   * WARNING: The AI suggestion does not support Quote or Work Planning directly. They always suggest from successful service calls.
   * We need to convert the kinds that no longer exist in Service Calls to their equivalent in Service Calls.
   */
  const suggestionsSaleKind = saleKind === 'liftingTestOperated' ? 'liftingTest' : saleKind;
  const $data = useFragment(
    graphql`
      fragment useSuggestionsFragment on ISale @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        clientBase {
          ...ClientBaseFields_useFieldAssignedClientFragment
          assignedClient {
            requirements {
              ...ClientBaseFields_RequirementsFragment
            }
          }
          assignedClientInfo {
            requirements {
              ...ClientBaseFields_RequirementsFragment
            }
          }
          ...ClientBaseFields_RepresentativeFragment
        }
        client {
          ...SaleClientFields_InTheCareOfFragment
        }
        projectBase {
          ...ProjectBaseFields_DurationHoursPerDayFragment
          ...ProjectBaseFields_DurationLengthInDaysFragment
          ...ProjectBaseFields_AssignedWorksiteFragment
          assignedWorksite {
            requirements {
              ...ProjectBaseFields_RequirementsFragment
            }
          }
          assignedWorksiteInfo {
            requirements {
              ...ProjectBaseFields_RequirementsFragment
            }
          }
        }
        project {
          ...SaleProjectFields_DispatchBranchFragment
          ...SaleProjectFields_IsSecondServingFragment
          ...SaleProjectFields_NatureOfWorkFragment
          ...SaleProjectFields_NatureOfWorkSubCategoryFragment
          ...SaleProjectFields_SalesBranchFragment
          ...SaleProjectFields_WorkDescriptionFragment
        }
        equipmentBase {
          craneSelector {
            ...useFavoriteEquipmentSelectedFragment
          }
        }
        costsBase {
          ...AccessoryLinesFields_AccessoryLineCollectionFragment @arguments(skipAccessories: $skipAccessories)
        }
      }
    `,
    $key,
  );

  const { equipments } = useFavoriteEquipment_Selected($data?.equipmentBase.craneSelector);

  const favoriteEquipment$key: useSuggestions_FavoriteEquipmentDataFragment$key | null =
    equipments[0]?.boomConfigurationId != null ? equipments[0].data : null;
  const favoriteEquipment$data = useFragment(
    graphql`
      fragment useSuggestions_FavoriteEquipmentDataFragment on BoomConfigurationSnapshot {
        capacity
        equipmentKindCode
        vehicleIds {
          displayName
        }
      }
    `,
    favoriteEquipment$key,
  );

  const { accessoryLines } = useFieldAccessoryLinesCollectionRead($data?.costsBase);
  const accessoryTypeCodes = useMemo(
    () => accessoryLines.flatMap((a) => (a.accessoryType?.code ? [a.accessoryType.code.toString()] : [])),
    [accessoryLines],
  );
  const { assignedClient } = useFieldAssignedClientRead($data?.clientBase);
  const { clientOverridesRequirements } = useFieldClientOverridesRequirementsRead(
    $data?.clientBase.assignedClientInfo.requirements,
    $data?.clientBase.assignedClient?.requirements,
  );
  const { inTheCareOf } = useFieldInTheCareOfRead($data?.client);
  const { dispatchBranch } = useFieldDispatchBranchRead($data?.project);
  const { durationHoursPerDay } = useFieldDurationHoursPerDayRead($data?.projectBase);
  const { isSecondServing } = useFieldIsSecondServingRead($data?.project);
  const { durationLengthInDays } = useFieldDurationLengthInDaysRead($data?.projectBase);
  const { natureOfWork } = useFieldNatureOfWorkRead($data?.project);
  const { natureOfWorkSubCategory } = useFieldNatureOfWorkSubCategoryRead($data?.project);
  const { representative } = useFieldRepresentativeRead($data?.clientBase);
  const { salesBranch } = useFieldSalesBranchRead($data?.project);
  const { workDescription } = useFieldWorkDescriptionRead($data?.project);
  const { assignedWorksite } = useFieldAssignedWorksiteRead($data?.projectBase);
  const { worksiteOverridesRequirements } = useFieldWorksiteOverridesRequirementsRead(
    $data?.projectBase.assignedWorksiteInfo.requirements,
    $data?.projectBase.assignedWorksite?.requirements,
  );

  return useMemo(
    () => ({
      accessoryTypes: accessoryTypeCodes,
      capacity: favoriteEquipment$data?.capacity ?? null,
      clientId: assignedClient?.id ?? null,
      clientRequirements: clientOverridesRequirements?.map((r) => r.id) ?? [],
      dispatchBranchId: dispatchBranch?.id ?? null,
      equipmentKindCode: favoriteEquipment$data?.equipmentKindCode.toString() ?? null,
      hoursPerDay: durationHoursPerDay,
      inTheCareOf,
      isSecondServing,
      lengthInDays: durationLengthInDays,
      natureOfWorkCode: natureOfWork?.code.toString() ?? null,
      natureOfWorkSubCategory: natureOfWorkSubCategory,
      representativeId: representative?.id ?? null,
      salesBranchId: salesBranch?.id ?? null,
      serviceCallKind: suggestionsSaleKind,
      vehicleIds: favoriteEquipment$data?.vehicleIds?.[0]?.displayName ? [favoriteEquipment$data?.vehicleIds?.[0]?.displayName] : [],
      workDescription,
      worksiteId: assignedWorksite?.id == null || assignedWorksite.id === 'new' ? null : assignedWorksite.id,
      worksiteRequirements: worksiteOverridesRequirements?.map((r) => r.id) ?? [],
    }),
    [
      accessoryTypeCodes,
      assignedClient?.id,
      assignedWorksite?.id,
      clientOverridesRequirements,
      dispatchBranch?.id,
      durationHoursPerDay,
      durationLengthInDays,
      favoriteEquipment$data,
      inTheCareOf,
      isSecondServing,
      natureOfWork?.code,
      natureOfWorkSubCategory,
      representative?.id,
      suggestionsSaleKind,
      salesBranch?.id,
      workDescription,
      worksiteOverridesRequirements,
    ],
  );
}
