import { WorkScheduleEnum } from '../../__enums__/WorkScheduleEnum';
import { NatureOfWorkSubCategoryEnum } from '../../__enums__/NatureOfWorkSubCategoryEnum';
import { QuoteKind } from '../../__enums__/QuoteKind';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { SalesQuestionKind } from '../../__enums__/SalesQuestionKind';
import { PowerlineProximity } from '../../__enums__/PowerlineProximity';
import { TransportSegmentKind } from '../../__enums__/TransportSegmentKind';

const button = {
  add: 'Ajouter',
  cancel: 'Annuler',
  clear: 'Effacer',
  clearFilters: 'Effacer les filtres',
  close: 'Fermer',
  confirm: 'Confirmer',
  copy: 'Copier',
  create: 'Créer',
  delay: 'Reporter',
  delete: 'Supprimer',
  done: 'Terminer',
  edit: 'Modifier',
  email: 'Courriel',
  filter: 'Filtrer',
  filters: 'Filtres',
  goBack: 'Retour',
  goHome: 'Retour à l’accueil',
  import: 'Importer',
  next: 'Suivant',
  notify: 'Notifier',
  ok: 'Ok',
  previous: 'Précédent',
  refresh: 'Actualiser',
  retry: 'Réessayer',
  returnToPreviousPage: 'Retour à la page précédente',
  save: 'Enregistrer',
  saveCopy: 'Enregistrer la copie',
  select: 'Sélectionner',
  submit: 'Soumettre',
  transfer: 'Transférer',
  update: 'Modifier',
};

const dateTime = {
  today: 'Aujourd’hui',
};

const field = {
  addendumTemplates: 'Addenda préconfigurés',
  duration: 'Durée',
  durationPerDay: 'Durée par jour',
  requirements: 'Exigences',
};

const unit = {
  datetime: {
    short: {
      hour: 'h',
    },
  },
  full: {
    day_one: 'jour',
    day_other: 'jours',
    degree_one: 'degré',
    degree_other: 'degrés',
    hour_one: 'heure',
    hour_other: 'heures',
    pound_one: '{{ count }} livre',
    pound_other: '{{ count }} livres',
    ton_one: '{{ count }} Tonne',
    ton_other: '{{ count }} Tonnes',
    feetInches: 'pieds/pouces',
  },
  short: {
    pound: 'lb',
    price: '{{ price }} $',
    ton: 'T',
  },
  mass: {
    short: {
      lbs: 'lbs',
      kg: 'kg',
    },
  },
  length: {
    short: {
      km: 'km',
      m: 'm',
      mm: 'mm',
      cm: 'cm',
      in: 'po',
      ft: 'pi',
      ftin: 'pi/po',
    },
  },
  angle: {
    short: {
      deg: '°',
    },
  },
  rate: {
    short: {
      hourly: '$/h',
    },
  },
  speed: {
    short: {
      'km/h': 'km/h',
    },
  },
};

const yesNo = {
  yes: 'Oui',
  no: 'Non',
};

const workSchedules: Record<WorkScheduleEnum, string> = {
  fiveDaysEightHours: '5j x 8h',
  fiveDaysNineHours: '5j x 9h',
  fiveDaysTenHours: '5j x 10h',
  fourDaysTenHours: '4j x 10h',
  fourDaysTenHoursOneDayFiveHours: '4j x 10h + 1j x 5h',
};

const natureOfWorkSubCategories: Record<NatureOfWorkSubCategoryEnum, string> = {
  accessory: 'Location d’accessoires',
  airports: 'Aéroports',
  antennas: 'Antennes',
  aqueductsSewer: 'Aqueduc et égouts',
  blade: 'Roulements de pales',
  bladeChange: 'Changement de pale',
  bridges: 'Ponts/Viaducs',
  cages: 'Cage de déménagement',
  chambers: 'Chambres',
  dams: 'Barrages',
  demolition: 'Démolition',
  distribution: 'Distribution',
  docks: 'Quais',
  excavation: 'Excavation',
  gearboxes: 'Boîte de vitesses',
  generator: 'Génératrice',
  gondola: 'Nacelle/Panier suspendu',
  hand: 'Main',
  handling: 'Manutention',
  landscaping: 'Aménagement paysager',
  lifting: 'Levage d’équipement',
  lines: 'Lignes',
  modules: 'Modules',
  newConstruction: 'Nouvelle construction/Agrandissement',
  nonOperated: 'Location non-opérée',
  powerPlants: 'Centrales',
  pruning: 'Émondage',
  pylons: 'Pylônes',
  refurbishment: 'Réfection',
  retentionBasins: 'Bassin de rétention',
  roadsRails: 'Routes/Rails/Métro',
  rotor: 'Rotor',
  shelters: 'Abris',
  substations: 'Postes/Centrales',
  towers: 'Tours',
  transformers: 'Transformateurs',
  transport: 'Transport',
  valves: 'Vannes',
  ventilationPlumbingElectrical: 'Ventilation/Plomberie/Électricité',
};

const autocomplete = {
  clearText: 'Effacer',
  closeText: 'Fermer',
  loadingText: 'Chargement…',
  noOptionsText: 'Aucune option',
  openText: 'Ouvrir',
  automaticSuggestions: 'Suggestions automatiques',
};

const additionalCranesPropertyNames = {
  Capacity: 'La capacité de la grue additionnelle',
  EquipmentKindCode: 'Le genre d’équipement de la grue additionnelle',
  ConfigurationKindCode: 'L’option de configuration de la grue additionnelle',
  BoomConfigurationId: 'La configuration de flèche de la grue additionnelle',
};

export const common = {
  autocomplete,
  automatic: 'Automatique',
  button,
  dateTime,
  dialog: {
    deleteAttachment: 'Voulez-vous vraiment supprimer cette pièce jointe\u00a0?',
    deleteConfirmation: 'Voulez-vous vraiment supprimer cet élément\u00a0?',
    deleteTitle: 'Confirmer la suppression',
    noResult: 'Aucun résultat trouvé',
  },
  displayedRows: '{{ info.from }}-{{ info.to }} de {{ info.count }}',
  errorMessages: {
    accessDeniedError: 'L’accès est refusé',
    archivedError: 'La révision actuelle est archivée et en lecture seule',
    canceledError: 'La révision actuelle est annulée et en lecture seule',
    costLineIsRequired: 'Veuillez saisir une ligne de coût',
    elementNotFound: {
      body: 'Le numéro d’identification de $t(errorMessages.elementNotFound.names.{{ element }}) n’existe pas, veuillez retourner à la page précédente.',
      names: {
        accessoryLine: 'la ligne d’accessoire',
        addendum: 'l’addendum',
        addendumTemplate: 'l’addendum préconfiguré',
        attachment: 'la pièce jointe',
        billingCodeRule: 'la règle de code de facturation',
        accessoryRule: 'la règle d’accessoires',
        costLine: 'la ligne de coût',
        lift: 'levage',
        quote: 'la soumission',
        serviceCall: 'l’appel de service',
        workPlanning: 'la planification de travaux',
        rateStrategy: 'la stratégie de taux',
      },
      subtitle: 'Veuillez retourner à la page précédente',
      title: 'L’élément n’existe pas',
    },
    entityNotFoundError: 'L’élément est introuvable',
    errorDuringCancel: 'Une erreur s’est produite pendant l’annulation',
    errorDuringCopy: 'Une erreur s’est produite pendant la copie',
    errorDuringSave: 'Une erreur s’est produite pendant la sauvegarde',
    errorOccurred: 'Une erreur s’est produite',
    geolocatePermissionDenied: "Veuillez autoriser l'application à accéder à votre position afin de vous localiser",
    invalidJobStageBaseTransitionError: 'Transition de {{ typeFrom }} vers {{ typeTo }} invalide',
    invalidPatchOperationError: 'Une opération sur une liste est invalide',
    jobRevisionIsNotTheLatestError: '$t(common:jobRevisionType.{{ jobRevisionType }}) n’est plus à jour, veuillez rafraichir la page',
    jobStageLockedError: 'La révision actuelle est verrouillée et en lecture seule',
    mapboxError: 'Erreur de Mapbox, veuillez réessayer plus tard',
    missingInformation: 'Informations manquantes, veuillez remplir tous les champs obligatoires correctement',
    mustBeUnique: 'Doit être unique',
    patchOperationItemNotFoundError: 'Une opération sur une liste est invalide',
    requiredField: 'Champ requis',
    save: 'Une erreur s’est produite pendant la sauvegarde',
    somethingWentWrong: 'Un problème est survenu',
    unknownError: 'Erreur inconnue',
    unsavedChanges: 'Les modifications que vous avez apportées ne seront peut-être pas enregistrées.',
    uploadFailed: 'Erreur lors du téléversement, veuillez réessayer plus tard.',
    validation: {
      accessoryTypeNotFound: 'Le type d’accessoire est requis',
      automaticMustBeSubsetOfVisible: 'Les codes de facturations automatiques doivent provenir de la liste des codes visibles sélectionnés',
      billingCodeNotFound: 'Le code de facturation est introuvable',
      craneNotFoundOrInactive: 'La grue de la ligne de coût #{{ index }} est introuvable ou inactive',
      craneSelectorETagMismatch: 'Les résultats du sélecteur de grue ne sont plus à jour, veuillez les rafraichir',
      craneSelectorSnapshotMismatch: 'Les données de la grue sélectionnée ont été mises à jour, veuillez chercher et choisir à nouveau',
      entityNotFound: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) est introuvable',
      equal: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être égale à {{ comparisonValue }}',
      greaterThan: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être supérieur(e) à {{ comparisonValue }}',
      greaterThanOrEqual:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être supérieur(e) ou égal(e) à {{ comparisonValue }}',
      integerQuantity: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) la quantité être entière',
      invalidFavoriteETag: 'La sélection de configuration est invalide',
      invalidFileName: 'Le nom du fichier "{{ actualValue }}" est invalide',
      invalidHourPerDay: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) est invalide',
      invalidPhoneNumber: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être un numéro de téléphone valide',
      invalidPrice: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit exister dans la liste de prix',
      invalidQuantity:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être plus grand ou égal à la quantité minimum',
      invalidStateTransitionToInModification: 'Le statut doit être en modification ou transféré',
      invalidStateTransitionToTransfer: 'Le statut doit être réservé, reporté, en modification ou transféré',
      itemTypeCodeIsRequired: 'La main d’oeuvre est requise et doit être active',
      lessThan: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être plus petite que {{ comparisonValue }}',
      lessThanOrEqual:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être plus petite ou égale à {{ comparisonValue }}',
      lessThanOrEqualToReadyDate:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être plus petite ou égale à la date prête',
      maximumLength:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) ne doit pas dépasser {{ comparisonValue }} caractères',
      mismatchSalesRates: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) est absent ou invalide',
      multipleQuantity: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être un multiple d’un quart',
      mustBeContained:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être contenu dans la liste de référence',
      mustBePositiveInteger: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être un entier positif',
      mustBeStrictlyPositiveInteger:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être un entier strictement positif',
      mustHaveFavorite: 'Une configuration de grue préférée doit être sélectionnée',
      noSituationSelected: 'Une situation particulière applicable doit être sélectionnée au minimum',
      notEmpty: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) ne doit pas être vide',
      notInThePast: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) ne doit pas être dans le passé',
      notNull: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) ne doit pas être vide',
      notSpecified: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit avoir une valeur',
      null: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être vide',
      oneAccessoryOrCostLine: 'Une ligne de coût ou d’accessoires doit être remplie',
      shouldBeInStatus: 'Le statut doit être {{ comparisonValue }}',
      shouldBeInWritingOrUnderReviewOrRevising: 'Le statut doit être en saisie, en approbation ou en revue',
      shouldBeInWritingOrWorksiteSelected: 'Le statut doit être en saisie ou chantier choisi',
      shouldBeInWritingOrReserved: 'Le statut doit être en saisie ou réservé',
      shouldBeInWritingReservedOrDelayed: 'Le statut doit être en saisie, réservé ou reporté',
      shouldNotBeTransferredOrLocked: 'Le statut ne doit pas être transféré ou en lecture seule',
      shouldBeUnderReview: 'Le statut doit être en approbation',
      shouldNotBeCanceledArchivedOrDispatched: 'Le statut ne doit pas être annulé, archivé, transféré ou réparti',
      shouldNotBeInStatus: 'Le statut ne doit pas être $t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" })',
      shouldNotBeTransferredOrDispatched: 'Le statut ne doit pas être transféré ou réparti',
      softDeleted: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) est inactif',
    },
  },
  field,
  fileUpload: {
    status: {
      queued: 'En attente',
      uploading: 'Téléversement',
      received: 'Reçu',
      processing: 'Traitement',
      error: 'Erreur',
      success: 'Succès',
    },
  },
  inactive: 'Inactif',
  jobRevisionType: {
    quote: 'La soumission',
    serviceCall: 'L’appel de service',
    workPlanning: 'La planification de travaux',
  },
  // keep in sync with languageToLocale in src/i18n.ts
  locale: 'fr-CA',
  maintenancePlaceholder: "L'application est présentement indisponible, veuillez revenir plus tard.",
  maintenanceTitle: 'Maintenance WeLift',
  natureOfWorkSubCategories,
  noResult: 'Aucun résultat trouvé',
  placeholder: {
    minimap: "Veuillez remplir le champ d'adresse",
    noValue: 'Aucune valeur',
    noSuggestions: 'Aucune suggestion',
  },
  propertyNames: {
    // Note: The case is important since they come as PascalCase from the backend...
    Addenda: {
      Title: 'Le titre de l’addenda #{{ index }}',
      Description: 'La description de l’addenda #{{ index }}',
    },
    BillingCode: {
      LabelEn: "L'étiquette en anglais",
      LabelFr: "L'étiquette en français",
    },
    BillingCodes: 'Facturé',
    BoomLength: 'La longueur de flèche',
    Building: {
      Value: {
        ExteriorRadius: {
          Value: 'Rayon extérieur',
        },
        DistanceBetweenMastAndBuildingEdge: {
          Value: 'Distance du bord du bâtiment',
        },
      },
    },
    Client: {
      InTheCareOf: 'A/S',
      OrderNumber: '# Commande',
      ProjectManagerId: 'Le chargé de projet',
    }, //< Whole block to be remove in retro-compatibility GG-XXXXX
    ClientBase: {
      AssignedClientId: 'Le client',
      AssignedClientInfo: {
        Name: 'Le nom du client',
        Contact: 'Le contact du client',
        PhoneNumber: 'Le téléphone du client',
        RequirementIds: 'Un des requis client',
        Location: {
          StructuredAddress: 'Les champs d’adresse du client',
          StructuredAddressError: 'Les champs d’adresse du client', //< Technically an error, but it's not on the user side
          StructuredAddressData: {
            StreetAddress: 'L’adresse du client',
            State: 'La province du client',
            City: 'La ville du client',
            PostalCode: 'Le code postal du client',
          },
        },
      },
      ProjectManagerId: 'Le chargé de projet',
      InTheCareOf: 'A/S',
      OrderNumber: '# Commande',
    },
    CostsBase: {
      CostLine: 'Une ligne de coût',
      CostLines: {
        Active: {
          BillingCodeId: 'Un des codes de facturation des lignes de coût',
          WorkForceTypeCode: 'Une des main d’oeuvre des lignes de coût',
        },
        Quantity: 'La case quantité de la ligne de coût #{{ index }}',
        WorkForceTypeCode: 'La case main d’oeuvre de la ligne de coût #{{ index }}',
        Rate: 'La case taux de la ligne de coût #{{ index }}',
        RateUpdateReason: 'La raison du changement de taux de la ligne de coût #{{ index }}',
        SalesRateResult: 'Le résultat du service de taux externe de la ligne de coût #{{ index }}',
      },
      AccessoryLines: {
        Active: {
          BillingCodeId: 'Un des codes de facturation des lignes d’accessoires',
          AccessoryGroupId: 'Une accessoire',
          AccessoryTypeCode: 'Un type d’accessoire',
        },
        AccessoryGroupId: 'La case accessoire de la ligne d’accessoires #{{ index }}',
        BillingCodeId: 'La case facturable de la ligne d’accessoires #{{ index }}',
        Quantity: 'La case quantité de la ligne de d’accessoire #{{ index }}',
        AccessoryOutOfInventory: 'La case accessoire de la ligne d’accessoire #{{ index }}',
        Rate: 'La case taux de la ligne d’accessoire #{{ index }}',
        SalesRateResult: 'Le résultat du service de taux externe de la ligne d’accessoire #{{ index }}',
      },
    },
    Counterweight: 'La masse du contrepoids',
    Description: 'Description',
    Equipment: {
      BranchToWorksiteDistance: 'La distance de la succursale au chantier',
      Transport: {
        FlatbedTransportRateStrategyId: 'Le taux de transport par fardier',
        BoomCounterweightTransportRateStrategyId: 'Le taux de transport de flèche et contrepoids',
        CraneTransportRateStrategyIds: 'Un des taux de grues',
        TransportSegments: {
          Cranes: {
            // The crane should be P for craneIndex 0 but this error will never produce because of the frontend validation
            Speed: 'La vitesse de la grue A{{ craneIndex }} du segment de transport #{{ index }}',
          },
        },
      },
    },
    EquipmentBase: {
      CraneSelector: {
        AutomaticConfiguration: {
          MatchingConfigurations: 'Le sélecteur de grue',
          SnapshotId: 'L’identifiant de snapshot',
          AdditionalConfigurations: additionalCranesPropertyNames,
        },
        ManualConfiguration: {
          AdditionalConfigurations: additionalCranesPropertyNames,
          Configuration: {
            BoomLength: 'La longueur de flèche de la section équipement requis',
            Capacity: 'La capacité de la section équipement requis',
            ConfigurationKindCode: 'L’option de configuration de la section équipement requis',
            Counterweight: 'La valeur de contrepoids',
            EquipmentKindCode: 'Le genre d’équipement de la section équipement requis',
            MaxWeight: 'Le poids max de la section configuration',
            Radius: 'Le rayon de la section configuration',
            VehicleIds: 'La liste de modèles',
            BoomConfigurationId: 'La configuration de flèche',
            MatchingCranes: 'Le modèle de grue choisi',
          },
          CraneConfigurations: 'Les configurations de grue',
        },
      },
    },
    JibLength: 'La longueur de fléchette',
    NatureOfWork: {
      NatureOfWorkSubCategories: 'Les catégories',
      DefaultWorkSchedule: "L'horaire de travail par défaut",
      WorkSchedules: 'Les horaires de travail',
    },
    Project: {
      DispatchBranchId: 'La succursale de répartition',
      NatureOfWorkCode: 'La nature du travail',
      NatureOfWorkSubCategory: 'La catégorie de la nature du travail',
      ReadyDate: 'La date prête',
      SalesBranchId: 'La succursale de ventes',
      WorkDescription: 'La description des travaux',
      WorkSchedule: 'L’horaire de travail',
      SchedulePeriod: {
        StartTime: 'L’heure de début',
        EndTime: 'L’heure de fin',
      },
    }, //< Whole block to be remove in retro-compatibility GG-XXXXX
    ProjectBase: {
      ArrivalDate: 'La date d’arrivée',
      AssignedWorksiteId: 'Le chantier',
      AssignedWorksiteInfo: {
        Name: 'Le nom du chantier',
        Contact: 'Le contact du chantier',
        PhoneNumber: 'Le téléphone du chantier',
        RequirementIds: 'Un des requis du chantier',
        Location: {
          StructuredAddress: 'Les champs d’adresse du chantier',
          StructuredAddressError: 'Les champs d’adresse du chantier', //< Technically an error, but it's not on the user side
          StructuredAddressData: {
            StreetAddress: 'L’adresse du chantier',
            State: 'La province du chantier',
            City: 'La ville du chantier',
            PostalCode: 'Le code postal du chantier',
          },
        },
      },
      Duration: {
        LengthInDays: 'La durée des travaux',
        HoursPerDay: 'La durée par jour',
      },
      DispatchBranchId: 'La succursale de répartition',
      NatureOfWorkCode: 'La nature du travail',
      NatureOfWorkSubCategory: 'La catégorie de la nature du travail',
      ReadyDate: 'La date prête',
      SalesBranchId: 'La succursale de ventes',
      WorkDescription: 'La description des travaux',
      WorkSchedule: 'L’horaire de travail',
      SchedulePeriod: {
        StartTime: 'L’heure de début',
        EndTime: 'L’heure de fin',
      },
    },
    QuestionsBase: {
      SalesQuestions: {
        Option: 'La proximité de lignes électriques',
        Angle: 'L’angle de l’information sur l’excavation',
        Distance: 'La distance de l’information sur l’excavation',
        Proximity: 'La proximité de lignes électriques',
      },
      QuestionsInternalBase: {
        HasWorksiteBeenVisited: 'Est-ce qu’une visite de chantier a été faite',
      },
    },
    RateStrategy: {
      LabelEn: 'L’étiquette anglaise',
      LabelFr: 'L’étiquette française',
    },
    SecurityFactorPercentage: {
      Value: 'Le facteur de sécurité du levage',
    },
    WorkSchedule: {
      LengthInDays: 'La durée des travaux',
      HoursPerDay: 'La durée par jour',
    },
  },
  proximityToPowerline: {
    lessThan126KV: '125KV et moins: 3m',
    from126KVTo250KV: '126KV à 250KV: 5m',
    from251KVTo550KV: '251KV à 550KV: 8m',
    moreThan550KV: '551KV et +: 12m',
  } satisfies Record<PowerlineProximity, string>,
  requestSupport: 'Contactez l’équipe de support',
  saleKinds: {
    accessoriesRental: 'Location d’accessoires',
    bare: 'Grue non-opérée',
    bareWithOperators: 'Grue non-opérée avec opérateurs',
    laborRental: 'Location de main d’oeuvre',
    laborRentalOnBare: 'Location de main d’oeuvre sur non-opérée',
    laborRentalOnOperated: 'Location de main d’oeuvre sur opérée',
    liftingPlan: 'Plan de levage',
    liftingTest: 'Test de levage',
    liftingTestOperated: 'Test de levage opéré',
    operatedHourly: 'Grue opérée (à l’heure)',
    operatedMonthly: 'Grue opérée (mensuelle)',
    rollingEquipment: 'Matériel roulant',
    storage: 'Entreposage',
  } satisfies Record<QuoteKind | ServiceCallKind, string>,
  salesQuestionKinds: {
    peopleLifting: 'Levage de personnes',
    hazardousTerrain: 'Pentes, dénivelés, chemin d’accès ou terrain est considéré à risque',
    powerlineProximity: 'Proximité de lignes électriques',
    craneOrObstacleProximity: 'Proximité d’obstacles ou de grues',
    excavationProximityLessThan10Feet: "Proximité d’une excavation - Moins de 10'",
    excavationProximityMoreThan10Feet: "Proximité d’une excavation - Plus de 10'",
    accidentScene: 'Scène d’accident',
    conventionalCrawlerCranesWithoutTravel: 'Grues conventionnelles sur chenilles - Sans déplacement',
    conventionalCrawlerCranesWithTravel: 'Grues conventionnelles sur chenilles - Avec déplacement',
    luffingJibOrExtensionInstallation: 'Installation d’un luffing / fix jib ou extension',
    movingCraneOrEquipmentWithoutPulling: 'Déplacement de grue ou autre équipement - Sans Tirage',
    movingCraneOrEquipmentWithPulling: 'Déplacement de grue ou autre équipement - Avec Tirage',
    tandemLiftingGuayCrane: 'Levage en tandem - Grue Guay',
    tandemLiftingClientCrane: 'Levage en tandem - Grue client',
    bargeWork: 'Travaux sur barge',
    unloadingOnQuay: 'Déchargement sur quai',
    jettyBridgeViaductDamSlabOrParkingDeck: 'Jetée, pont, viaduc, barrage, dalle ou stationnement étagé',
    windTurbines: 'Éoliens',
    clamShellBucketOrGrapple: 'Benne preneuse ou grappin',
    events: 'Événementiel',
    valueOf1MillionOrMore: 'Valeur d’un million $ et +',
    videoOrPhotoShooting: 'Tournage vidéo ou photo',
  } satisfies Record<SalesQuestionKind, string>,
  searchResults: 'Résultats de recherche',
  selectOption: 'Sélectionnez une option',
  suggestions: 'Suggestions',
  supportedLanguages: {
    french: 'Français',
    english: 'Anglais',
  },
  transportSegmentKinds: {
    departure: 'Aller',
    return: 'Retour',
  } satisfies Record<TransportSegmentKind, string>,
  unit,
  warning: 'Avertissement',
  workSchedules,
  yesNo,
};
